import { Select } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector, worklistTodoActions } from '../../../state-management';

// TODO: Replace with central version in /features
const options: Array<{ value: ActualFasting; label: string }> = [
  { value: 0, label: 'Fasting' },
  { value: 1, label: 'Not Fasting' },
  { value: 2, label: 'Not Recorded' },
];

function getActualFastingFromInput(e: React.ChangeEvent<HTMLSelectElement>): ActualFasting {
  return e.target.value ? (Number(e.target.value) as ActualFasting) : 2;
}

export function ActualFastingSelect({
  onChange,
  value,
}: {
  value?: ActualFasting;
  onChange?: (val: ActualFasting) => void;
}) {
  const dispatch = useAppDispatch();
  const actualFastingValue = useAppSelector(
    (state) => state.worklistTodo?.labGlobalForm?.actualFasting,
  );

  function handleUpdateGlobalForm(e: React.ChangeEvent<HTMLSelectElement>) {
    const newVal = getActualFastingFromInput(e);
    dispatch(worklistTodoActions.updateGlobalLabForm({ actualFasting: newVal }));
  }

  return (
    <Select
      value={typeof value === 'number' ? value : actualFastingValue ?? undefined}
      onChange={
        onChange
          ? (e) => {
              const newVal = getActualFastingFromInput(e);
              onChange(newVal);
            }
          : handleUpdateGlobalForm
      }>
      {options.map((option) => (
        <option key={option.value} value={option.value ?? undefined}>
          {option.label}
        </option>
      ))}
    </Select>
  );
}
