import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  VStack,
  HStack,
  Box,
} from '@chakra-ui/react';
import {
  type ImmunizationInjectionItem,
  type SubmitImmunizationInjectionInput,
  useUpdateImmunizationInjectionMutation,
} from '../../../__generated__/graphql';
import { useAuth, useCustomToast } from '../../../hooks';
import { Flex } from '../../../components';
import { type DiagnosisUI } from '../../../types';
import { useState } from 'react';
import { PatientAssessmentSelect } from '../../../features/PatientAssessmentSelect';

export function EditInjectionOrderButton({ order }: { order: ImmunizationInjectionItem | null }) {
  const ecwId = useAuth().user?.ecwId;
  const reviewedByUserId = ecwId ? Number(ecwId) : null;

  const toast = useCustomToast();

  const [updateImmunization, { loading: isSubmittingForm }] =
    useUpdateImmunizationInjectionMutation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedAssessments, setSelectedAssessments] = useState<DiagnosisUI[]>([]);

  function handleSubmit() {
    if (reviewedByUserId) {
      const input: SubmitImmunizationInjectionInput = {
        ...order,
        immunizationItemId: order?.immunizationItemId ?? 0,
        sourceEncounterId: order?.sourceEncounterId ?? 0,
        statusId: order?.statusId ?? 0,
        sourcePatientId: order?.sourcePatientId ?? 0,
        patientImmunizationInjectionAssessments: selectedAssessments.map((a) => {
          return {
            assessmentValue: a.icdCode ?? '',
            assessmentId: a.assessmentItemId ?? -1,
            assessmentName: a.diagnosisName ?? '',
            immunizationID: order?.immunizationId ?? -1,
          };
        }),
      };

      updateImmunization({
        variables: {
          input,
        },
        update: (cache) => {
          // Invalidate cache
          cache.evict({
            fieldName: 'getEncounterOrders',
          });
        },
        onError: (error) => {
          toast({
            id: 'update-oi-toast-error',
            title: 'Error',
            description: error.message,
            status: 'error',
          });
        },
        onCompleted: () => {
          toast({
            id: 'update-oi-toast-success',
            title: 'Success',
            description: 'Changed successfully',
            status: 'success',
          });
          onClose();
        },
      });
    }
  }

  return (
    <>
      <Button onClick={onOpen} variant={'outline'}>
        Edit
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size={'6xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Order</ModalHeader>
          <ModalCloseButton />
          <ModalBody minH='70vh'>
            <HStack spacing={'lg'} alignItems='flex-start'>
              <Box flex='1'>
                <PatientAssessmentSelect
                  onChange={setSelectedAssessments}
                  initialOrderableItemAssessments={order?.patientImmunizationInjectionAssessments.map(
                    (a) => {
                      return {
                        assessmentValue: a.assessmentValue ?? '',
                        assessmentId: a.assessmentId ?? -1,
                        assessmentName: a.assessmentName ?? '',
                        immunizationID: a?.immunizationID ?? -1,
                      };
                    },
                  )}
                />
              </Box>

              <VStack
                flex='2'
                align={'left'}
                borderBottom={'1px solid'}
                borderBottomColor={'gray.300'}
                spacing={'lg'}
                pb='lg'>
                <Text fontWeight='bold'>Order</Text>
                <Flex justifyContent={'space-between'} w='full'>
                  <Text variant='h6-b'>{order?.immunizationName}</Text>
                </Flex>
              </VStack>
            </HStack>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button variant='solid' isLoading={isSubmittingForm} onClick={handleSubmit}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
