import React, { useEffect, useState } from 'react';
import { Select } from '@chakra-ui/react';

const options = [
  {
    measurementCode: 'cL',
    measurementDescription: 'centiliters',
  },
  {
    measurementCode: 'cc',
    measurementDescription: 'cubic centimeters',
  },
  {
    measurementCode: 'g',
    measurementDescription: 'grams',
  },
  {
    measurementCode: 'mcg',
    measurementDescription: 'microgram',
  },
  {
    measurementCode: 'uL',
    measurementDescription: 'microliter',
  },
  {
    measurementCode: 'mCi',
    measurementDescription: 'millicuries',
  },
  {
    measurementCode: 'mg',
    measurementDescription: 'milligrams',
  },
  {
    measurementCode: 'mL',
    measurementDescription: 'milliliters',
  },
  {
    measurementCode: 'ng',
    measurementDescription: 'nanogram',
  },
  {
    measurementCode: 'spray',
    measurementDescription: 'spray',
  },
  {
    measurementCode: 'U',
    measurementDescription: 'units',
  },
] as const;

export function DoseSelect({
  onChange,
  isDisabled,
  defaultValue,
}: {
  onChange(dose: string): void;
  isDisabled?: boolean;
  defaultValue?: string | null;
}) {
  const [selectedDose, setSelectedDose] = useState<string>(defaultValue ?? '');

  useEffect(() => {
    if (!selectedDose) {
      setSelectedDose('mL');
      onChange('mL');
    }
  }, []);

  return (
    <Select
      isDisabled={isDisabled}
      placeholder='Unit'
      value={selectedDose}
      onChange={(e) => {
        const dose = e.target.value;
        setSelectedDose(dose);
        onChange(dose);
      }}>
      {options.map((option) => (
        <option key={option.measurementCode} value={option.measurementCode}>
          {option.measurementDescription}
        </option>
      ))}
    </Select>
  );
}
