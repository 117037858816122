import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  ModalCloseButton,
  ModalHeader,
  Radio,
  Text,
  VStack,
} from '@chakra-ui/react';
import { type EncounterHPIDataItem, type HPISymptom } from '../../../types';
import { useEffect, useState } from 'react';
import { type HpiSmartFormEntity } from '../../../__generated__/graphql';
import { ComboBox } from '../../form';
import { useScoring } from './useScoring';
import { handleCustomSmartForm } from '../../../screens/MAWorkFlowScreen/HPI/utils/handleCustomSmartForm';

interface HPISmartFormModalProps {
  encounterHPIDataItem: EncounterHPIDataItem | undefined;
  categoryName: string;
  symptom: HPISymptom;
  onCloseModal: () => void;
  saveSymptom: (encounterHPIDataItem: EncounterHPIDataItem, symptom: HPISymptom) => void;
  nextHandler: (next: boolean, symptom: HPISymptom) => void;
  enableNext: boolean;
  enableBack: boolean;
  smartForms: HpiSmartFormEntity[];
}

export function HPISmartFormModal({
  encounterHPIDataItem: encounterHPIDataItemProp,
  categoryName,
  symptom,
  onCloseModal,
  saveSymptom,
  smartForms,
  enableBack,
  enableNext,
  nextHandler,
}: HPISmartFormModalProps) {
  const [encounterHPIDataItem, setEncounterHPIDataItem] = useState<
    EncounterHPIDataItem | undefined
  >(encounterHPIDataItemProp || undefined);

  const [selectedRadio, setSelectedRadio] = useState<'Complains' | 'Denies' | undefined>(undefined);
  const updatedSmartForms = handleCustomSmartForm(smartForms);

  useEffect(() => {
    if (encounterHPIDataItem) {
      setSelectedRadio(
        encounterHPIDataItem.complainsDenies
          ? 'Complains'
          : encounterHPIDataItem.complainsDenies === false
          ? 'Denies'
          : undefined,
      );
    }
  }, [encounterHPIDataItem]);

  const [structuredResponses, setStructuredResponses] = useState(
    encounterHPIDataItem?.hpiStructuredData ?? [],
  );

  type ResponseUpdate = Partial<(typeof structuredResponses)[0]>;

  const changeHandler = (update: ResponseUpdate) => {
    const fieldExists = structuredResponses.some(
      (sr) => sr.hpiStructuredDataDetailId === update.hpiStructuredDataDetailId,
    );

    if (fieldExists) {
      const updated = structuredResponses.map((sr) => {
        if (update.hpiStructuredDataDetailId === sr.hpiStructuredDataDetailId) {
          return {
            value: '',
            ...sr,
            ...update,
          };
        }
        return sr;
      });
      setStructuredResponses(updated);
    } else {
      const newVal = {
        value: '',
        ...update,
      };
      setStructuredResponses([...structuredResponses, newVal]);
    }
  };

  const updatedItem = {
    ...encounterHPIDataItem,
    complainsDenies:
      selectedRadio === 'Complains' ? true : selectedRadio === 'Denies' ? false : null,
    hpiStructuredData: structuredResponses,
  };

  const { disabledFields } = useScoring({
    smartForms: updatedSmartForms,
    responses: structuredResponses,
    changeHandler,
  });

  return (
    <>
      <ModalHeader>
        {categoryName} {'>'} {symptom.name}
      </ModalHeader>
      <ModalCloseButton />

      <Flex h='100%' overflow='auto' justifyContent='center' paddingBottom={'md'}>
        <VStack alignItems='flex-start' width='95%' spacing={5}>
          <HStack spacing={3}>
            <Text fontSize='lg'>Complains</Text>
            <Radio
              colorScheme='brand'
              size='lg'
              isChecked={selectedRadio === 'Complains'}
              onClick={() => {
                setSelectedRadio('Complains');
                setEncounterHPIDataItem({
                  ...encounterHPIDataItem,
                  complainsDenies: true,
                });
              }}
            />
            <Text fontSize='lg'>Denies</Text>
            <Radio
              colorScheme='brand'
              size='lg'
              isChecked={selectedRadio === 'Denies'}
              onClick={() => {
                setSelectedRadio('Denies');
                setEncounterHPIDataItem({
                  ...encounterHPIDataItem,
                  complainsDenies: false,
                });
              }}
            />
          </HStack>

          {updatedSmartForms
            .filter((sf) => sf.itemID === symptom.hpiSymptomID)
            .map((sf) => {
              if (!sf.structureDataDetailID) return null;

              const isDisabled = disabledFields.includes(sf.structureDataDetailID);

              const response = structuredResponses.find(
                (r) => r.hpiStructuredDataDetailId === sf.structureDataDetailID,
              );

              const selection = sf.smartFormSurveyStructureOptions?.find((option) => {
                return `${option.structDataOptionID}` === `${response?.value}`;
              });

              return (
                <VStack
                  key={sf.structureDataDetailID}
                  alignItems='flex-start'
                  spacing={3}
                  width='100%'>
                  <Text>{sf.name}</Text>
                  {!!sf.smartFormSurveyStructureOptions?.length && (
                    <Box w='100%'>
                      <ComboBox
                        isDisabled={isDisabled}
                        isLoading={false}
                        key={selection?.structDataOptionID}
                        placeholder='Select option'
                        defaultValue={
                          selection
                            ? {
                                label: `${selection?.name}`,
                                value: `${selection?.structDataOptionID}`,
                              }
                            : undefined
                        }
                        options={sf.smartFormSurveyStructureOptions?.map((option) => {
                          return {
                            label: `${option?.name}`,
                            value: `${option?.structDataOptionID}`,
                          };
                        })}
                        onSelection={(selection) => {
                          if (selection?.value) {
                            changeHandler({
                              hpiStructuredDataDetailId: sf.structureDataDetailID,
                              value: selection.value,
                            });
                          }
                        }}
                      />
                    </Box>
                  )}

                  {!sf.smartFormSurveyStructureOptions?.length && (
                    <Input
                      isDisabled={isDisabled}
                      value={response?.value ?? ''}
                      focusBorderColor='brand.500'
                      placeholder={sf?.name ?? ''}
                      onChange={(e) => {
                        changeHandler({
                          hpiStructuredDataDetailId: sf.structureDataDetailID,
                          value: e.target.value,
                        });
                      }}
                    />
                  )}
                </VStack>
              );
            })}

          <Flex width='100%' justifyContent='space-between'>
            <HStack>
              <Button
                isDisabled={!enableBack}
                mr='sm'
                variant='outline'
                onClick={() => {
                  saveSymptom(updatedItem, symptom);
                  nextHandler(false, symptom);
                }}>
                Previous
              </Button>
              <Button
                isDisabled={!enableNext}
                variant='outline'
                onClick={() => {
                  saveSymptom(updatedItem, symptom);
                  nextHandler(true, symptom);
                }}>
                Next
              </Button>
            </HStack>
            <HStack>
              <Button variant='outline' colorScheme='brand' onClick={onCloseModal}>
                Cancel
              </Button>

              <Button
                variant='solid'
                colorScheme='brand'
                onClick={() => {
                  saveSymptom(updatedItem, symptom);
                  onCloseModal();
                }}>
                Save
              </Button>
            </HStack>
          </Flex>
        </VStack>
      </Flex>
    </>
  );
}
