import { FullStory as FS } from '@fullstory/browser';
import { useAuth } from '../../hooks';
import { useEffect } from 'react';

export function FullStory() {
  const { user } = useAuth();

  useEffect(() => {
    if (user.userId) {
      FS('setProperties', {
        type: 'user',
        properties: {
          userId: user.userId,
          email: user.email ? user.email : user.userId,
          displayName: `${user.firstName} ${user.lastName}`,
        }
      });
    }
  }, [user]);

  return null;
}
