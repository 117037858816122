import { Select } from '@chakra-ui/react';
import { useLabCollectionForm } from './useLabCollectionForm';

const options = [
  'anal',
  'blood',
  'blood, whole',
  'breath',
  'hair',
  'nail',
  'nasal',
  'skin',
  'stool',
  'throat',
  'tongue',
  'urine',
  'vaginal',
  'wound',
];

export function CollectionSourceSelect({
  value,
  onChange,
}: {
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}) {
  const { handleUpdateGlobalLabForm, globalLabForm } = useLabCollectionForm();

  function handleUpdateGlobalFormSpecimen(e: React.ChangeEvent<HTMLSelectElement>) {
    handleUpdateGlobalLabForm({ specimenSource: e.target.value });
  }
  return (
    <Select
      placeholder='Collection Source'
      value={value || (globalLabForm.specimenSource ?? undefined)}
      onChange={onChange || handleUpdateGlobalFormSpecimen}>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
}
