import React from 'react';
import { type ImmunizationInjectionDetails } from '../../../__generated__/graphql';
import { Select } from '@chakra-ui/react';
import { useAdministerVaccineForm } from './useAdministerVaccineForm';

const injectionSites: ImmunizationInjectionDetails['injectionSites'] = [
  {
    code: 'LA',
    location: 'Left Arm',
  },
  {
    code: 'LD',
    location: 'Left Deltoid',
  },
  {
    code: 'LG',
    location: 'Left Gluteus Medius',
  },
  {
    code: 'LLFA',
    location: 'Left Lower Forearm',
  },
  {
    code: 'LT',
    location: 'Left Thigh',
  },
  {
    code: 'LVL',
    location: 'Left Vastus Lateralis',
  },
  {
    code: 'RA',
    location: 'Right Arm',
  },
  {
    code: 'RD',
    location: 'Right Deltoid',
  },
  {
    code: 'RG',
    location: 'Right Gluteus Medius',
  },
  {
    code: 'RLFA',
    location: 'Right Lower Forearm',
  },
  {
    code: 'RT',
    location: 'Right Thigh',
  },
  {
    code: 'RVL',
    location: 'Right Vastus Lateralis',
  },
  {
    code: 'PO',
    location: 'Oral',
  },
];

export function InjectionSiteSelect({
  isDisabled,
  value,
}: {
  isDisabled?: boolean;
  value?: string | null;
}) {
  const { formValues, handleUpdateForm } = useAdministerVaccineForm();

  return (
    <Select
      isDisabled={isDisabled}
      placeholder='Location*'
      value={value || (formValues?.injectionLocation ?? '')}
      onChange={(e) => {
        handleUpdateForm({ injectionLocation: e.target.value });
      }}>
      {injectionSites?.map((item) => (
        <option key={item?.location ?? ''} value={item?.location}>
          {item?.location}
        </option>
      ))}
    </Select>
  );
}
