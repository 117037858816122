import {
  Box,
  Tooltip,
  Button,
  Checkbox,
  DrawerBody,
  DrawerContent,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  DrawerOverlay,
} from '@chakra-ui/react';
import {
  type ProviderUpdateOrderableItemReviewInput,
  type GetProviderEncounterDetailsQuery,
  useGetPatientBasicDetailsQuery,
} from '../../../../../__generated__/graphql';
import {
  providerEncounterActions,
  selectors,
  useAppSelector,
} from '../../../../../state-management';
import { LabResults } from '../../../components/ComboBoxes';
import { ProviderAndStaffComboBox } from '../../../../../features';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Notes } from '../../../../../components';

interface Props {
  onClose: () => void;
  item: NonNullable<GetProviderEncounterDetailsQuery['getProviderEncounterDetails']>['items'][0];
}

export function EditPanel({ onClose, item }: Props) {
  const [searchParams] = useSearchParams();
  const patientId = searchParams.get('patientId');
  const patient = useGetPatientBasicDetailsQuery({
    variables: {
      patientID: Number(patientId),
    },
    skip: !patientId,
  });

  const name = `${patient?.data?.getPatientBasicDetails?.firstName} ${patient?.data?.getPatientBasicDetails?.lastName}`;

  const dispatch = useDispatch();
  const [localItemState, setLocalItemState] = useState<ProviderUpdateOrderableItemReviewInput>({});
  const [newNote, setNewNote] = useState<string>('');
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const handleUpdate = (update: Partial<ProviderUpdateOrderableItemReviewInput>) => {
    !hasChanges && setHasChanges(true);
    setLocalItemState({
      ...localItemState,
      ...update,
    });
  };

  const providerEncounterState = useAppSelector(selectors.getProviderEncounterState);
  const updated = providerEncounterState.updatedItems[Number(item.orderableReportID!)];

  const save = () => {
    dispatch(
      providerEncounterActions.updateItem({
        [item.orderableReportID!]: {
          ...updated,
          ...localItemState,
          notes: latestItemState.notes?.length
            ? `${latestItemState.notes}${newNote.length ? '\n' : ''}${newNote}`
            : newNote,
        },
      }),
    );
    onClose();
  };

  const sidebarVals = providerEncounterState.sidebarState.appliedValues;

  const latestItemState = {
    ...item,
    assignedToUserID: sidebarVals.assignedToUserID,
    highPriority: sidebarVals.highPriority,
    reviewed: sidebarVals.reviewed,
    result: sidebarVals.result,
    ...updated,
    ...localItemState,
  };

  if (item.reviewed) {
    latestItemState.reviewed = true;
  }

  const updatedVals = {
    assignedToUserID: latestItemState.assignedToUserID
      ? Number(latestItemState.assignedToUserID)
      : item.assignedToUserID
      ? Number(item.assignedToUserID)
      : undefined,
    highPriority: latestItemState.highPriority,
    reviewed: !!(item?.reviewed || latestItemState.reviewed),
    result: latestItemState.result || item.result,
  };

  return (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody
          style={{
            padding: 0,
          }}>
          <Box height='100%' width='100%'>
            <Flex>
              <Box minHeight='100vh' overflowY='auto' width='20%' backgroundColor='#ED4924' />

              <Box width='80%' marginTop='50px' paddingBottom='84px'>
                <Flex justifyContent='center'>
                  <Box width='95%'>
                    <Flex justifyContent='space-between' alignItems='flex-start'>
                      <Flex flex={1}>
                        <Text fontSize='xl' as='b'>
                          {item.orderableItemName}
                        </Text>
                        <Text color='white'>{item.orderableReportID}</Text>
                      </Flex>

                      <Flex alignItems='center'>
                        <Stack spacing={2} direction='row'>
                          <Text fontSize='sm'>High Priority</Text>

                          <Checkbox
                            disabled={!!item.reviewed}
                            colorScheme='brand'
                            size='lg'
                            isChecked={
                              updatedVals.highPriority !== undefined
                                ? !!updatedVals.highPriority
                                : !!item.highPriority
                            }
                            onChange={(e) => {
                              const highPriority = e.target.checked;
                              handleUpdate({ highPriority });
                            }}
                          />
                        </Stack>
                      </Flex>
                    </Flex>

                    <SimpleGrid columns={2} gap={4} marginTop='24px'>
                      <ProviderAndStaffComboBox
                        isDisabled={!!item.reviewed}
                        selectedId={updatedVals.assignedToUserID}
                        selectionCallback={(assignee) => {
                          handleUpdate({
                            assignedToUserID: assignee && Number(assignee.value),
                          });
                        }}
                        placeholder='Assigned To'
                      />
                      <LabResults
                        isDisabled={!!item.reviewed}
                        selectedId={updatedVals.result || undefined}
                        selectionCallback={(result) => {
                          handleUpdate({
                            result,
                          });
                        }}
                      />
                    </SimpleGrid>

                    <Box margin='18px 0 18px 0'>
                      <Notes
                        popupTitle={`Notes - ${name}`}
                        popupDisclaimer='Visible to patient'
                        isDisabled={!!item.reviewed}
                        keynameCategory='LabNotes'
                        placeholder='Add note'
                        disclaimer='Visible to patient'
                        previousNote={latestItemState.notes ?? ''}
                        value={newNote}
                        onChange={(val) => {
                          setNewNote(val);
                          setHasChanges(true);
                        }}
                        suggestionCallback={(notes) => {
                          handleUpdate({
                            notes,
                          });
                        }}>
                        {sidebarVals.notes && (
                          <Box bg='gray.100' p={4}>
                            <Text pb={1} color='gray.500'>
                              <b>Pending changes:</b> {sidebarVals?.notes}
                            </Text>
                          </Box>
                        )}
                      </Notes>
                    </Box>

                    <Box marginBottom='24px'>
                      <Notes
                        popupTitle={`Internal Notes - ${name}`}
                        popupDisclaimer=''
                        isDisabled={!!item.reviewed}
                        keynameCategory='LabNotes'
                        placeholder='Internal Note'
                        disclaimer='Not visible to patient'
                        value={latestItemState.internalNotes ?? ''}
                        onChange={(internalNotes) => {
                          handleUpdate({
                            internalNotes,
                          });
                        }}
                        suggestionCallback={(internalNotes) => {
                          handleUpdate({
                            internalNotes,
                          });
                        }}>
                        {sidebarVals.internalNotes && (
                          <Box bg='gray.100' p={4}>
                            <Text pb={1} color='gray.500'>
                              <b>Pending changes:</b> {sidebarVals?.internalNotes}
                            </Text>
                          </Box>
                        )}
                      </Notes>
                    </Box>

                    <Flex width='100%' border='1px solid #7E90CF' borderRadius='3px'>
                      <Tooltip label={item.reviewed && "Can't open a previously reviewed note"}>
                        <Flex
                          width='50%'
                          cursor='pointer'
                          opacity={item.reviewed ? 0.5 : 1}
                          justifyContent='center'
                          alignItems='center'
                          padding='8px 0'
                          backgroundColor={!latestItemState.reviewed ? '#7E90CF' : 'white'}
                          borderRight='1px solid #7E90CF'
                          transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                          onClick={() => {
                            if (item.reviewed) return;
                            handleUpdate({
                              reviewed: false,
                            });
                          }}>
                          <Text
                            fontSize='md'
                            textColor={latestItemState.reviewed ? '#7E90CF' : 'white'}>
                            Open
                          </Text>
                        </Flex>
                      </Tooltip>

                      <Flex
                        width='50%'
                        cursor='pointer'
                        justifyContent='center'
                        opacity={item.reviewed ? 0.5 : 1}
                        alignItems='center'
                        padding='8px 0'
                        backgroundColor={latestItemState.reviewed ? '#7E90CF' : 'white'}
                        transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                        onClick={() => {
                          if (item.reviewed) return;
                          handleUpdate({
                            reviewed: true,
                          });
                        }}>
                        <Text
                          fontSize='md'
                          textColor={!latestItemState.reviewed ? '#7E90CF' : 'white'}>
                          Reviewed
                        </Text>
                      </Flex>

                      <Box
                        // Extra space for button
                        paddingBottom='48px'
                      />
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Flex>

            <Flex width='100%' position='absolute' bottom='0'>
              <Box width='20%' />
              <Box width='80%' backgroundColor='white' paddingY={4}>
                <Flex justifyContent='center'>
                  <Flex justifyContent='space-between' width='95%'>
                    <Button
                      width='45%'
                      backgroundColor='#7E90CF'
                      _hover={{ backgroundColor: '#4C5E9B' }}
                      onClick={onClose}>
                      Cancel
                    </Button>

                    <Button isDisabled={!hasChanges} onClick={save} width='45%'>
                      Apply
                    </Button>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </>
  );
}
