import { useGetEncounterOrdersQuery } from '../__generated__/graphql';
import { useEncounter } from '.';

export function useGetEncounterOrders() {
  const { activeEncounterID } = useEncounter();

  const { data: encounterOrdersData, ...query } = useGetEncounterOrdersQuery({
    variables: {
      encounterId: activeEncounterID,
    },
  });

  const regularOrders = [
    ...(encounterOrdersData?.getEncounterOrders?.encounterOrderableItems ?? []),
  ]?.sort((a, b) => {
    const aId = a?.orderableReportID ?? 0;
    const bId = b?.orderableReportID ?? 0;
    return bId - aId;
  });

  const immunizationOrders =
    encounterOrdersData?.getEncounterOrders?.encounterImmunizationInjectionItems;

  type Assess = (typeof regularOrders)[0]['orderableItemAssessments'];
  // Extract assessments from regular orders
  const orderAssessments = regularOrders?.reduce<Assess>((acc, order) => {
    if (order.orderableItemAssessments?.length) {
      order.orderableItemAssessments.forEach((assessment) => {
        const exists = acc.find((a) => a.assessmentName === assessment.assessmentName);
        if (!exists) {
          acc.push(assessment);
        }
      });
    }
    return acc;
  }, []);

  const numberOfOrders = (regularOrders?.length ?? 0) + (immunizationOrders?.length ?? 0);

  return {
    regularOrders,
    immunizationOrders,
    orderAssessments,
    numberOfOrders,
    ...query,
  };
}
