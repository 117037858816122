import { isNumber, isString } from 'lodash';

export function isLabSubmissionInputValid(lab: Partial<LabGlobalForm> | undefined) {
  if (!lab) return false;

  return (
    isGlobalFormInputValid(lab) &&
    isString(lab.clinicalInformation) &&
    isString(lab.comments) &&
    !!lab.reportId &&
    !!lab.specimenCollected
  );
}
export function isGlobalFormInputValid(lab: LabGlobalForm) {
  return (
    isNumber(lab.actualFasting) &&
    !!lab.collectionDateTime &&
    isNumber(lab.orderingFacilityId) &&
    !!lab.sendToLabCompanyId
  );
}
