import {
  appointmentsActions,
  type EditedOrder,
  modalActions,
  selectors,
  useAppDispatch,
  useAppSelector,
} from '../../../state-management';
import { Card } from '../Card';
import { Box, Flex, HStack } from '../../layout';
import { Button } from '../../form';
import { Text } from '../../typography';
import { SelectDiagnosis } from '../SelectDiagnosis';
import { SelectOrderableItem } from '../../../features';

interface ConfirmOrderCardProps {
  active?: boolean;
  alertId: number;
}

function Controls({ alertId }: { alertId: number }) {
  const dispatch = useAppDispatch();
  const editedOrder = useAppSelector(selectors.editedOrdersSelectors.selectById(alertId));
  const isAcceptedOrder = editedOrder?.orderType === 'ACCEPTED';
  const editing = isEditing(editedOrder);
  let renderButtons;

  function handleEditOrderLineItem() {
    if (isAcceptedOrder) {
      dispatch(appointmentsActions.editOrderLineItem({ alertId }));
    } else {
      dispatch(appointmentsActions.editOrderLineItem({ alertId }));
      dispatch(
        modalActions.showModal({
          modalProps: { alertId },
          modalType: 'DeclineReasonModal',
        }),
      );
    }
  }

  function handleRemoveOrderLineItem() {
    dispatch(appointmentsActions.resetOrderLineItem({ alertId }));
  }

  function handleCancelEdit() {
    dispatch(appointmentsActions.cancelOrderLineItemEdit({ alertId }));
  }

  if (editing) {
    renderButtons = (
      <HStack spacing='sm' justifyContent='flex-end'>
        <Button variant='outline' onClick={handleCancelEdit}>
          Cancel Edit
        </Button>
      </HStack>
    );
  } else {
    renderButtons = (
      <HStack spacing='sm' justifyContent='flex-end'>
        <Button variant='outline' onClick={handleEditOrderLineItem}>
          Edit
        </Button>
        <Button
          // colorScheme="red"
          variant='outline'
          onClick={handleRemoveOrderLineItem}>
          Remove
        </Button>
      </HStack>
    );
  }

  return renderButtons;
}

function isEditing(order: EditedOrder) {
  const editedOrderStatus = order.status;
  const isEditing =
    editedOrderStatus === 'YES_EDITING_DIAGNOSIS' ||
    editedOrderStatus === 'NO_EDITING_DECLINE_REASON';

  return isEditing;
}

export function SavedOrderLineItemCard({ active, alertId }: ConfirmOrderCardProps) {
  const order = useAppSelector(selectors.editedOrdersSelectors.selectById(alertId));
  const alert = order.alert;
  const isAcceptedOrder = order?.orderType === 'ACCEPTED';
  const editing = isEditing(order);
  let renderContent;

  if (isAcceptedOrder) {
    if (editing) {
      renderContent = (
        <HStack spacing='md'>
          <SelectOrderableItem alertId={alertId} />
          <SelectDiagnosis alertId={alertId} />
        </HStack>
      );
    } else {
      const selectedOrderableItemName = order.selectedOrderableItem?.orderableItemName;

      renderContent = (
        <Box>
          {selectedOrderableItemName && <Text>Immunization Name: {selectedOrderableItemName}</Text>}
          <Text>Assessments: {order?.diagnosis?.diagnosisName}</Text>
        </Box>
      );
    }
  } else {
    const declineReason = order?.declineReason;
    const renderInfoText = `Decline reason: ${declineReason?.name}`;
    renderContent = (
      <Box>
        <Text>{renderInfoText}</Text>
        <Text>
          {order.declineReason?.additionalDetails
            ? `Additional details: ${order.declineReason?.additionalDetails}`
            : ''}
        </Text>
      </Box>
    );
  }

  return (
    <Card active={active} variant={isAcceptedOrder ? 'accept' : 'reject'}>
      <Flex>
        <Box flex='1'>
          <Box mb='md'>
            <Text variant='h6-b'>{alert?.shortDescription}</Text>
          </Box>
          <HStack spacing='lg' w='full' justifyContent='space-between' alignItems='flex-end'>
            <Box flexGrow={1}>{renderContent}</Box>
            <Controls alertId={alertId} />
          </HStack>
        </Box>
      </Flex>
    </Card>
  );
}
