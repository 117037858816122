import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  VStack,
  HStack,
  Checkbox,
  Box,
} from '@chakra-ui/react';
import {
  type EncounterOrderableItem,
  type UpdateOrderableItemInput,
  useGetEncounterOrdersQuery,
  useUpdateOrderableItemAndResultsMutation,
} from '../../../__generated__/graphql';
import { useAuth, useCustomToast, useEncounter } from '../../../hooks';
import { Flex } from '../../../components';
import { type DiagnosisUI } from '../../../types';
import { useState } from 'react';
import { PatientAssessmentSelect } from '../../../features/PatientAssessmentSelect';
import { useAppDispatch, worklistTodoActions } from '../../../state-management';

export function EditRegularOrderButton({ order }: { order: EncounterOrderableItem | null }) {
  const ecwId = useAuth().user?.ecwId;
  const reviewedByUserId = ecwId ? Number(ecwId) : null;
  const dispatch = useAppDispatch();

  const toast = useCustomToast();
  const [updateOrderableItemAndResults, { loading: loadingUpdateOrderableItemAndResults }] =
    useUpdateOrderableItemAndResultsMutation();

  const initialUpdateOrderInput = {
    futureOrder: order?.futureOrder ?? false,
    orderableReportID: order?.orderableReportID ?? 0,
    fasting: order?.fasting ?? false,
    reviewedByUserId: reviewedByUserId ?? 0,
  };
  const [updateOrderInput, setUpdateOrderInput] =
    useState<UpdateOrderableItemInput>(initialUpdateOrderInput);

  function handleOrderChange(update: Partial<UpdateOrderableItemInput>) {
    setUpdateOrderInput((state) => ({ ...state, ...update }));
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOnClose = () => {
    setUpdateOrderInput(initialUpdateOrderInput);
    setSelectedAssessments([]);
    onClose();
  };

  const [selectedAssessments, setSelectedAssessments] = useState<DiagnosisUI[]>([]);

  const { activeEncounterID } = useEncounter();
  const { data: encounterOrdersData } = useGetEncounterOrdersQuery({
    variables: {
      encounterId: activeEncounterID,
    },
  });
  const existingOrderableItems =
    encounterOrdersData?.getEncounterOrders?.encounterOrderableItems ?? [];

  function handleSubmit() {
    // Prevent adding duplicate orders
    const hasAddedOrder = existingOrderableItems.some((oi) => {
      const isCurrentOrder = oi.orderableReportID === Number(updateOrderInput?.orderableReportID);
      const orderExists =
        !isCurrentOrder &&
        oi.labItemId === Number(order?.labItemId) &&
        oi.futureOrder === updateOrderInput.futureOrder;

      return orderExists;
    });

    if (hasAddedOrder) {
      toast({
        title: 'Already exists',
        description: 'You cannot add a duplicate order',
        status: 'warning',
        id: 'order-already-added',
      });

      return;
    }

    if (updateOrderInput?.orderableReportID && reviewedByUserId) {
      const input: UpdateOrderableItemInput = {
        ...updateOrderInput,
        orderableItemAssessments: selectedAssessments.map((a) => {
          return {
            assessmentValue: a.icdCode,
            assessmentId: a.assessmentItemId,
            assessmentName: a.diagnosisName,
            labId: order?.orderableReportID,
          };
        }),
      };

      updateOrderableItemAndResults({
        variables: {
          orderableItem: input,
        },
        update: (cache) => {
          // Invalidate cache
          cache.evict({
            fieldName: 'getEncounterOrders',
          });
        },
        onError: (error) => {
          toast({
            id: 'update-oi-toast-error',
            title: 'Error',
            description: error.message,
            status: 'error',
          });
        },
        onCompleted: () => {
          // Add constellation item
          dispatch(
            worklistTodoActions.updateConstellationItem({
              id: updateOrderInput.orderableReportID,
              futureOrder: !!updateOrderInput.futureOrder,
            }),
          );

          toast({
            id: 'update-oi-toast-success',
            title: 'Success',
            description: 'Changed successfully',
            status: 'success',
          });
          handleOnClose();
        },
      });
    }
  }

  return (
    <>
      <Button onClick={onOpen} variant={'outline'}>
        Edit
      </Button>
      <Modal isOpen={isOpen} onClose={handleOnClose} size={'6xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Order</ModalHeader>
          <ModalCloseButton />
          <ModalBody minH='70vh'>
            <HStack spacing={'lg'} align='flex-start'>
              <Box flex='1'>
                <PatientAssessmentSelect
                  onChange={setSelectedAssessments}
                  initialOrderableItemAssessments={order?.orderableItemAssessments}
                />
              </Box>

              <VStack
                flex='2'
                align={'left'}
                borderBottom={'1px solid'}
                borderBottomColor={'gray.300'}
                spacing={'lg'}
                pb='lg'>
                <Text fontWeight='bold'>Order</Text>
                <Flex justifyContent={'space-between'} w='full'>
                  <Text variant='h6-b'>{order?.orderableItemName}</Text>
                  <HStack spacing={'lg'}>
                    <HStack spacing={'md'}>
                      <Checkbox
                        isChecked={updateOrderInput?.fasting ?? false}
                        onChange={() => {
                          handleOrderChange({ fasting: !updateOrderInput?.fasting });
                        }}>
                        <Text whiteSpace='nowrap'>Ordered fasting?</Text>
                      </Checkbox>
                      <Checkbox
                        isChecked={updateOrderInput?.futureOrder ?? false}
                        onChange={() => {
                          handleOrderChange({ futureOrder: !updateOrderInput?.futureOrder });
                        }}>
                        <Text whiteSpace='nowrap'>Future order</Text>
                      </Checkbox>
                    </HStack>
                  </HStack>
                </Flex>
              </VStack>
            </HStack>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr={3} onClick={handleOnClose}>
              Cancel
            </Button>
            <Button
              variant='solid'
              isLoading={loadingUpdateOrderableItemAndResults}
              onClick={handleSubmit}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
