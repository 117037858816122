import { Textarea } from '@chakra-ui/react';
import { useLabCollectionForm } from './useLabCollectionForm';

export function ClinicalInfoInput({
  value,
  onChange,
}: {
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}) {
  const { handleUpdateGlobalLabForm, globalLabForm } = useLabCollectionForm();

  function handleUpdateGlobalFormClinicalInfo(e: React.ChangeEvent<HTMLTextAreaElement>) {
    handleUpdateGlobalLabForm({
      clinicalInformation: e.target.value,
    });
  }

  return (
    <Textarea
      placeholder='Clinical Info'
      rows={2}
      value={value || (globalLabForm?.clinicalInformation ?? undefined)}
      onChange={onChange || handleUpdateGlobalFormClinicalInfo}
    />
  );
}
