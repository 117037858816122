import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Td,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { type ImmunizationInjectionItem } from '../../../../__generated__/graphql';
import { DisabledInjectionForm } from '../../../../features/DisabledInjectionForm';
import { formatDate } from '../../../../utils';

export function RowWithDisabledInjectionFormModal({
  injection,
}: {
  injection: ImmunizationInjectionItem;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tr
        key={injection.immunizationId}
        onClick={onOpen}
        _hover={{ cursor: 'pointer', bgColor: 'blue.50' }}>
        <Td>{injection.immunizationName}</Td>
        <Td>{injection.dose}</Td>
        <Td>{injection.dateTimeAdministered ? formatDate(injection.dateTimeAdministered) : ''}</Td>
        <Td>{injection.injectionLocation}</Td>
        <Td>{injection.lotId}</Td>
      </Tr>

      <Modal isOpen={isOpen} onClose={onClose} size='6xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <DisabledInjectionForm injection={injection} />
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' onClick={onClose}>
              Back
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
