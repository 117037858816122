import { Checkbox, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { Card, WaitUntilLoaded } from '../../components';
import { AssessmentSearch } from './AssessmentSearch';
import { isEmpty } from 'lodash';
import { usePatientAssessments } from './usePatientAssessments';
import { type Assessment } from '../../__generated__/graphql';
import { useEffect } from 'react';
import { type DiagnosisUI } from '../../types';

export function PatientAssessmentSelect({
  initialOrderableItemAssessments,
  onChange,
}: {
  initialOrderableItemAssessments?: Array<
    Pick<Assessment, 'assessmentId' | 'assessmentName' | 'assessmentValue'>
  >;
  onChange: (selectedAssessments: DiagnosisUI[]) => void;
}) {
  const { isLoading, diagnosisList, setDiagnosisList } = usePatientAssessments({
    orderableItemAssessments: initialOrderableItemAssessments,
  });

  useEffect(() => {
    // get the list of selected assessments from diagnosisList
    const selectedAssessments = diagnosisList.filter((a) => a.isSelected);

    onChange(selectedAssessments);
  }, [diagnosisList]);

  return (
    <VStack alignItems={'stretch'}>
      <Text fontWeight='bold' mb='lg'>
        Assessments
      </Text>
      <WaitUntilLoaded loading={isLoading}>
        <AssessmentSearch
          onChange={(value) => {
            // don't allow empty values
            if (!value.assessementItemId) return;

            // don't allow duplicate assessments
            const isAlreadyAdded = diagnosisList.some(
              (a) => a.assessmentItemId === value.assessementItemId,
            );
            if (isAlreadyAdded) return;

            setDiagnosisList((state) => [
              {
                assessmentItemId: value.assessementItemId,
                diagnosisName: value.assessmentName,
                icdCode: value.assessmentCode,
                isSelected: true,
              },
              ...state,
            ]);
          }}
        />

        <VStack
          // overflow='visible'
          overflow='auto'
          maxH='56vh'>
          {isEmpty(diagnosisList) ? (
            <Text>No assessments found</Text>
          ) : (
            diagnosisList.map((a) => {
              return (
                <Card size={'sm'} key={a.assessmentItemId}>
                  <Flex justifyContent='space-between'>
                    <Text variant='body2-b'>{a.diagnosisName}</Text>
                    <HStack>
                      <Text variant='body2' color='gray.500'>
                        {a.icdCode}
                      </Text>
                      <Checkbox
                        isChecked={a.isSelected ?? false}
                        onChange={(e) => {
                          setDiagnosisList((state) => {
                            // if the assessment is already in the list, update the isSelected value
                            // else add the assessment to the list

                            const updatedState = state.map((s) => {
                              if (s.assessmentItemId === a.assessmentItemId) {
                                return {
                                  ...s,
                                  isSelected: e.target.checked,
                                };
                              }
                              return s;
                            });

                            if (
                              !updatedState.some((s) => s.assessmentItemId === a.assessmentItemId)
                            ) {
                              return [
                                ...updatedState,
                                {
                                  ...a,
                                  isSelected: e.target.checked,
                                },
                              ];
                            }

                            return updatedState;
                          });
                        }}
                      />
                    </HStack>
                  </Flex>
                </Card>
              );
            })
          )}
        </VStack>
      </WaitUntilLoaded>
    </VStack>
  );
}
