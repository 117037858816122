import {
  Box,
  CloseButton,
  Flex,
  Heading,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { AppRoutes } from './AppRoutes';
import { useSidebar } from '../hooks';
import { BiDockLeft, BiDockRight } from 'react-icons/bi';
import { useState } from 'react';
import { Scrollable } from '../components';
import { VscKebabVertical } from 'react-icons/vsc';

export function AppRoutesWithSideBar() {
  const { closeSidebar, sidebarState, moveSidebar } = useSidebar();
  const [sidebarSize, setSidebarSize] = useState<'S' | 'M' | 'L'>('M');
  const { sidebarPosition } = sidebarState;
  const isSidebarLeft = sidebarPosition === 'left';
  const sidebarPositionStyles = isSidebarLeft
    ? { left: 0, borderRight: '1px solid' }
    : { right: 0, borderLeft: '1px solid' };
  const sidebarWidth = sidebarSize === 'S' ? '20vw' : sidebarSize === 'M' ? '30vw' : '40vw';

  return (
    <Flex w='full'>
      <Box flex='1'>
        <AppRoutes />
      </Box>

      {sidebarState.open && (
        <Box
          position='fixed'
          {...sidebarPositionStyles}
          borderColor='gray.200'
          width={sidebarWidth}
          flex='1'
          h='100vh'
          bg='white'
          zIndex={'popover'}>
          <Flex align='center' px='md' pt='md'>
            {sidebarState.sidebarHeader && (
              <Heading fontSize='lg'>{sidebarState.sidebarHeader}</Heading>
            )}
            <Spacer />
            <Popover>
              <PopoverTrigger>
                <IconButton
                  aria-label='menu-button'
                  size='sm'
                  icon={<VscKebabVertical />}
                  colorScheme='gray'
                  variant='ghost'
                />
              </PopoverTrigger>
              <PopoverContent w='fit-content'>
                <PopoverArrow />
                <PopoverBody>
                  <Box w='full'>
                    <Flex align='center'>
                      <Text fontSize='sm'>Dock side</Text>
                      <Flex>
                        <IconButton
                          color={isSidebarLeft ? 'orange.500' : 'gray.500'}
                          p='0'
                          variant='link'
                          aria-label='dock-left'
                          onClick={() => {
                            moveSidebar('left');
                          }}
                          icon={<Icon as={BiDockLeft} />}
                        />
                        <IconButton
                          p='0'
                          color={isSidebarLeft ? 'gray.500' : 'orange.500'}
                          variant='link'
                          aria-label='dock-right'
                          onClick={() => moveSidebar('right')}
                          icon={<Icon as={BiDockRight} />}
                        />
                      </Flex>
                    </Flex>

                    <Flex align='center'>
                      <Text fontSize='sm'>Size</Text>
                      <Flex>
                        <IconButton
                          p='0'
                          color={sidebarSize === 'S' ? 'orange.500' : 'gray.500'}
                          variant='link'
                          aria-label='size-small'
                          onClick={() => {
                            setSidebarSize('S');
                          }}
                          icon={<Text fontSize='xs'>S</Text>}
                        />
                        <IconButton
                          p='0'
                          color={sidebarSize === 'M' ? 'orange.500' : 'gray.500'}
                          variant='link'
                          aria-label='size-medium'
                          onClick={() => {
                            setSidebarSize('M');
                          }}
                          icon={<Text fontSize='sm'>M</Text>}
                        />
                        <IconButton
                          p='0'
                          color={sidebarSize === 'L' ? 'orange.500' : 'gray.500'}
                          variant='link'
                          aria-label='size-large'
                          onClick={() => {
                            setSidebarSize('L');
                          }}
                          icon={<Text fontSize='lg'>L</Text>}
                        />
                      </Flex>
                    </Flex>
                  </Box>
                </PopoverBody>
              </PopoverContent>
            </Popover>

            <CloseButton onClick={closeSidebar} ml='auto' />
          </Flex>

          <Scrollable p='md'>{sidebarState.sidebarContent}</Scrollable>
        </Box>
      )}
    </Flex>
  );
}
