import {
  Badge,
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  type EncounterOrderableItem,
  useDeleteOrderableItemMutation,
} from '../../../__generated__/graphql';
import { Card } from '../../../components';
import { useCustomToast } from '../../../hooks';
import { useAppDispatch, worklistTodoActions } from '../../../state-management';
import { EditRegularOrderButton } from './EditRegularOrderButton';

export function TreatmentPlanOrderCard({ order }: { order?: EncounterOrderableItem | null }) {
  if (!order) {
    return null;
  }

  return (
    <Card>
      <Box mb='md'>
        <Text variant='h6-b'>{order?.orderableItemName}</Text>
      </Box>
      <Box>
        <Flex justifyContent='space-between' alignItems={'center'} width='full'>
          <Text>
            Assessments:{' '}
            {order.orderableItemAssessments
              .map((assessment) => assessment?.assessmentName)
              .join(', ') ?? 'None'}
          </Text>
          <Flex gap='sm' alignItems='center'>
            {order.futureOrder && (
              <Badge bg='primary' rounded='full'>
                <Text fontSize='xs' color='white' textTransform='none'>
                  Future Order
                </Text>
              </Badge>
            )}

            <EditRegularOrderButton
              order={order}
              // key prop forces a refresh whenever the fasting or futureOrder changes from the server. Otherwise, if the modal is re-opened
              // right after the user changes the fasting or futureOrder, the modal will not reflect the latest fetched changes from the server.
              key={`${order.labItemId} ${order.fasting} ${order.futureOrder}`}
            />
            <DeleteButton order={order} />
          </Flex>
        </Flex>
      </Box>
    </Card>
  );
}

function DeleteButton({ order }: { order?: EncounterOrderableItem | null }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const orderName = order?.orderableItemName;
  const toast = useCustomToast();
  const dispatch = useAppDispatch();

  const [deleteOrderableItem, { loading: isLoadingDeleteOrderableItem }] =
    useDeleteOrderableItemMutation();

  function handleDelete() {
    if (!order) {
      return;
    }

    deleteOrderableItem({
      variables: { orderableReportId: order.orderableReportID ?? -1 },
      update: (cache) => {
        // Invalidate cache
        cache.evict({
          fieldName: 'getEncounterOrders',
        });
      },
      onCompleted: () => {
        toast({
          id: 'delete-order-error',
          title: 'Success',
          description: 'Item Deleted',
          status: 'success',
        });
        dispatch(worklistTodoActions.removeLabItems([order.orderableReportID || -1]));
        onClose();
      },
      onError: (error) => {
        toast({
          id: 'delete-order-success',
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 5000,
        });
      },
    });
  }

  return (
    <>
      <Button variant='outline' onClick={onOpen}>
        Delete
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`Remove ${orderName} From The Treatment Plan?`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{`Are you sure you want to remove ${orderName} from the Treatment Plan?`}</Text>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr={'sm'} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleDelete} isLoading={isLoadingDeleteOrderableItem}>
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
