import { useEffect, useState } from 'react';
import {
  Box,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Select,
  Checkbox,
  Icon,
  Button,
  Textarea,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  HStack,
  Flex,
  Divider,
  Switch,
  FormLabel,
  FormControl,
  Spinner,
} from '../../../components';
import { AiOutlineCalendar as DateIcon } from 'react-icons/ai';
import {
  Center,
  type CheckboxProps,
  InputRightElement,
  NumberInput,
  NumberInputField,
  VStack,
} from '@chakra-ui/react';
import { modalActions, uiActions, useAppDispatch } from '../../../state-management';
import { MAWorkFlowHeader } from '../MAWorkFlowHeader';
import { MdOutlineStickyNote2 as NoteIcon } from 'react-icons/md';
import { BiPlus as PlusIcon, BiMinus as MinusIcon } from 'react-icons/bi';
import { MAWorkFlowContentContainer } from '../MAWorkFlowContentContainer';
import { useEncounter, useMAWorkflow, usePatient, useToast } from '../../../hooks';
import { isEmpty } from 'lodash';
import { SaveAndWorklistButtons } from '../../components/SaveAndChecklistButtons';
import {
  FamilyMemberStatusOption,
  defaultFamilyMemberNames,
  icdCodeSorter,
  orderedMemeberTypes,
  transformFormFamilyHistoryToFamilyHistoryUI,
  createDefaultFamilyHistoryForPatient,
} from './FamilyHistoryContent.helpers';
import {
  type FamilyMembers,
  type UpdateFamilyHistory,
  useGetFamilyHistoryIcdCodesQuery,
  useGetFamilyHistoryQuery,
  useUpdateFamilyHistoryMutation,
} from '../../../__generated__/graphql';
import { FullStory as FS } from '@fullstory/browser';

export function FamilyHistoryContent({ nextStep }: { nextStep: () => void }) {
  const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);
  const { familyHistory, getHasLoadedInitialData } = useMAWorkflow();
  const { markCurrentStepComplete } = useMAWorkflow();
  const { familyMembers, familyHistoryUI, handleSetFamilyMember, handleSetFamilyHistory } =
    familyHistory;
  familyMembers.sort(
    (m1, m2) =>
      orderedMemeberTypes[m1.familyMemberType || 'NA'] -
      orderedMemeberTypes[m2.familyMemberType || 'NA'],
  );
  const dispatch = useAppDispatch();
  const { patientId } = usePatient();
  const { activeEncounterID } = useEncounter();
  const [showYobIndex, setShowYobIndex] = useState<number | undefined>();
  const [
    handleUpdateFamilyHistory,
    { loading: isLoadingUpdate, data: updateData, error: errorUpdate, called: calledHandleUpdate },
  ] = useUpdateFamilyHistoryMutation();
  useEffect(() => {
    if (calledHandleUpdate && !errorUpdate) {
      markCurrentStepComplete();
      nextStep();
    }
  }, [updateData]);
  const hasLoadedInitialData = getHasLoadedInitialData('family-history');

  const { data: dataFamilyHistoryData, loading: isLoadingFamilyHistory } = useGetFamilyHistoryQuery(
    {
      variables: { patientId },
      skip: !patientId || hasLoadedInitialData,
    },
  );
  const dataFamilyHistory = dataFamilyHistoryData?.getFamilyHistory;
  const isSuccessFamilyHistory = !!dataFamilyHistoryData;
  const { data: dataICDCodesData, loading: isLoading } = useGetFamilyHistoryIcdCodesQuery({
    variables: { contains: '' },
  });
  const dataICDCodes = dataICDCodesData?.getFamilyHistoryICDCodes || [];
  const sortedICDCodes = [...dataICDCodes].sort(icdCodeSorter);
  useEffect(() => {
    if (isSuccessFamilyHistory && !hasLoadedInitialData) {
      dispatch(uiActions.setHasLoadedInitialData('family-history'));
    }
  }, [isSuccessFamilyHistory, hasLoadedInitialData]);
  function handleAddNewPerson() {
    const newPerson: FamilyMemberUI = {
      tempId: new Date().toString(),
      status: FamilyMemberStatusOption.Unknown,
      customFamilyMemberFlag: true,
      familyMemberDiagnosisList: [],
      notes: null,
      age: null,
      yearOfBirth: null,
      familyMemberType: null,
    };
    handleSetFamilyMember({ familyMember: newPerson });
  }
  useEffect(() => {
    const familyHistoryUI = transformFormFamilyHistoryToFamilyHistoryUI(
      dataFamilyHistory || createDefaultFamilyHistoryForPatient(patientId),
    );
    if (familyHistoryUI && !hasLoadedInitialData) {
      handleSetFamilyHistory(familyHistoryUI);
    }
  }, [dataFamilyHistory]);

  // Setup toasts
  useToast({
    id: 'isSuccessUpdate',
    title: 'Success',
    description: 'Your submission was successful.',
    show: showUpdateSuccess,
    status: 'success',
  });
  useToast({
    id: 'isErrorUpdate',
    title: 'Error',
    description: 'Something went wrong.',
    show: !!errorUpdate,
    status: 'error',
  });
  function onSubmit() {
    const { familyMembersHistory, sourcePatientId, ...rest } = familyHistoryUI;
    const serverFamilyMembers: FamilyMembers[] = familyMembers.map(
      ({ tempId, familyMemberDiagnosisList, ...rest }) => {
        return {
          ...rest,
          familyMemberDiagnosisList: familyMemberDiagnosisList.map((d) => {
            return {
              icdItemId: d.icdItemId,
              value: d.isChecked ? '1' : '0',
            };
          }),
        };
      },
    );
    const familyHistory: UpdateFamilyHistory = {
      familyMembers: rest.nonContributory ? [] : serverFamilyMembers,
      additionalNotes: rest.additionalNotes || null,
      childrenHealthy: rest.childrenHealthy || true,
      familyHistoryNotes: rest.familyHistoryNotes || null,
      nonContributory: rest.nonContributory || false,
      numberOfBrothers: rest.numberOfBrothers || 0,
      numberOfDaughters: rest.numberOfDaughters || 0,
      numberOfSisters: rest.numberOfSisters || 0,
      numberOfSons: rest.numberOfSons || 0,
      siblingHealthy: rest.siblingHealthy || true,
    };

    FS('trackEvent', {
      name: 'Save / Verify Family History Clicked', 
      properties: { encounter_id: activeEncounterID }
    });

    handleUpdateFamilyHistory({
      variables: {
        patientId,
        encounterId: activeEncounterID,
        familyHistory,
      },
      onCompleted: () => {
        setShowUpdateSuccess(true);
      },
      onError: (err) => {
        console.log('Error loading error', err);
      },
    });
  }

  const disableFields = familyHistoryUI.nonContributory || false;

  const renderAddNewPerson = (
    <Box>
      <Button
        variant='outline'
        onClick={handleAddNewPerson}
        leftIcon={<Icon as={PlusIcon} />}
        isDisabled={disableFields}>
        New Person
      </Button>
    </Box>
  );
  return (
    <MAWorkFlowContentContainer>
      <VStack spacing='lg' alignItems='stretch'>
        <MAWorkFlowHeader>Family History</MAWorkFlowHeader>

        {/* TODO: consolidate to one call */}
        {isLoadingFamilyHistory || isLoading ? (
          <Spinner />
        ) : isEmpty(familyMembers) ? (
          <Center>
            <VStack>
              <Text color='secondary'>To get started, add a new person.</Text>
              {renderAddNewPerson}
            </VStack>
          </Center>
        ) : (
          <>
            <Flex>
              <FormControl display='flex' alignItems='center' justifyContent='flex-end'>
                <FormLabel htmlFor='non-contributory' mb='0'>
                  Is Family History pertinent to this visit?
                </FormLabel>
                <Switch
                  id='non-contributory'
                  defaultChecked={!familyHistoryUI.nonContributory}
                  onChange={() => {
                    handleSetFamilyHistory({
                      ...familyHistoryUI,
                      nonContributory: !familyHistoryUI.nonContributory,
                    });
                  }}
                />
              </FormControl>
            </Flex>

            <TableContainer>
              <Table variant='simple' size='sm'>
                <Thead>
                  <Tr>
                    <Th />
                    {!isEmpty(sortedICDCodes) &&
                      [
                        'Status',
                        'YOB/Age',
                        'Note',
                        ...(sortedICDCodes?.map((item) => item.customName) || []),
                      ]?.map((th) => <Th key={th}>{th}</Th>)}
                  </Tr>
                </Thead>

                <Tbody>
                  {familyMembers?.map((fam, idx) => {
                    const { yearOfBirth } = fam;
                    // const yearOfBirth = 1993
                    const age =
                      yearOfBirth && yearOfBirth.length === 4
                        ? new Date().getFullYear() - +yearOfBirth
                        : undefined;
                    function handleOpenModal() {
                      dispatch(
                        modalActions.showModal({
                          modalType: 'FamilyHistoryModal',
                          chakraModalProps: { size: '2xl' },
                          modalProps: {
                            familyMember: fam,
                          },
                        }),
                      );
                    }

                    return (
                      <Tr key={fam.tempId}>
                        <Td>
                          {idx < defaultFamilyMemberNames.length ? (
                            <Text color={disableFields ? 'secondary' : 'default'} w={135}>
                              {fam.familyMemberType}
                            </Text>
                          ) : (
                            <Input
                              isDisabled={disableFields}
                              w={135}
                              value={fam.familyMemberType || ''}
                              onChange={(e) => {
                                handleSetFamilyMember({
                                  familyMember: {
                                    ...fam,
                                    familyMemberType: e.target.value,
                                  },
                                });
                              }}
                            />
                          )}
                        </Td>
                        <Td>
                          <Select
                            isDisabled={disableFields}
                            w='130px'
                            value={fam.status || ''}
                            onChange={(e) => {
                              handleSetFamilyMember({
                                familyMember: {
                                  ...fam,
                                  status: e.target.value,
                                },
                              });
                            }}>
                            {[
                              { value: 'alive', label: 'Alive' },
                              { value: 'deceased', label: 'Deceased' },
                              { value: 'unknown', label: 'Unknown' },
                            ].map(({ value, label }) => (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            ))}
                          </Select>
                        </Td>
                        <Td>
                          <HStack alignItems='center' spacing='xs'>
                            <Box w='110px'>
                              {showYobIndex === idx ? (
                                <InputGroup>
                                  <NumberInput isDisabled={disableFields}>
                                    <NumberInputField
                                      placeholder='YYYY'
                                      maxLength={4}
                                      value={yearOfBirth || ''}
                                      onBlur={() => {
                                        setShowYobIndex(undefined);
                                      }}
                                      onChange={(e) => {
                                        handleSetFamilyMember({
                                          familyMember: {
                                            ...fam,
                                            yearOfBirth: e.target.value,
                                          },
                                        });
                                      }}
                                    />
                                  </NumberInput>
                                  <InputRightElement>
                                    <Text color={disableFields ? 'secondary' : 'default'}>
                                      {age && `(${age})`}
                                    </Text>
                                  </InputRightElement>
                                </InputGroup>
                              ) : yearOfBirth ? (
                                <Text
                                  ml={4}
                                  color={disableFields ? 'secondary' : 'default'}
                                  onClick={() => {
                                    setShowYobIndex(idx);
                                  }}>
                                  {yearOfBirth} {age && `(${age})`}
                                </Text>
                              ) : (
                                <IconButton
                                  ml={2}
                                  color={!fam.notes ? 'secondary' : 'brand.500'}
                                  onClick={() => {
                                    setShowYobIndex(idx);
                                  }}
                                  variant='link'
                                  isDisabled={disableFields}
                                  icon={<DateIcon size={24} />}
                                  aria-label='Family Note'
                                />
                              )}
                            </Box>
                          </HStack>
                        </Td>
                        <Td>
                          <IconButton
                            color={!fam.notes ? 'secondary' : 'brand.500'}
                            onClick={handleOpenModal}
                            variant='link'
                            isDisabled={disableFields}
                            icon={<NoteIcon size={24} />}
                            aria-label='Family Note'
                          />
                        </Td>
                        {sortedICDCodes?.map((item) => (
                          <CheckboxTd
                            isDisabled={disableFields}
                            key={item.fhItemID}
                            conditionId={item.fhItemID.toString()}
                            familyMember={fam}
                          />
                        ))}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            {renderAddNewPerson}
          </>
        )}
      </VStack>
      <Box py='lg'>
        <RelativeCountsInfo />
      </Box>
      <SaveAndWorklistButtons onClick={onSubmit} isLoading={isLoadingUpdate}>
        Save / Verify Family History
      </SaveAndWorklistButtons>
    </MAWorkFlowContentContainer>
  );
}

function RelativeCountsInfo() {
  const { familyHistory, maWorkFlowState } = useMAWorkflow();
  const { handleSetFamilyHistory } = familyHistory;
  const fam = maWorkFlowState.familyHistory;

  return (
    <>
      <HStack borderTop='1px' borderTopColor='secondaries.1'>
        <Flex justifyContent='space-between' flex='1'>
          <Box alignSelf='center'>
            <Text variant='body-b'>Siblings</Text>
          </Box>

          <HStack spacing='lg'>
            <RelativeCounter
              name='Brothers'
              value={familyHistory.numberOfBrothers || 0}
              onChange={(val) => {
                handleSetFamilyHistory({
                  ...fam,
                  numberOfBrothers: val,
                });
              }}
            />
            <RelativeCounter
              name='Sisters'
              value={familyHistory.numberOfSisters || 0}
              onChange={(val) => {
                handleSetFamilyHistory({
                  ...fam,
                  numberOfSisters: val,
                });
              }}
            />
          </HStack>

          <HealthyCheckbox
            isChecked={fam.siblingHealthy || true}
            onChange={() => {
              handleSetFamilyHistory({
                ...fam,
                siblingHealthy: !fam.siblingHealthy,
              });
            }}
          />
        </Flex>

        <Box h='100px'>
          <Divider orientation='vertical' borderStyle='dashed' borderColor='secondaries.1' />
        </Box>

        <Flex justifyContent='space-between' flex='1'>
          <Box alignSelf='center'>
            <Text variant='body-b'>Children</Text>
          </Box>
          <HStack spacing='lg'>
            <RelativeCounter
              name='Sons'
              value={familyHistory.numberOfSons || 0}
              onChange={(val) => {
                handleSetFamilyHistory({
                  ...fam,
                  numberOfSons: val,
                });
              }}
            />
            <RelativeCounter
              name='Daughters'
              value={familyHistory.numberOfDaughters || 0}
              onChange={(val) => {
                handleSetFamilyHistory({
                  ...fam,
                  numberOfDaughters: val,
                });
              }}
            />
          </HStack>
          <HealthyCheckbox
            isChecked={fam.childrenHealthy || true}
            onChange={() => {
              handleSetFamilyHistory({
                ...fam,
                childrenHealthy: !fam.childrenHealthy,
              });
            }}
          />
        </Flex>
      </HStack>

      <Textarea
        placeholder='Notes'
        noOfLines={5}
        value={fam.familyHistoryNotes || ''}
        onChange={(e) => {
          handleSetFamilyHistory({
            ...fam,
            familyHistoryNotes: e.target.value,
          });
        }}
      />
    </>
  );
}

function CheckboxTd({
  familyMember,
  conditionId,
  isDisabled,
}: {
  isDisabled?: boolean;
  familyMember: FamilyMemberUI;
  conditionId: ICDItemId;
  // condition: FamilyMemberCondition;
}) {
  const { familyHistory } = useMAWorkflow();
  const { handleToggleFamilyMemberCondition } = familyHistory;

  const isChecked = familyMember.familyMemberDiagnosisList.find(
    (condition) => conditionId === condition.icdItemId,
  )?.isChecked;

  return (
    <Td>
      <Center>
        <Checkbox
          isDisabled={isDisabled}
          colorScheme='brand'
          isChecked={isChecked}
          onChange={() => {
            handleToggleFamilyMemberCondition({
              tempId: familyMember.tempId,
              icdItemId: conditionId,
            });
          }}
        />
      </Center>
    </Td>
  );
}

function HealthyCheckbox(props: CheckboxProps) {
  return (
    <VStack pr='lg'>
      <Text>Healthy</Text>
      <Checkbox colorScheme='brand' size='lg' {...props} />
    </VStack>
  );
}

function RelativeCounter({
  name,
  value = 0,
  onChange = () => {},
}: {
  name: string;
  value?: number;
  onChange?: (v: number) => void;
}) {
  const count = value;

  return (
    <Box>
      <Text>{name}</Text>
      <InputGroup w='98px' size='sm'>
        <InputLeftElement>
          <IconButton
            size='sm'
            onClick={() => {
              if (count > 0) {
                onChange(count - 1);
              }
            }}
            aria-label='decrease'
            icon={<Icon as={MinusIcon} />}
          />
        </InputLeftElement>
        <Input
          type='number'
          value={count}
          textAlign='center'
          onChange={(e) => {
            const newCount = Number(e.target.value);
            if (newCount > 0) {
              onChange(newCount);
            } else {
              onChange(0);
            }
          }}
        />
        <InputRightElement>
          <IconButton
            size='sm'
            onClick={() => {
              onChange(count + 1);
            }}
            aria-label='increase'
            icon={<Icon as={PlusIcon} />}
          />
        </InputRightElement>
      </InputGroup>
    </Box>
  );
}
