// If text is highlighted, replace the highlighted text with tag
// Else, add tag to the end of the note, prefix with comma
export const injectSuggestionTagForInput = (
  inputRef:
    | React.RefObject<HTMLInputElement>
    | React.RefObject<HTMLTextAreaElement>
    | null
    | undefined,
  note: string | undefined,
  tagName: string,
) => {
  const input = inputRef?.current;
  if (!input) return '';

  const start = input.selectionStart;
  const end = input.selectionEnd;

  let result = note ?? '';

  // If text is selected (highlighted)
  if (start !== end) {
    const currentValue = input.value;
    result = currentValue.substring(0, start!) + tagName + currentValue.substring(end!);
  } else {
    result = note ? `${note}, ${tagName}` : tagName;
  }

  input.focus();
  return result;
};
