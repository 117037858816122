import {
  Text,
  Tabs,
  TabPanels,
  TabPanel,
  Tab,
  TabList,
  HStack,
  Button,
  GridItem,
  SimpleGrid,
} from '../../../components';
import { MAWorkFlowContentContainer } from '../MAWorkFlowContentContainer';
import { useActiveEncounter, useModal } from '../../../hooks';
import { TreatmentPlan } from './TreatmentPlan';
import { useGetEncounterOrders } from '../../../hooks/useGetEncounterOrders';
import { CDSSRecommendations } from './CDSSRecommendations';
import { useState } from 'react';
import { CircleNumber } from './CircleNumber';
import { FullStory as FS } from '@fullstory/browser';
import { ImmunizationHistoryButton } from './ImmunizationHistoryButton/ImmunizationHistoryButton';

export function CDSSContent() {
  const { showModal } = useModal();
  const [activeIndex, setActiveIndex] = useState(0);
  const { activeEncounterId } = useActiveEncounter();

  const { numberOfOrders } = useGetEncounterOrders();

  const saveHandler = async () => {
    FS('trackEvent', {
      name: 'Add Order Clicked',
      properties: { encounter_id: activeEncounterId },
    });
    showModal({
      modalType: 'AddAdHocOrderModal',
      chakraModalProps: { size: '6xl' },
      modalProps: {},
    });
  };

  return (
    <MAWorkFlowContentContainer>
      <Tabs
        colorScheme='brand'
        mt='md'
        index={activeIndex}
        onChange={(index) => {
          setActiveIndex(index);
        }}>
        <SimpleGrid columns={3} alignItems='center' w='full'>
          <GridItem colSpan={1}>
            <Text variant='h6-b'>Treatment Plan + CDSS</Text>
          </GridItem>
          <GridItem colSpan={1}>
            <TabList maxW={450} mx='auto'>
              <Tab>CDSS Recommendations</Tab>
              <Tab>
                <HStack>
                  <Text>Treatment Plan</Text>
                  {numberOfOrders && <CircleNumber value={numberOfOrders} />}
                </HStack>
              </Tab>
            </TabList>
          </GridItem>
          <GridItem display={'flex'} justifyContent={'end'} colSpan={1}>
            <HStack>
              <ImmunizationHistoryButton />
              <Button ml='auto' onClick={saveHandler}>
                Add Order
              </Button>
            </HStack>
          </GridItem>
        </SimpleGrid>

        <TabPanels>
          <TabPanel>
            <CDSSRecommendations
              onAddToTreatmentPlanSuccess={() => {
                setActiveIndex(1);
              }}
            />
          </TabPanel>
          <TabPanel>
            <TreatmentPlan />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </MAWorkFlowContentContainer>
  );
}
