import { Box, Button, Checkbox, Flex, IconButton, Text, VStack, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { LabResults } from '../../../components/ComboBoxes';
import { ProviderAndStaffComboBox } from '../../../../../features';
import { providerEncounterActions, useAppSelector } from '../../../../../state-management';
import { useDispatch } from 'react-redux';
import {
  useGetPatientBasicDetailsQuery,
  type ProviderUpdateOrderableItemReviewInput,
} from '../../../../../__generated__/graphql';
import { useSearchParams } from 'react-router-dom';
import { Notes } from '../../../../../components';

export function Sidebar() {
  const [open, setOpen] = useState(true);
  const sidebarState = useAppSelector((state) => state.note.sidebarState).currentValues;
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const patientId = searchParams.get('patientId');
  const patient = useGetPatientBasicDetailsQuery({
    variables: {
      patientID: Number(patientId),
    },
    skip: !patientId,
  });
  const patientName = `${patient?.data?.getPatientBasicDetails?.firstName} ${patient?.data?.getPatientBasicDetails?.lastName}`;

  const dispatch = useDispatch();
  const handleChange = (val: Partial<ProviderUpdateOrderableItemReviewInput>) => {
    dispatch(providerEncounterActions.updateSidebar({ ...sidebarState, ...val }));
  };

  const submit = () => {
    dispatch(providerEncounterActions.applySidebarChanges());
    toast({
      title: 'Changes Applied',
      status: 'success',
      duration: 1000,
      isClosable: true,
    });
  };

  return (
    <Box
      width={open ? 400 : 0}
      className='sidebar-animation-slider'
      position='relative'
      height='100%'
      borderColor='gray.200'
      borderWidth={0}
      borderRightWidth={1}
      paddingBottom={75}>
      <IconButton
        top='40%'
        position='absolute'
        right={-3}
        aria-label='toggle-sidebar-button'
        rounded='full'
        boxShadow='lg'
        size='xs'
        zIndex={11}
        style={{ transform: open ? 'rotate(180deg)' : '' }}
        icon={<ChevronLeftIcon />}
        onClick={() => {
          setOpen(!open);
        }}
      />
      <Box
        className='sidebar-animation-slider'
        style={{ height: 'calc(100%)' }}
        width='100%'
        overflowY='auto'
        overflowX='hidden'
        position='relative'>
        <VStack
          position='relative'
          height='100%'
          minHeight={600}
          paddingTop={8}
          paddingRight={5}
          width={400}>
          <ProviderAndStaffComboBox
            selectionCallback={(assignee) => {
              handleChange({
                assignedToUserID: assignee ? parseInt(assignee?.value) : undefined,
              });
            }}
            placeholder='Assigned To'
          />
          <LabResults
            selectionCallback={(result) => {
              handleChange({
                result: result ?? undefined,
              });
            }}
          />
          <Notes
            popupTitle={`Note - ${patientName}`}
            keynameCategory='LabNotes'
            popupDisclaimer='Visible to Patient'
            placeholder='Note'
            disclaimer='This is visible on the Patient Portal!'
            disclaimerStyling={{
              color: 'brand.500',
            }}
            value={sidebarState.notes ?? ''}
            onChange={(notes) => {
              handleChange({
                notes,
              });
            }}
            suggestionCallback={(notes) => {
              handleChange({
                notes,
              });
            }}
          />
          <Notes
            popupTitle={`Internal Note - ${patientName}`}
            popupDisclaimer=''
            keynameCategory='LabNotes'
            placeholder='Internal Note'
            disclaimer='Not visible to patient'
            value={sidebarState.internalNotes ?? ''}
            onChange={(internalNotes) => {
              handleChange({
                internalNotes,
              });
            }}
            suggestionCallback={(internalNotes) => {
              handleChange({
                internalNotes,
              });
            }}
            prependWithSignature
          />
          <Flex width='100%' justifyContent='flex-end' paddingY='4px'>
            <Checkbox
              colorScheme='brand'
              onChange={(e) => {
                const highPriority = e.target.checked;
                handleChange({
                  highPriority,
                });
              }}>
              <Text fontSize='xs'>High Priority</Text>
            </Checkbox>
          </Flex>
          <Flex width='100%' border='1px solid #7E90CF' borderRadius='8px'>
            <Flex
              width='50%'
              justifyContent='center'
              alignItems='center'
              padding='8px 0'
              borderRadius='6.5px 0 0 6.5px'
              backgroundColor={!sidebarState.reviewed ? '#7E90CF' : 'white'}
              borderRight='1px solid #7E90CF'
              onClick={() => {
                handleChange({
                  reviewed: false,
                });
              }}
              transition='all 0.2s cubic-bezier(.08,.52,.52,1)'>
              <Text fontSize='md' textColor={sidebarState.reviewed ? '#7E90CF' : 'white'}>
                Open
              </Text>
            </Flex>

            <Flex
              width='50%'
              justifyContent='center'
              alignItems='center'
              padding='8px 0'
              borderRadius='0px 6.5px 6.5px 0'
              backgroundColor={sidebarState.reviewed ? '#7E90CF' : 'white'}
              onClick={() => {
                handleChange({
                  reviewed: true,
                });
              }}
              transition='all 0.2s cubic-bezier(.08,.52,.52,1)'>
              <Text fontSize='md' textColor={!sidebarState.reviewed ? '#7E90CF' : 'white'}>
                Reviewed
              </Text>
            </Flex>
          </Flex>
          {open && (
            <Button
              position='fixed'
              bottom={7}
              left={5}
              width={380}
              zIndex={10}
              variant='solid'
              isDisabled={!sidebarState.notes?.length}
              onClick={() => {
                submit();
              }}>
              Apply All
            </Button>
          )}
        </VStack>
      </Box>
    </Box>
  );
}
