import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  Icon,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  WaitUntilLoaded,
} from '../../../components';
import { AiOutlinePlus as AddIcon } from 'react-icons/ai';
import { uiActions, useAppDispatch } from '../../../state-management';
import { useDebounce, useMAWorkflow } from '../../../hooks';
import { useGetRosCategoryLookupsQuery } from '../../../__generated__/graphql';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { ROSExistingEncounterSymptomsPanel } from './ROSExistingEncounterSymptomsPanel';

const TAB_NAMES = ['All', 'Selected'] as const;
type TabName = (typeof TAB_NAMES)[number];

export function ROSCategories() {
  const [activeTab, setActiveTab] = useState<TabName>('All');
  const dispatch = useAppDispatch();
  const {
    ros: { showingCategoryIds, rosData },
  } = useMAWorkflow();
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText);

  const { data, loading: isLoading } = useGetRosCategoryLookupsQuery({
    variables: {
      contains: debouncedSearchText || '',
    },
  });

  const selectedCount = showingCategoryIds?.length || 0;
  // count excludes unedited items
  // const selectedCount = rosData?.encounterROSDataItems?.length || 0;

  useEffect(() => {
    if (data && rosData) {
      rosData.encounterROSDataItems.forEach((encounterRosData) => {
        const category = data.getROSCategoryLookups?.find(
          (lookup) => lookup.rosCategoryID === encounterRosData.rosCategoryID,
        );
        dispatch(
          uiActions.addRosDataItem({
            rosCategoryId: category?.rosCategoryID ?? -1,
            rosCategoryName: category?.name ?? '',
          }),
        );
      });
    }
  }, [rosData, data]);

  const allTab = () => {
    return (
      <VStack overflow='auto' h='full'>
        <Flex w='full'>
          <Input
            size='lg'
            borderColor='secondary'
            borderWidth='2px'
            autoFocus
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            mb='sm'
            placeholder='Search'
            _focus={{
              boxShadow: 'primary',
              borderColor: 'brand.500',
            }}
          />
        </Flex>

        <VStack w='full' align='flex-start' overflow='auto' justify='flex-start' h='full'>
          <WaitUntilLoaded loading={isLoading}>
            {data?.getROSCategoryLookups?.map((d) => {
              const isDisabled = showingCategoryIds?.some(
                (c) => c.rosCategoryId === d?.rosCategoryID,
              );
              return (
                <Box pl='sm' key={d?.rosCategoryID} w='full'>
                  <Box
                    opacity={isDisabled ? 0.5 : 1}
                    onClick={() => {
                      if (d?.rosCategoryID && !isDisabled) {
                        setActiveTab('Selected');
                        dispatch(uiActions.setROSSelectedCategoryId(d?.rosCategoryID));
                        dispatch(
                          uiActions.addRosDataItem({
                            rosCategoryId: d?.rosCategoryID,
                            rosCategoryName: d?.name ?? '',
                          }),
                        );
                        setSearchText('');
                      }
                    }}
                    textAlign='left'>
                    <Box
                      role='group'
                      _hover={{ color: !isDisabled ? 'primary' : '' }}
                      cursor={isDisabled ? 'default' : 'pointer'}
                      mb={3}>
                      <VStack spacing={0}>
                        <HStack spacing='sm' justifyContent='flex-start' w='full'>
                          <Text as='b' whiteSpace='nowrap' w='full' textAlign='left'>
                            {d?.name}
                          </Text>
                          <Icon boxSize={6} as={AddIcon} color='brand.500' />
                        </HStack>
                        {!!d?.rosSymptoms?.length && searchText && (
                          <Box
                            w='full'
                            color='secondary'
                            _groupHover={{ color: !isDisabled ? 'primary' : '' }}>
                            <Text fontSize='sm' as='sub' pr={1} w='full' textAlign='left'>
                              Symptoms <ChevronRightIcon />
                            </Text>
                            <Text fontSize='sm' as='sub' w='full' textAlign='left'>
                              {d?.rosSymptoms?.map((symptom) => symptom?.symptomName).join(', ')}
                            </Text>
                          </Box>
                        )}
                      </VStack>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </WaitUntilLoaded>
        </VStack>
      </VStack>
    );
  };

  return (
    <VStack spacing='md' w='full' h='full' overflow='auto' pr='md'>
      <Tabs
        index={TAB_NAMES.indexOf(activeTab)}
        onChange={(index) => {
          setActiveTab(TAB_NAMES[index]);
        }}
        colorScheme='brand'
        display='flex'
        flexDirection='column'
        overflow='auto'
        isFitted
        width='100%'>
        <TabList>
          <Tab>All</Tab>
          <Tab>Selected ({selectedCount})</Tab>
        </TabList>

        <TabPanels overflow='auto'>
          <TabPanel px={0} overflow='auto' h='full'>
            {allTab()}
          </TabPanel>
          <TabPanel px={0} overflow='auto' h='full'>
            <ROSExistingEncounterSymptomsPanel
              categoryEncounterData={rosData}
              clickHandler={(categoryId: number, symptomId?: number) => {
                dispatch(uiActions.setROSSelectedCategoryId(categoryId));
                dispatch(uiActions.setROSSelectedSymptomId(symptomId));
              }}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
}
