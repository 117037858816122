import { type EntityState } from '@reduxjs/toolkit';
import { type ButtonProps, type InputProps } from '@chakra-ui/react'; // TODO: Get from components
import { type g } from './api';
import {
  type Allergy,
  type AllergyType,
  type CreateMedicalHistoryItem,
  type FamilyHistory,
  type FamilyMembers,
  type Gender,
  type GetEncounterHpiQuery,
  type GetIcdCodesQuery,
  type GetPatientQuery,
  type GetSocialHistoryCategoriesQuery,
  type GetSocialHistoryQuery,
  type Hospitalization,
  type HpiEncounterDataItem,
  type HpiSymptomEntity,
  type HpiSymptomOption,
  type MedicationItem,
  type OrderDeclineReasonCode,
  type PatientImmunizationInjectionType,
  type Problem,
  type Surgery,
  type Location,
  type HpiCategoryDataInput,
  type GetOrderableItemsLookupQuery,
  type UpdateOrderableItemInput,
  type SubmitImmunizationInjectionInput,
  type AddOrderableItemMutationVariables,
  type AddOrderableItemResultInput,
  type AddOrderableItemResult,
  type EncounterTemplateMetaData,
  type AoeAnswerInput,
  type LabSubmissionInput,
  type GetRosCategoryByIdQuery,
  type GetEncounterRosDataQuery,
  type GetImmunizationInjectionsQuery,
  type EncounterOrderableItem,
  type OrderMetricRecommendationType,
} from './__generated__/graphql';
import { type ConstellationRecommendation } from './services/constellation-recommendations/constellation-recommendations.types';
import { type ReactNode } from 'react';

declare global {
  namespace ReactNavigation {}

  export type SidebarPosition = 'right' | 'left';
  export interface SidebarState {
    sidebarContent?: ReactNode;
    sidebarHeader?: ReactNode;
    open?: boolean;
    sidebarPosition?: SidebarPosition;
  }

  export type VaccineForm = Partial<SubmitImmunizationInjectionInput>;
  export interface VaccineFormUpdate extends VaccineForm {
    immunizationItemId: SubmitImmunizationInjectionInput['immunizationItemId'];
  }

  export interface LabGlobalForm extends Partial<LabSubmissionInput> {
    // UI only
    sendTolabCompanyName?: string;
    labReviewedByLastName?: string;
    labReviewedByFirstName?: string;
    labReviewedByUserId?: UpdateOrderableItemInput['reviewedByUserId']; // TODO: Looks like we'll need for updateOrderableItem for labInstructions
  }

  export type LabItemId = LabItem['orderableReportID'];
  export interface LabItem extends Partial<UpdateOrderableItemInput> {
    orderableReportID: number;
    orderableItemName?: string | null;
    orderableItemId?: number;
    aoeAnswers?: Array<Partial<AoeAnswerInput>>;
    labSubmissionInputs?: Partial<LabSubmissionInput>;
    isSelected?: boolean;
  }

  export interface UILabItem {
    orderableReportID: number;
    hasDetailedReport: boolean;
    hasDocument: boolean;
    name?: string;
    date?: string;
    status?: string;
  }

  export interface ConstellationWorklistItem {
    id: AddOrderableItemResult['orderableReportID'];
    text: AddOrderableItemMutationVariables['orderableItemName'];
    completed: boolean;
    orderableItem: Partial<EncounterOrderableItem> & {
      labItemId: NonNullable<AddOrderableItemMutationVariables['labItemId']>;
    };
    orderableItemResultFormFields: Record<
      NonNullable<AddOrderableItemResultInput['attributeID']>,
      AddOrderableItemResultInput
    >;
    resultDate: string;
    result: string;
    received: boolean;
    collectedDate?: string;
    futureOrder?: boolean;
    notes?: string;
    internalNotes?: string;
  }

  /**
   * Server should make enum
   0 - fasting
   1 - not fasting
   2 - not recorded
   */
  export type ActualFasting = 0 | 1 | 2;

  export type Role = 'provider' | 'devs' | 'rad-techs' | 'legacy-ma-user' | 'ma-workflow';
  interface UserUI {
    authToken?: string;
    email?: string;
    username?: string;
    isAuthenticated?: boolean;
    userId?: string;
    firstName?: string;
    lastName?: string;
    ecwId?: string;
    cognito?: {
      groups: string[];
      roles: Role[];
    };
  }

  export type OrderFlowSteps =
    | 'vitals'
    | 'chief-complaint'
    | 'medical-history'
    | 'ros'
    | 'hpi'
    | 'cdss'
    | 'medications'
    | 'surgeries'
    | 'family-history'
    | 'social-history'
    | 'obgyn'
    | 'allergies';

  export interface Step {
    id: OrderFlowSteps;
    completed: boolean;
    hasLoadedInitialData?: boolean;
    isDirty?: boolean;
  }

  // MODALS

  export interface ConfirmationModalProps {
    title: string;
    message: ReactNode;
    labels?: { confirm: string; cancel: string };
    onCancel?: () => void;
    onConfirm?: () => void;
    cancelProps?: ButtonProps;
    confirmProps?: ButtonProps;
  }

  export type ModalTypes =
    | 'ConfirmationModal'
    | 'AOEQuestionsAndLabFormsModal'
    | 'AdministerVaccineFormModal'
    | 'PendingVaccinesModal'
    | 'WorklistModal'
    | 'EditMedicationsModal'
    | 'DeclineReasonModal'
    | 'LocationSelectorModal'
    | 'SurgeryModal'
    | 'HospitalizationModal'
    | 'FamilyHistoryModal'
    | 'AddAllergyPromptModal'
    | 'SocialHistoryEditModal'
    | 'HPIFollowUpModal'
    | 'HPISmartFormModal'
    | 'EncounterHPIDataItemModal'
    | 'EditROSModal'
    | 'EditGynSymptomNoteModal'
    | 'EditOBSymptomNoteModal'
    | 'GYNStructedDataFormModal'
    | 'ProviderSelectorModal'
    | 'ConstellationRecommendationModal'
    | 'LabCollectionFormModal'
    | 'ChecklistConstellationModal'
    | 'AddAdHocOrderModal'
    | 'GrowthChartModal'
    | 'ClinicalStatusSelectModal';

  // OBGYN
  export interface UpsertGYNHistoryItem extends g.GynHistory {
    isNew?: boolean;
  }

  export interface SpecificModalProps {
    onCloseModal(): void;
    socialInfoId?: number;
  }

  // OTHER
  type TempId = string;

  export interface DropdownOption<T = Record<string, unknown>> {
    value: string;
    label: string;
    entity?: T;
  }

  export interface EncounterRosDataUI {
    encounterROSDataItems: EncounterRosDataItemInputUI[];
    notes?: string;
  }

  interface RosTab {
    rosCategoryName: string;
    rosCategoryId: number;
  }

  export type EncounterRosDataItemInputUI = NonNullable<
    NonNullable<
      NonNullable<GetEncounterRosDataQuery>['getEncounterROSData']
    >['encounterROSDataItems']
  >[0];

  export type ROSCategorySymptom = NonNullable<
    NonNullable<NonNullable<GetRosCategoryByIdQuery>['getROSCategoryById']>['rosSymptoms']
  >[0];

  // SURGERIES & HOSPITALIZATION

  export interface SurgeryUI extends Partial<Surgery> {
    tempId: TempId;
  }

  export interface HospitalizationUI extends Partial<Hospitalization> {
    tempId: TempId;
  }

  // UI INPUT
  export interface AsyncSelectProps extends InputProps {
    inputProps?: InputProps;
    dropdownItems?: DropdownOption[];
    loading?: boolean;
    isDisabled?: boolean;
    onAddCustomInputValueToList?(config: { inputValue: string }): void;
    handleInputChange(inputValue: string | undefined): void;
    handleOptionSelect?(option: DropdownOption | undefined | null): void;
    initialInputValue?: string | null;
    variant?: 'combobox' | 'search-select';
    openOnFocus?: boolean;
    hideAddItem?: boolean;
    inputRightElement?: JSX.Element;
  }

  // Medications
  type MedStatus = 'Taking' | 'Not-Taking/PRN' | 'Unknown' | 'Discontinued';

  export interface SelectStatusOptions {
    T: 'Taking';
    N: 'Not-Taking/PRN';
    U: 'Unknown';
    D: 'Discontinued';
  }

  export interface MedicationItemUI extends Partial<MedicationItem> {
    newStatus?: string | null;
    id: string;
  }

  // START - Family History
  export interface FamilyHistoryUI extends Partial<Omit<FamilyHistory, 'familyMembers'>> {
    familyMembersHistory?: EntityState<FamilyMemberUI>;
  }
  export interface FamilyMemberUI extends Omit<FamilyMembers, 'familyMemberDiagnosisList'> {
    tempId: FamilyMemberTempId;
    familyMemberDiagnosisList: FamilyMemberDiagnosisListItemUI[];
  }

  export type FamilyMemberTempId = string;

  type ICDItemId = string;
  interface FamilyMemberDiagnosisListItemUI {
    isChecked: boolean;
    icdItemId: ICDItemId;
  }

  // Social History

  export type SocialHistory = NonNullable<GetSocialHistoryQuery['getSocialHistory']>[0];

  export type SocialHistoryCategoryUI = NonNullable<
    GetSocialHistoryCategoriesQuery['getSocialHistoryCategories']
  >[0];

  export type SocialInfoItem = NonNullable<SocialHistoryCategoryUI['socialInfoItems']>[0];

  export type StructuredDataItem = NonNullable<SocialInfoItem['structuredDataItems']>[0];

  export interface UpdateMedicalHistoryItemInput {
    patientId: number;
    encounterId: number;
    medicalHistory: CreateMedicalHistoryItem[];
  }

  // Note: I'm not sure why this was created as a number or string.
  // From the backend, the id is always a string.
  export type MedicalHistoryItemId = number | string;

  /**
   * This represents the MedicalHistoryItem but with additional fields
   * for the UI specific fields.
   */
  export interface MedicalHistoryViewItem {
    id: MedicalHistoryItemId;
    name: string;
    icdCode?: string | null;
    addToPatientProblemList?: boolean;
    isCustomEntry?: boolean;
    isByName?: boolean;
    icdItemID?: number;
  }
}

export enum MedStatusValues {
  T = 'Taking',
  N = 'Not-Taking/PRN',
  U = 'Unknown',
  D = 'Discontinued',
}

/**
 * Not setting it as the type on FamilyMember.familyMemberType to avoid breaking backwards compatability
 */
export enum FamilyMemberType {
  Father = 'Father',
  Mother = 'Mother',
  Children = 'Children',
  Siblings = 'Siblings',
}

// BUSINESS TYPES
export type ClientDeclineReasonCode = 'ALREADY_ORDERED' | 'PATIENT_DECLINE';
export interface DeclineReasonConfig {
  id?: string; // TODO: When we send decline reasons back, do we need ID? Or "code" sufficient?
  uiDeclineReasonCode: ClientDeclineReasonCode;
  serverDeclineReasonCode: OrderDeclineReasonCode;
  name: string; // "Already Ordered"
}

export interface DeclineReason {
  additionalDetails?: string;
  result?: string;
  serverDeclineReasonCode: OrderDeclineReasonCode;
  uiDeclineReasonCode: ClientDeclineReasonCode;
  location?: string;
  dateTimeOrdered?: string;
  name?: string;
}

export interface AdministrationRoute {
  id: number;
  routeCode: string;
  routeDescription: string;
}
export interface InjectionSite {
  code: string;
  location: string;
}

type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> & Partial<Pick<Type, Key>>;
export interface PatientAlert
  extends MakeOptional<
    GetPatientRecommendation,
    | 'orderableItemOptions'
    | 'isOrderable'
    | 'required'
    | 'orderableItemName'
    | 'description'
    | 'displayOrder'
  > {
  orderMetricRecommendationType?: OrderMetricRecommendationType;
  alertId: number;
  shortDescription: string;
  longDescription: string;
  fasting?: boolean;
  lastOrdered?: string; // ISO
  // TODO: Type "new" vs "ordered" alerts? This key is only valid for ordered alert. New alerts don't have orders
  // order?: Order;
}

// AUTH / COGNITO
export type AuthState =
  | 'loading'
  | 'signIn'
  | 'signUp'
  | 'signedUp'
  | 'confirmSignIn'
  | 'confirmSignUp'
  | 'forgotPassword'
  | 'requireNewPassword' // TODO: What state?
  | 'verifyContact'
  | 'signedIn';

// ENCOUNTER
export interface EncounterCardInfo {
  encounterID: number;
  fullNameAndID: string;
  genderAgeBirthday: string;
  time: string;
  date: string;
  visitReason: string;
  visitStatus: string;
  clinicalStatusAfterCheckIn: string;
  insurance: string;
  resourceName: string;
  roomNumber: string;
  isNotStarted: boolean;
  isStartedAndNotComplete: boolean;
  isComplete: boolean;
}

type OrderType = 'ACCEPTED' | 'DECLINED' | 'READONLY';
export interface OrderUI {
  orderId?: string; // Before submission to server, no orderId
  // medicalProcedure: string; // A1C, Mammogram, Diabetic Eye Exam e.t.c.
  diagnosis?: DiagnosisUI;
  declineReason?: DeclineReason;
  alert: PatientAlert;
  orderType: OrderType;
}

export interface SelectedOrderableItem {
  orderableItemId: number;
  orderableItemName: string;
}

export interface Patient {
  patientID: number;
  firstName: string;
  lastName: string;
  insurance: string;
  gender: Gender;
  primaryRace: string;
  dateOfBirth: string;
  allergies: Allergy[];
  problems: Problem[];
  orderableItemHistory: OrderableItem[];
  orderableImmunizationInjectionHistory: OrderableImmunizationInjection[];
  encounters: Appointment[];
  recommendations: GetPatientRecommendation[];
  // vitals: PatientVitals[];
}

interface OrderableImmunizationInjection {
  encounterID: number;
  immunizationID: number;
  immunizationItemID: number;
  immunizationName: string;
  dateTimeAdministered: string;
  patientImmunizationInjectionType: PatientImmunizationInjectionType;
}

export interface SubmissionRate {
  userRate: number;
  teamRate: number;
}

export type AlertId = NonNullable<GetPatientRecommendation['orderableItemId']>;
export interface OrderableItemOption {
  orderableItemId: number;
  orderableItemName: string;
}

export type GetPatientPatient = NonNullable<GetPatientQuery['getPatient']>;
export type GetPatientRecommendation = NonNullable<GetPatientPatient['recommendations']>[0];

interface OrderableItem {
  orderDate: string;
  orderableItemName: string;
  primaryDiagnosis: string;
  encounterID: number;
}

export interface OrderableItemUI {
  orderDate?: Date;
  orderableItemName?: string;
  labItemId?: number;
  reason?: string;
  assigneeId?: number;
  facilityID?: number;
  orderedFasting?: boolean;
  highPriority?: boolean;
  futureOrder?: boolean;
  inHouse?: boolean;
  labType?: {
    type: string;
    typeId: number;
  };
}

export interface Appointment {
  encounterID: number;
  providerID: number;
  startTime: string; // ISO string
  endTime: string;
  clinicalTimeIn: string; // ISO string
  clinicalTimeOut: string;
  clinicalStatusAfterCheckIn: string;
  progressNoteLockedDateTime: string;
  visitStatus: string;
  visitReason: string;
  roomNumber: string;
  patient: Patient;
  orders?: OrderUI[]; // If past appointment
  vitals?: VitalUI[];
  resource?: any; // TODO: Get proper type
  attachedReportIds?: number[] | null;
}

export interface VitalUI {
  id: number;
  vitalItemName: string;
  vitalItemValue: string;
}

export interface DiagnosisUI {
  diagnosisName?: string;
  icdCode?: string;
  assessmentItemId?: number;
  isSelected?: boolean;
}

export type ICDCode = NonNullable<NonNullable<GetIcdCodesQuery['getICDCodes']>['codes']>[0];

// TODO: This needs to be removed. The BE is currently incorrect. There's a definition for ChiefComplaintsInput
// but updateEncounterChiefComplaints is not using it. We should use the type defined in the BE instead of
// defining our own
export interface ChiefComplaintsInput {
  complaints: string[];
  encounterId: number;
  patientId: number;
}

export interface AllergyReaction {
  id: number;
  reaction: string;
}

// TODO: Placeholder.
export interface AllergyCriticality {
  id: number;
  name: Criticality;
}

export type Criticality = 'Unknown' | 'High' | 'Low';

export interface AllergyStatus {
  id: number; // TODO: Placeholder.
  name: 'Active' | 'Inactive';
}

export interface AllergySource {
  id: number;
  agentSubstance: string;
}

export interface AllergyUI {
  id: number;
  allergySourceId: number;
  source: AllergySource;
  reaction?: AllergyReaction;
  type?: AllergyType;
  criticality?: AllergyCriticality;
  onSetDate?: string;
  status?: AllergyStatus;
}

export interface AllergyListUI {
  nkda: boolean;
  allergyItems: AllergyUI[];
}

// UI
export type PatientSearchScreenTabs = 'To-Do' | 'Done';
export type MAMetricsTabs = 'Today' | 'This Week' | 'This Month';

export type LocationUI = Pick<Location, 'locationID' | 'locationName'>;

export type ChiefComplaint = string;
export interface MedicalHistory {
  historyText: string;
}

export type VitalQualifier = 'sitting' | 'standing' | 'supine' | 'repeat' | 'left' | 'right' | '';

export interface QualifierConfig {
  isDefault: boolean;
  qualifier: VitalQualifier;
  value: string;
}

export type HPISymptom = Pick<HpiSymptomEntity, 'hpiSymptomID' | 'name'> & {
  symptomOptions?: HpiSymptomOption[] | null;
};

export type EncounterHPI = GetEncounterHpiQuery['getEncounterHPI'];
export type HPICategoryDataItem = NonNullable<NonNullable<EncounterHPI>['hpiCategoryData']>[0];
export type EncounterHPIDataItem = HpiEncounterDataItem;
export interface UpdateEncounterHPIInput {
  encounterId: number;
  patientId: number;
  hpiCategoryData: HpiCategoryDataInput[];
}

export interface ComboBoxOption {
  value: string;
  label: string;
}

export type EncounterTabOption = 'To Be Reviewed' | 'Outstanding' | 'Reviewed' | 'Open TEs';

export interface EncounterSort {
  orderByColumn: string;
  orderDirection: 'ASC' | 'DESC';
}

export interface EncounterTableHeadCell {
  label: string;
  value: string;
  isAsc?: boolean;
}

export type ConstellationRecommendationUI = ConstellationRecommendation & {
  shouldDecline: boolean;
  declineReason?: string;
};

export type Mutable<Type> = {
  -readonly [Key in keyof Type]: Type[Key];
};

export interface OptionItem {
  id: number;
  value: string;
}

export interface GrowthChartTabMetadata {
  label: string[];
  dataType: string[];
  xAxis: string[];
  xAxisUnit: string[];
  yAxis: string[];
  yAxisUnit: string[];
  min: number[];
  max: number[];
  annotationXOffset: number[];
  annotationPadding: number[];
}

/**
 * This is the generic master orderable item type that is not
 * associated to a user. These orderable items comes from a
 * master list of orderable items.
 */
export type OrderableItemLookupUI = NonNullable<
  NonNullable<GetOrderableItemsLookupQuery>['orderableItemsLookup']
>[0];

export type ImmunizationInjectionLookupUI = NonNullable<
  NonNullable<GetImmunizationInjectionsQuery>['getImmunizationInjections']
>[0];

export type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;

export type EncounterTemplateMetaDataUI = EncounterTemplateMetaData & { isFavorite: boolean };

export type EncounterCategoryDataItem = NonNullable<
  NonNullable<HPICategoryDataItem>['encounterHPIDataItems']
>[0];
