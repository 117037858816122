/* eslint-disable */
import { createApi } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';
import { ClientError, request } from 'graphql-request';
import { IS_PROD, DEV_API_URL, USE_LOCAL_MOCK } from '../constants';
import { SAMPLE_API_RESPONSE_DATA } from './fixtures';
import { sendTextToEnsomataApiErrorsChannelInSlack } from './sendTextToEnsomataApiErrorsChannelInSlack';

const BASE_URL = IS_PROD ? 'https://api.prod.ensomata.io/graphql' : DEV_API_URL;

const graphqlBaseQuery =
  ({ baseUrl }: { baseUrl: string }) =>
  async ({ body, variables }: { body: string; variables?: Record<string, any> }) => {
    if (USE_LOCAL_MOCK) {
      // DEBUGGING ONLY
      return SAMPLE_API_RESPONSE_DATA;
    }

    const authData = await Auth.currentAuthenticatedUser();
    const token = authData?.signInUserSession?.accessToken?.jwtToken;

    try {
      const result = await request(baseUrl, body, variables, {
        authorization: token,
      });
      return { data: result };
    } catch (error) {
      let errorMessage: string | undefined;
      if (error instanceof ClientError) {
        errorMessage = error?.message;
        sendTextToEnsomataApiErrorsChannelInSlack(errorMessage);
        return {
          error: { message: errorMessage },
        };
      }

      errorMessage = error?.toString();
      if (errorMessage) {
        sendTextToEnsomataApiErrorsChannelInSlack(errorMessage);
      }

      return { error: { status: 500, data: error } };
    }
  };

export const ensomataApi = createApi({
  reducerPath: 'ensomataApi',
  baseQuery: graphqlBaseQuery({
    baseUrl: BASE_URL,
  }),
  // NOTE: ********* This deprecated ********** DO NOT ADD ANYTHING TO endpoints use graphql/src for new definitions!
  endpoints: (builder) => ({}),
});
