import { useCombobox } from 'downshift';
import { Button, InputGroup, type InputProps, InputRightElement } from '@chakra-ui/react'; // TODO: Get from components
import { Input, Box, List, ListItem, Spinner, Text, VStack } from '../../../components';
import { isEmpty } from 'lodash';
import { useMedicalHistory } from './useMedicalHistory';

interface AsyncSelectProps extends InputProps {
  dropdownItems?: MedicalHistoryViewItem[];
  handleOptionSelect: (option: MedicalHistoryViewItem | undefined | null) => void;
  autoFocus?: boolean;
}

export function AsyncNameSearchField({
  dropdownItems: options,
  handleOptionSelect,
  autoFocus,
}: AsyncSelectProps) {
  const {
    handleNameSearchChange,
    isLoadingMedicalHistoryOptionsByName: loading,
    debouncedMedHistoryNameSearchText: searchText,
  } = useMedicalHistory();

  // Combobox hook for Name input
  const {
    isOpen,
    reset,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    inputValue,
    closeMenu,
  } = useCombobox({
    items: options != null ? options : [],
    itemToString: (item) => (item?.name ? item.name : ''),
    onSelectedItemChange: ({ selectedItem }) => {
      handleOptionSelect(selectedItem);
    },
    onInputValueChange: ({ inputValue, selectedItem }) => {
      // prevent wasteful API call when input val has already been added. No need to fetch it again
      if (selectedItem?.name !== inputValue) {
        handleNameSearchChange(inputValue || '');
      }
    },
  });

  const showDropDown = !!(isOpen && options != null && searchText) || loading;
  const showClearButton = !!inputValue;

  return (
    <Box w='full' pos='relative'>
      <Box>
        <InputGroup>
          <Input
            {...getInputProps()}
            autoFocus
            sx={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              placeholder: 'Enter name',
            }}
          />
          {/* TODO: Re-usable component */}
          {showClearButton && (
            <InputRightElement>
              <Box pr='lg'>
                <Button
                  size='sm'
                  onClick={() => {
                    reset();
                  }}
                  aria-label='clear'
                  variant='ghost'>
                  Clear
                </Button>
              </Box>
            </InputRightElement>
          )}
        </InputGroup>
      </Box>

      <DropDown
        {...{
          getMenuProps,
          showDropDown,
          loading,
          options,
          getItemProps,
          highlightedIndex,
          searchText,
          closeMenu,
          itemAddedToMedicalHistoryHandler: reset,
        }}
      />
    </Box>
  );
}

// TODO: Separate component to share with ICD/Desc.
function DropDown({
  getMenuProps,
  showDropDown,
  options,
  loading,
  getItemProps,
  highlightedIndex,
  searchText,
  closeMenu,
  itemAddedToMedicalHistoryHandler,
}: {
  showDropDown: boolean;
  loading: boolean;
  options: AsyncSelectProps['dropdownItems'];
  getMenuProps: () => any;
  getItemProps: (v: any) => any;
  highlightedIndex: number;
  searchText: string;
  closeMenu: () => void;
  itemAddedToMedicalHistoryHandler?: () => void;
}) {
  const { handleAddMedicalHistoryItem } = useMedicalHistory();

  return (
    <List
      {...getMenuProps()}
      sx={
        showDropDown
          ? {
              pos: 'absolute',
              zIndex: 'dropdown',
              bg: 'white',
              shadow: 'sm',
              rounded: 'md',
              p: 'md',
              mt: 'sm',
              w: 'full',
              maxH: '360px',
              overflow: 'auto',
              borderWidth: 1,
            }
          : {}
      }>
      {!showDropDown ? null : loading ? (
        <Spinner />
      ) : isEmpty(options) ? (
        <VStack>
          <Text textAlign='center' color='gray.900'>
            We couldn't find a match. Would you like to add '{searchText}' to the medical history?
          </Text>
          <Button
            w='full'
            onClick={() => {
              handleAddMedicalHistoryItem({
                icdCode: '',
                id: Number(Date.now().toString().slice(-9)),
                isCustomEntry: true,
                isByName: true,
                addToPatientProblemList: false, // By Name cannot have add to problem list option.
                name: searchText,
              });
              itemAddedToMedicalHistoryHandler?.();
              closeMenu();
            }}>
            Add '{searchText}' To Medical History
          </Button>
        </VStack>
      ) : (
        options?.map((option, index) => {
          return (
            <ListItem
              key={option.id}
              value={option.name}
              p='sm'
              rounded='md'
              _hover={{ cursor: 'pointer' }}
              {...getItemProps({ item: option, index })}
              sx={highlightedIndex === index ? { backgroundColor: 'gray.100' } : {}}>
              <Text>{option.name}</Text>
            </ListItem>
          );
        })
      )}
    </List>
  );
}
