import {
  List,
  ListItem,
  IconButton,
  ModalBody,
  Icon,
  Text,
  ModalCloseButton,
  Box,
  ModalFooter,
  Button,
  Checkbox,
  HStack,
} from '@chakra-ui/react';
import { BiChevronRight } from 'react-icons/bi';
import {
  usePendingVaccines,
  useModal,
  useEncounter,
  useMAWorkflow,
  useChecklistConstellationItems,
  useEditedOrders,
  useActiveEncounter,
} from '../../../hooks';
import { Flex, WaitUntilLoaded } from '../../layout';
import { useAppDispatch, useAppSelector, worklistTodoActions } from '../../../state-management';
import { LabStatus, OrderType, useGetEncounterOrdersQuery } from '../../../__generated__/graphql';
import { useEffect } from 'react';
import { isWorklistVaccineFormDirty } from '../../../utils';
import { FinishButtonModal } from './FinishButtonModal';
import { useWorklistModal } from './useWorklistModal';
import { isEmpty } from 'lodash';
import { FullStory as FS } from '@fullstory/browser';

function useInitializeWorklist() {
  const { activeEncounterID } = useEncounter();
  const { isMAWorkflowEnabled } = useMAWorkflow();
  const { getEditedOrder } = useEditedOrders();
  const dispatch = useAppDispatch();

  const constellationItemsState = useAppSelector((state) => state.worklistTodo.constellationItems);

  const { data, loading } = useGetEncounterOrdersQuery({
    variables: {
      encounterId: activeEncounterID,
    },
  });
  const encounterVaccines = data?.getEncounterOrders?.encounterImmunizationInjectionItems;
  const encounterOrderableItems = data?.getEncounterOrders?.encounterOrderableItems;

  // Initialize Pending Vaccine items
  useEffect(() => {
    if (isMAWorkflowEnabled && encounterVaccines?.length) {
      encounterVaccines.forEach((v) => {
        const isAccepted = v?.statusDescription !== 'Refused'; // For non-vaccine orderable item, the equivalent is "orderType": "ACCEPTED"
        const isDirty = isWorklistVaccineFormDirty(v);
        const completed = isAccepted && isDirty;

        if (isAccepted) {
          dispatch(
            worklistTodoActions.addPendingVaccine({
              id: v?.immunizationItemId ?? 0,
              text: v?.immunizationName ?? '',
              completed,
              immunization: v,
            }),
          );
        }
      });
    }
  }, [encounterVaccines, isMAWorkflowEnabled]);

  // Initialize Lab Collection and Constellation Symptoms Items
  useEffect(() => {
    if (isMAWorkflowEnabled && encounterOrderableItems?.length) {
      encounterOrderableItems.forEach((o) => {
        const isLabOrder = o?.orderableItemTypeId === 0; // LAB
        const isAccepted = o?.orderType === OrderType.Accepted; // For non-vaccine orderable item, the equivalent is "orderType": "ACCEPTED"
        const orderableReportID = o?.orderableReportID;
        const futureOrder = o?.futureOrder;
        const isEnterLabResultOrProcedureLab =
          isEnterLabResultsLabItem(o?.labItemId) || isProcedureLabItem(o?.labItemId);
        const editedOrder = getEditedOrder(o?.labItemId ?? 0);
        const isSubmittedLab = o?.labStatus === LabStatus.Submitted;
        /**
         * The orderableItemName is not always available in getEncounterOrders query.
         * This might occur whenever we just submit addOrderableItem (in confirm order page), and immediately perform getEncounterOrders (auto-opening Checklist modal)
         * Data in the backend, such as orderableItemName, might not have been updated yet and need to be populataed by eCW.
         * This is a workaround to ensure we have the orderableItemName available for display within the Checklist flows,
         * if the user immediately opens the Checklist modal after submitting an order.
         */
        const orderableItemName = o?.orderableItemName
          ? o.orderableItemName
          : editedOrder?.alert?.orderableItemName
          ? editedOrder.alert.orderableItemName
          : '';

        // Initialize Collect and Send lab items
        if (
          !isSubmittedLab &&
          isLabOrder &&
          !isEnterLabResultOrProcedureLab &&
          isAccepted &&
          !!orderableReportID &&
          !futureOrder
        ) {
          const isDirty = !!o?.collectionSource;

          if (isDirty) {
            dispatch(worklistTodoActions.markLabCollectionComplete());
          }

          dispatch(
            worklistTodoActions.updateLabItem({
              orderableReportID,
              orderableItemId: o.labItemId ?? -1,
              orderableItemName,
              collectionSource: o.collectionSource,
              collectionVolume: o.collectionVolume,
              collectionUnits: o.collectionUnits,
              clinicalInfo: o.clinicalInfo,
              internalNotes: o.internalNotes,
              actualFasting: o.actualFasting,
            }),
          );
        }

        // prevent override of existing item in redux state
        const item = constellationItemsState[o?.orderableReportID ?? 0];
        const isLabStatePristine = !item?.id;

        // Initialize "Complete Procedure" and "Enter Lab Results" lab items
        if (isEnterLabResultOrProcedureLab && isLabStatePristine) {
          const isProcedureComplete = !!o.resultDate;
          const isEnterLabResultComplete = !isEmpty(o?.orderableItemResults);

          dispatch(
            worklistTodoActions.setConstellationItem({
              id: o?.orderableReportID ?? 0,
              text: orderableItemName,
              completed: isProcedureComplete || isEnterLabResultComplete,
              orderableItem: {
                resultDate: o?.resultDate ?? '',
                labItemId: o?.labItemId ?? 0,
                orderDate: o?.orderDate ?? '',
                orderableItemName,
              },
              collectedDate: o?.collectedDate ?? '',
              resultDate: o?.resultDate ?? '',
              result: o?.result ?? '',
              futureOrder: !!futureOrder,
              received: o?.orderReceived ?? false,
              notes: o?.notes ?? '',
              internalNotes: o?.internalNotes ?? '',
              orderableItemResultFormFields:
                o?.orderableItemResults?.reduce(
                  (acc: ConstellationWorklistItem['orderableItemResultFormFields'], item) => {
                    const { orderableReportID, attributeID, ...rest } = item;
                    const formattedOrderableReportID = Number(orderableReportID) ?? 0;
                    const formattedAttributeID = Number(attributeID) ?? 0;

                    if (formattedAttributeID) {
                      acc[formattedAttributeID] = {
                        orderableReportID: formattedOrderableReportID,
                        attributeID: formattedAttributeID,
                        ...rest,
                      };
                    }

                    return acc;
                  },
                  {},
                ) ?? {},
            }),
          );
        }
      });
    }
  }, [encounterOrderableItems, isMAWorkflowEnabled]);

  return { loading };
}

interface Props {
  showFinalize?: boolean;
}

const PROCEDURE_LAB_ITEM_IDS = [
  268821, // SPIROMETRY
  268879, // EAR IRRIGATION
  275462, // NEBULIZER TREATMENT
  285265, // PRE/POST SPIROMETRY
  308254, // Holter Monitoring REPORT, 24 HRS
  308785, // HYDRATION IV INFUSION, INIT
  395353, // Ear Irrigation with the use of a curette instrument
  570313, // ECG continuous monitoring CAM (>48hrs-7day)
  570314, // ECG continuous monitoring CAM (8-15 days)
];

function isProcedureLabItem(labItemId: number | null | undefined): boolean {
  if (!labItemId) {
    return false;
  }

  return PROCEDURE_LAB_ITEM_IDS.includes(labItemId);
}

export function isEnterLabResultsLabItem(labItemId: number | null | undefined): boolean {
  if (!labItemId) {
    return false;
  }

  return [
    285267, // Glucose
    285266, // Glucose, whole blood (venous draw)
    245214, // Strep A
    237287, // Flu A/B
    224118, // Pregnancy Test, Urine HCG
    229159, // Hemoccult -IFOBT
    285289, // Drug Screen
    224354, // Mono
    582403, // Rapid COVID
  ].includes(labItemId);
}

export function WorklistModal(props: Props) {
  const { loading } = useInitializeWorklist();

  const { hideModal } = useModal();

  const isLabCollectionCompleted = useAppSelector(
    (state) => state.worklistTodo.isLabCollectionCompleted,
  );
  const {
    hasAtLeastOneSubmittedVaccineOrder,
    hasAtLeastOneSubmittedTherapeuticInjectionOrder,
    pendingVaccines,
    pendingTherapeuticInjections,
  } = usePendingVaccines();

  const { constellationItems } = useChecklistConstellationItems();

  const isAdministerVaccineCompleted = pendingVaccines.every((v) => v.completed);
  const isAdministerTherapeuticInjectionCompleted = pendingTherapeuticInjections.every(
    (v) => v.completed,
  );
  const { hasAtLeastOneWorklistItem, hasAtLeastOneSubmittedLabOrder } = useWorklistModal();

  const currentLabItems = constellationItems.filter((item) => !item.futureOrder);
  const enterLabResultLabItems = currentLabItems.filter((item) =>
    isEnterLabResultsLabItem(item.orderableItem.labItemId),
  );
  const procedureLabItems = currentLabItems.filter((item) =>
    isProcedureLabItem(item.orderableItem.labItemId),
  );

  return (
    <>
      <ModalCloseButton />
      <ModalBody>
        <Box h='70vh'>
          <Text variant='h6-b' mb='md'>
            Checklist
          </Text>
          <WaitUntilLoaded loading={loading}>
            {hasAtLeastOneWorklistItem ? (
              <List spacing={0}>
                {hasAtLeastOneSubmittedVaccineOrder && (
                  <WorklistTodoItem
                    text='Administer Vaccine(s)'
                    modalType='PendingVaccinesModal'
                    modalProps={{ type: 'Vaccination' }}
                    isChecked={isAdministerVaccineCompleted}
                  />
                )}
                {hasAtLeastOneSubmittedTherapeuticInjectionOrder && (
                  <WorklistTodoItem
                    text='Administer Therapeutic Injection(s)'
                    modalType='PendingVaccinesModal'
                    modalProps={{ type: 'Therapeutic Injection' }}
                    isChecked={isAdministerTherapeuticInjectionCompleted}
                  />
                )}
                {hasAtLeastOneSubmittedLabOrder && (
                  <WorklistTodoItem
                    text='Collect and Send Labs'
                    modalType='LabCollectionFormModal'
                    isChecked={isLabCollectionCompleted}
                  />
                )}
                {enterLabResultLabItems?.map((item) => {
                  return (
                    <WorklistTodoItem
                      key={item.id}
                      text={item.text ? `Enter Lab Results - ${item.text}` : ''}
                      modalType='ChecklistConstellationModal'
                      modalProps={{ constellationItem: item }}
                      isChecked={item.completed}
                    />
                  );
                })}
                {procedureLabItems?.map((item) => {
                  return (
                    <WorklistTodoItem
                      key={item.id}
                      text={item.text ? `Complete Procedure - ${item.text}` : ''}
                      modalType='ChecklistConstellationModal'
                      modalProps={{ constellationItem: item }}
                      isChecked={item.completed}
                    />
                  );
                })}
              </List>
            ) : (
              <Text>No items.</Text>
            )}
          </WaitUntilLoaded>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button
          variant='ghost'
          onClick={() => {
            hideModal();
          }}>
          Close
        </Button>
        <FinishButtonModal showFinalize={!!props.showFinalize} />
      </ModalFooter>
    </>
  );
}

function WorklistTodoItem({
  modalType,
  modalProps,
  text,
  isChecked,
}: {
  modalType: ModalTypes;
  modalProps?: any;
  text: string;
  isChecked?: boolean;
}) {
  const { showModal } = useModal();
  const { activeEncounterId } = useActiveEncounter();

  return (
    <ListItem
      display='flex'
      alignItems='center'
      px='md'
      py='sm'
      borderBottom={'1px solid'}
      borderColor={'blue.100'}
      _hover={{
        cursor: 'pointer',
        bg: 'gray.50',
      }}>
      <Flex
        w='full'
        align='center'
        justifyContent='space-between'
        onClick={() => {
          FS('trackEvent', {
            name: `${text} Clicked`, 
            properties: { encounter_id: activeEncounterId }
          });
          showModal({
            modalProps,
            modalType,
            chakraModalProps: { size: '6xl' },
          });
        }}>
        <HStack spacing={'md'}>
          <Checkbox isChecked={isChecked} />
          <Text>{text}</Text>
        </HStack>
        <IconButton
          aria-label='show LocationSelectorModal modal'
          size='sm'
          variant='ghost'
          icon={<Icon boxSize={5} as={BiChevronRight} />}
        />
      </Flex>
    </ListItem>
  );
}
