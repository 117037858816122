import { useState } from 'react';
import { useDebounce, usePatient } from '../../hooks';
import { useGetIcdCodesQuery } from '../../__generated__/graphql';
import { ComboBox } from '../../components';

export function AssessmentSearch({
  onChange,
}: {
  onChange: (value: {
    assessementItemId: number;
    assessmentName: string;
    assessmentCode: string;
  }) => void;
}) {
  const { patient } = usePatient();
  const [icdSearch, setIcdSearch] = useState('');

  const debouncedICDSearch = useDebounce(icdSearch, 400);

  const icdQuery = useGetIcdCodesQuery({
    variables: {
      startsWith: '',
      contains: debouncedICDSearch,
      isIcd10Code: true,
    },
  });

  return (
    <ComboBox
      filterOption={() => true}
      placeholder='Add New'
      isLoading={icdQuery.loading}
      onInputChange={(val) => {
        setIcdSearch(val ?? '');
      }}
      onSelection={(sel) => {
        const icdCode = sel?.value;
        const selectedProblem = icdQuery.data?.getICDCodes?.codes?.find(
          (mh) => mh.icdCode === icdCode,
        );
        onChange({
          assessementItemId: selectedProblem?.itemID ?? 0,
          assessmentName: selectedProblem?.name ?? '',
          assessmentCode: icdCode ?? '',
        });
      }}
      options={(icdQuery.data?.getICDCodes?.codes || [])
        .filter((val) => {
          return !patient.problems?.some((mh) => mh.icdCode === val.icdCode);
        })
        .map((code) => {
          return {
            label: `${code.icdCode} - ${code.name}`,
            value: code.icdCode,
          };
        })}
    />
  );
}
