import { Grid, GridItem, Spinner, VStack } from '../../../components';
import MAWorkFlowHeader from '../MAWorkFlowHeader';
import {
  useAppUI,
  useEncounter,
  useMAWorkflow,
  usePatient,
  useSteps,
  useToast,
} from '../../../hooks';
import { uiActions, useAppDispatch, useAppSelector } from '../../../state-management';
import { MAWorkFlowContentContainer } from '../MAWorkFlowContentContainer';
import { ROSPanel } from './ROSPanel';
import { ROSCategories } from './ROSCategories';

import _, { isNil } from 'lodash';
import {
  type EncounterRosDataItemInput,
  useGetEncounterRosDataQuery,
  useUpdateEncounterRosMutation,
} from '../../../__generated__/graphql';
import { useEffect } from 'react';
import { SaveAndWorklistButtons } from '../../components/SaveAndChecklistButtons';
import { AddItemEmptyStatePlaceholderLeft } from '../../../components/general/AddItemEmptyStatePlaceholderLeft';
import { FullStory as FS } from '@fullstory/browser';

const ERROR_TOAST_DURATION = 8000;

export function ROSContent({ nextStep }: { nextStep: () => void }) {
  const dispatch = useAppDispatch();
  const { markCurrentStepComplete } = useAppUI();
  const {
    ros: { rosData, selectedROSCategoryId },
  } = useMAWorkflow();
  const [
    updateEncounterRos,
    {
      data: dataUpdateEncounterRos,
      error: errorUpdateEncounterRos,
      loading: isLoadingUpdateEncounterRos,
    },
  ] = useUpdateEncounterRosMutation({ onCompleted: async () => await refetchEncounterRosData() });
  const isErrorUpdateEncounterRos = !(errorUpdateEncounterRos == null);
  const isSuccessUpdateEncounterRos = !(dataUpdateEncounterRos == null);
  const { patientId } = usePatient();
  const { activeEncounterID } = useEncounter();
  const lastUpdate = useAppSelector((state) => state.refetchData.encounterTemplateLastApplyTime);
  useEffect(() => {
    if (selectedROSCategoryId < 0 && rosData.encounterROSDataItems.length) {
      dispatch(uiActions.setROSSelectedCategoryId(rosData.encounterROSDataItems[0].rosCategoryID));
    }
  }, [rosData]);
  // Setup initial data: Hydrate the redux store with the saved user data from server
  const {
    data: dataGetEncounterRosData,
    loading: isLoadingGetEncounterRosData,
    error: errorGetEncounterRosData,
    refetch: refetchEncounterRosData,
  } = useGetEncounterRosDataQuery({
    variables: {
      encounterId: activeEncounterID,
    },
    skip: !activeEncounterID,
    onCompleted() {
      dispatch(uiActions.setHasLoadedInitialData('ros'));
    },
    onError() {
      dispatch(uiActions.setHasLoadedInitialData('ros'));
    },
  });
  const isErrorGetEncounterRosData = !(errorGetEncounterRosData == null);
  const userRosDataItems: EncounterRosDataItemInputUI[] | undefined =
    dataGetEncounterRosData?.getEncounterROSData?.encounterROSDataItems;

  useEffect(() => {
    void refetchEncounterRosData();
  }, [lastUpdate]);

  const { steps } = useSteps();

  useEffect(() => {
    const isDirty = steps.ros.isDirty; // If items exist in Redux, don't overwrite them
    if (!isNil(userRosDataItems) && !isDirty) {
      // Hydrate the redux store with the saved user data from server
      if (dataGetEncounterRosData?.getEncounterROSData) {
        dispatch(
          uiActions.setRosData({
            encounterROSDataItems: userRosDataItems,
            notes: dataGetEncounterRosData?.getEncounterROSData.notes || undefined,
          }),
        );
      }

      dispatch(uiActions.setAllRosDataItems(userRosDataItems));
    }
  }, [JSON.stringify(userRosDataItems)]);

  // Setup toasts
  useToast({
    id: 'isErrorGetEncounterRosData',
    title: 'Error',
    description: 'Failed to fetch encounter ROS data.',
    show: isErrorGetEncounterRosData,
    status: 'error',
    duration: ERROR_TOAST_DURATION,
  });

  useToast({
    id: 'isErrorUpdateEncounterRos',
    title: 'Error',
    description: 'Failed to update',
    show: isErrorUpdateEncounterRos,
    status: 'error',
    duration: ERROR_TOAST_DURATION,
  });

  useToast({
    id: 'isSuccessUpdateEncounterRos',
    title: 'Success',
    description: 'Your submission was successful.',
    show: isSuccessUpdateEncounterRos,
    status: 'success',
  });

  // Mark workflow complete if submission is successful
  useEffect(() => {
    if (isSuccessUpdateEncounterRos) {
      markCurrentStepComplete();
      nextStep();
    }
  }, [isSuccessUpdateEncounterRos, markCurrentStepComplete]);

  if (isLoadingGetEncounterRosData) {
    return <Spinner />;
  }
  return (
    <MAWorkFlowContentContainer>
      <VStack spacing='lg' alignItems='flex-start' flexGrow={1} overflow='auto'>
        <Grid
          w='full'
          templateColumns={{ md: 'repeat(12, 1fr)' }}
          gap={4}
          flexGrow={1}
          overflow='auto'>
          <GridItem colSpan={{ md: 3 }} h='full' overflow='auto'>
            <VStack alignItems={'flex-start'} overflow='auto' h='full'>
              <MAWorkFlowHeader mb='md'>ROS</MAWorkFlowHeader>
              <ROSCategories />
            </VStack>
          </GridItem>
          <GridItem colSpan={{ md: 9 }} overflow='auto'>
            {selectedROSCategoryId < 0 ? (
              <AddItemEmptyStatePlaceholderLeft text='To get started, search and add items to the ROS.' />
            ) : (
              <ROSPanel rosCategoryID={selectedROSCategoryId} />
            )}
          </GridItem>
        </Grid>
      </VStack>
      {/* Fixed button: TODO: Test, and make container for all MA Workflow submit buttons */}
      <SaveAndWorklistButtons
        isLoading={isLoadingUpdateEncounterRos}
        onClick={() => {
          let encounterROSDataItems = rosData.encounterROSDataItems.map((item) =>
            _.omit(item, ['rosSymptomName', 'rosCategoryName']),
          ) as EncounterRosDataItemInput[];
          // Check local data against server data to see if add/edit/delete has occurred locally
          const localHasChanged = !_.isEqual(
            rosData.encounterROSDataItems,
            dataGetEncounterRosData?.getEncounterROSData?.encounterROSDataItems,
          );

          // Add Reviewed by Provider if there is a local change, and it hasn't been added already
          if (localHasChanged) {
            let isReviewed = false;

            // Step through array backwards, because Reviewed By Provider should be at the end
            for (let i = encounterROSDataItems.length - 1; i >= 0; i--) {
              // If Reviewed By Provider is present...
              if (encounterROSDataItems[i].rosSymptomID === 307574) {
                // Set entry to True in case the user incorrectly entered the input
                const dataItemsCopy = [...encounterROSDataItems];
                dataItemsCopy[i] = {
                  ...encounterROSDataItems[i],
                  symptomNote: 'Yes',
                };
                encounterROSDataItems = dataItemsCopy;

                // Break the loop and record the result
                isReviewed = true;
                break;
              }
            }

            // Add Reviewed By Provider if not present
            if (!isReviewed) {
              encounterROSDataItems = [
                ...encounterROSDataItems,
                {
                  rosCategoryID: 286286,
                  rosSymptomID: 307574,
                  symptomNote: 'Yes',
                },
              ];
            }
          }

          FS('trackEvent', {
            name: 'Save / Verify ROS Clicked',
            properties: { encounter_id: activeEncounterID },
          });

          updateEncounterRos({
            variables: {
              encounterRosData: {
                notes: rosData.notes,
                encounterID: activeEncounterID,
                patientID: patientId,
                encounterROSDataItems,
              },
            },
          });
        }}>
        Save / Verify ROS
      </SaveAndWorklistButtons>
    </MAWorkFlowContentContainer>
  );
}
