import { CheckIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { FaXmark } from 'react-icons/fa6';
import {
  type CreateMedicalHistoryItem,
  useDeleteProblemMutation,
  useGetIcdCodesQuery,
  useGetMedicalHistoryQuery,
  useUpdateMedicalHistoryMutation,
} from '../../../__generated__/graphql';
import { ComboBox } from '../../../components';
import { useActiveEncounter, useDebounce, useEncounter, usePatient, useSidebar } from '../../../hooks';
import { getOrderFlow, useAppSelector } from '../../../state-management';
import { Flex } from '../../layout';
import { Spinner } from '../Spinner';
import { FullStory as FS } from '@fullstory/browser';

interface MedHistoryItemInput {
  addToPatientProblemList: boolean;
  icdCode: string;
  historyText: string;
  medhxItemID: number;
}

export function Problems() {
  const orderFlowCurrentStep = useAppSelector(getOrderFlow).currentStep;
  const { sidebarState, openSidebar, closeSidebar } = useSidebar();
  const { activeEncounterId } = useActiveEncounter();

  useEffect(() => {
    if (sidebarState.open) closeSidebar();
  }, [orderFlowCurrentStep]);

  return (
    <div>
      <Button
        width='100%'
        onClick={() =>
          {
            FS('trackEvent', {
              name: 'Problems Clicked', 
              properties: { encounter_id: activeEncounterId }
            });
            return openSidebar({ sidebarContent: <ProblemsContent />, sidebarHeader: 'Problem List' });
          }
        }
        variant='outline'
        height='24px'>
        <Text fontSize='xs'>Probs.</Text>
      </Button>
    </div>
  );
}

function ProblemsContent() {
  const { patient, loading } = usePatient();
  const { activeEncounterID } = useEncounter();
  const [search] = useState('');

  const medHistory = useGetMedicalHistoryQuery({
    variables: {
      patientId: patient?.patientID ? Number(patient?.patientID) : -1,
    },
    skip: !patient,
  });

  const medHistoryItems = medHistory.data?.getMedicalHistory?.medicalHistory || [];

  const [icdSearch, setIcdSearch] = useState('');

  const [updateMedHistory, updateMedHistMutation] = useUpdateMedicalHistoryMutation();
  const debouncedICDSearch = useDebounce(icdSearch, 400);

  const icdQuery = useGetIcdCodesQuery({
    variables: {
      startsWith: '',
      contains: debouncedICDSearch,
      isIcd10Code: true,
    },
  });

  const { nonHistory, history } = useMemo(() => {
    if (!loading && patient.problems) {
      const loweredSearch = search.toLocaleLowerCase();

      type PProblems = typeof patient.problems;
      const nonHistory: PProblems = [];
      const history: PProblems = [];

      patient.problems?.forEach((problem) => {
        const isAddedToHistory = medHistoryItems?.some((mh) => mh.icdCode === problem.icdCode);
        const searchMiss =
          loweredSearch &&
          (!problem.problemName?.toLocaleLowerCase().includes(loweredSearch) ||
            !problem.icdCode?.toLocaleLowerCase().includes(loweredSearch));

        if (searchMiss) return;

        if (isAddedToHistory) {
          history.push(problem);
        } else {
          nonHistory.push(problem);
        }
      });

      return { nonHistory, history };
    }
    return { nonHistory: [], history: [] };
  }, [patient, loading, medHistoryItems?.length]);

  const addToMedHist = (newItem: MedHistoryItemInput) => {
    const currentMedHis = medHistory.data?.getMedicalHistory?.medicalHistory || [];

    const updated: CreateMedicalHistoryItem[] = currentMedHis.map((mh) => {
      return {
        addToPatientProblemList: mh.presentOnProblemList,
        icdCode: mh.icdCode,
        historyText: mh.historyText,
      };
    });

    updateMedHistory({
      update(cache) {
        // invalidate getPatient so problems will be present on the
        // adhoc order diagnosis list
        cache.evict({
          fieldName: 'getPatient',
        });
      },
      onCompleted() {
        medHistory.refetch();
      },
      variables: {
        patientId: patient?.patientID ?? -1,
        encounterId: activeEncounterID ?? -1,
        medicalHistory: [...updated, newItem],
      },
    });
  };

  return (
    <VStack h='calc(100% - 50px)' pb='sm' overflow='auto' spacing='sm' width='full'>
      <Box w='full'>
        <ComboBox
          filterOption={() => true}
          placeholder='Add Problem'
          isLoading={icdQuery.loading || updateMedHistMutation.loading}
          onInputChange={(val) => {
            setIcdSearch(val ?? '');
          }}
          onSelection={(icdCode) => {
            if (icdCode) {
              setIcdSearch('');
              const item = icdQuery.data?.getICDCodes?.codes?.find(
                (c) => c.icdCode === icdCode.value,
              );
              if (!item) return;
              addToMedHist({
                icdCode: icdCode.value,
                historyText: icdCode.label,
                addToPatientProblemList: true,
                medhxItemID: item.itemID,
              });
            }
          }}
          options={(icdQuery.data?.getICDCodes?.codes || [])
            .filter((val) => {
              return !patient.problems?.some((mh) => mh.icdCode === val.icdCode);
            })
            .map((code) => {
              return {
                label: `${code.icdCode} - ${code.name}`,
                value: code.icdCode,
              };
            })}
        />
      </Box>

      {nonHistory?.map((problem) => (
        <Card key={problem.itemID} width='100%'>
          <CardBody>
            <Flex justifyContent='space-between' alignItems='center'>
              <Text fontSize='sm' as='b' width='48%'>
                {problem.problemName}
              </Text>
              <Text color='gray.500'>{problem.icdCode}</Text>
              <Button
                isLoading={updateMedHistMutation.loading || medHistory.loading}
                onClick={() => {
                  if (!problem.icdCode || !problem.problemName || !problem.itemID) return;
                  addToMedHist({
                    icdCode: problem.icdCode,
                    historyText: problem.problemName,
                    addToPatientProblemList: true,
                    medhxItemID: Number(problem.itemID),
                  });
                }}
                size='sm'
                leftIcon={<Icon as={BiPlus} />}
                variant='outline'>
                Med His
              </Button>
              <DeleteHandler
                problemText={problem.problemName}
                patientId={patient.patientID}
                sourceProblemId={problem.problemListSourceId}
              />
            </Flex>
          </CardBody>
        </Card>
      ))}

      {loading && <Spinner colorScheme='brand' />}

      {history?.map((problem, i) => {
        return (
          // not gauranteed to be unique
          <Card key={`${problem.icdCode}-${i}`} width='100%'>
            <CardBody>
              <Flex justifyContent='space-between' alignItems='center'>
                <Text fontSize='sm' as='b' width='48%'>
                  {problem.problemName}
                </Text>
                <Text color='gray.500'>{problem.icdCode}</Text>
                <Button
                  size='sm'
                  color='green'
                  leftIcon={<Icon as={CheckIcon} />}
                  disabled
                  variant='ghost'>
                  Med His
                </Button>
                <DeleteHandler
                  problemText={problem.problemName}
                  patientId={patient.patientID}
                  sourceProblemId={problem.problemListSourceId}
                />
              </Flex>
            </CardBody>
          </Card>
        );
      })}
    </VStack>
  );
}

interface DeleteHandlerProps {
  sourceProblemId?: number | null;
  problemText?: string | null;
  patientId?: number | null;
}

function DeleteHandler({ sourceProblemId, patientId, problemText }: DeleteHandlerProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteMutation, { loading }] = useDeleteProblemMutation();

  if (!sourceProblemId || !patientId) return null;

  const handleDelete = () => {
    deleteMutation({
      variables: {
        problemId: sourceProblemId,
        patientId,
      },
      update(cache) {
        cache.evict({
          fieldName: 'getPatient',
        });
        cache.evict({
          fieldName: 'getMedicalHistory',
        });
      },
      onCompleted() {
        onClose();
      },
    });
  };

  return (
    <>
      <IconButton
        isLoading={loading}
        aria-label='delete'
        icon={<Icon as={FaXmark} />}
        onClick={onOpen}
        size='sm'
        variant='ghost'
      />

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`Delete problem list item`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{`Are you sure you would like to remove "${problemText}" from patient problem list?`}</Text>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr='sm' onClick={onClose}>
              Cancel
            </Button>
            <Button isLoading={loading} onClick={handleDelete}>
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
