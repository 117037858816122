import React, { useState } from 'react';
import { Button, ErrorMessage, Textarea, Page, Text, VStack, Box } from '../../components';
import { useAuth } from '../../hooks';
import { FullStory as FS } from '@fullstory/browser';

const useCustomerFeedback = () => {
  const [error, setError] = useState<any>();
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { user } = useAuth();

  function getUserDisplay() {
    return `From: ${user.firstName} ${user.lastName} (${user.email})\n${user.userId}\n`;
  }

  async function handleSendFeedback(feedback: string) {
    const userStoryURL = await FS('getSessionAsync', { format: 'url.now' });
    const text = `${getUserDisplay()}\n${feedback}\n\n${userStoryURL}`;
    try {
      setLoading(true);
      await fetch(
        'https://hooks.slack.com/services/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',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            // CORS Content-Type Bug fix: https://stackoverflow.com/a/45752919/2213882
            // "Content-Type": "application/json",
          },
          body: JSON.stringify({
            text,
          }),
        },
      );
      setSuccess(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  }

  return {
    handleSendFeedback,
    isError: !!error,
    isSuccess,
    isLoading,
  };
};

export function CustomerFeedbackScreen() {
  const { handleSendFeedback, isLoading, isSuccess, isError } = useCustomerFeedback();
  const [feedbackMessage, setFeedbackMessage] = useState('');

  function handleSetFeedbackMessage(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setFeedbackMessage(e.target.value);
  }

  return (
    <Page headerTitle='Feedback'>
      <VStack spacing='lg'>
        <Box w='full'>
          <Text variant='h6-b' textAlign='left'>
            Please leave feedback
          </Text>
        </Box>
        <Textarea
          autoCapitalize='sentences'
          onChange={handleSetFeedbackMessage}
          value={feedbackMessage}
          placeholder='Enter feedback message...'
          rows={10}
        />
        <Button
          isLoading={isLoading}
          onClick={() => {
            handleSendFeedback(feedbackMessage);
          }}
          variant={isSuccess ? 'outline' : 'solid'}
          w='full'
        >
          {isSuccess ? 'Message sent successfully!' : 'Send Feedback'}
        </Button>
        {isError && <ErrorMessage text='Something went wrong' />}
      </VStack>
    </Page>
  );
}
