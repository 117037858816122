import {
  getLastSavedVitalsState,
  selectors,
  useAppDispatch,
  useAppSelector,
  worklistTodoActions,
} from '../state-management';
import {
  type EncounterVital,
  OrderMetricRecommendationType,
  useAddOrderableItemMutation,
  useDeclineOrderableItemMutation,
  useGetEncounterOrdersQuery,
} from '../__generated__/graphql';
import _, { isNil } from 'lodash';
import { useChiefComplaints } from '../screens/MAWorkFlowScreen/ChiefComplaintsPanel/useChiefComplaints';
import { useModal } from './useModal';
import { useAuth } from './useAuth';
import { useCustomToast } from './useToast';
import { type ConstellationRecommendationUI } from '../types';
import { useActiveEncounter } from './useActiveEncounter';
import { useMemo, useState } from 'react';
import { getConstellationRecommendations } from '../services/constellation-recommendations';
import { useMAWorkflow } from './useMAWorkflow';
import { useEncounter } from './useEncounter';

const addArrayVitals = (vitals: EncounterVital[], encounterVitals: EncounterVital[]) => {
  encounterVitals.forEach((bp) => {
    if (!isNil(bp.value)) {
      vitals.push(bp);
    }
  });
};

const addSingleVital = (vitals: EncounterVital[], encounterVital?: EncounterVital) => {
  if (encounterVital && !isNil(encounterVital.value)) {
    vitals.push(encounterVital);
  }
};

const addVitals = (
  vitals: EncounterVital[],
  encounterVitals?: EncounterVital | EncounterVital[],
) => {
  if (isNil(encounterVitals)) return;
  if (encounterVitals instanceof Array) {
    addArrayVitals(vitals, encounterVitals);
  } else {
    addSingleVital(vitals, encounterVitals);
  }
};

export const useConstellationRecommendation = (
  saveCompleteHandler?: () => void,
): {
  saveHandler: () => Promise<void>;
  isSavingOrderableItem: boolean;
  isLoading: boolean;
} => {
  const { isMAWorkflowEnabled } = useMAWorkflow();
  const dispatch = useAppDispatch();
  const { showModal } = useModal();
  const vitalsState = useAppSelector(getLastSavedVitalsState);
  const { complaintNames } = useChiefComplaints();
  const { activeEncounterID } = useEncounter();
  /**
   * Note: This magic had to be done b/c at the time saveHandler is initialized
   * it capture the prior value of complaintNames. By doing this it captures the
   * reference to complaintNamesRef, but complaintNamesRef[0] is always pointing
   * to the latest version of complaintNames
   */
  const [complaintNamesRef] = useState<string[][]>([]);
  complaintNamesRef[0] = complaintNames;

  const { data: encounterOrdersData } = useGetEncounterOrdersQuery({
    variables: {
      encounterId: activeEncounterID,
    },
  });

  const existingOrderableItems = encounterOrdersData?.getEncounterOrders?.encounterOrderableItems;

  const toast = useCustomToast();
  const { activeEncounter } = useActiveEncounter();
  const patient = activeEncounter?.patient;
  const patientId = patient?.patientID;
  const { user } = useAuth();
  const assignedToUserID = user.ecwId;
  const assignedToUserEmail = user.email || '';
  const encounterID = useAppSelector(selectors.encounterSelectors.getActiveEncounterID) || -1;
  const orderingProviderID = useAppSelector(selectors.getActiveProviderID) || -1;
  const facilityID = useAppSelector(selectors.getSelectedLocationID);
  const dateOfBirth = patient?.dateOfBirth || '';

  const [handleAddOrderableItem, { loading: isSavingOrderableItem }] =
    useAddOrderableItemMutation();
  const [handleDeclineOrderableItem] = useDeclineOrderableItemMutation();
  const vitals = useMemo(() => {
    const v: EncounterVital[] = [];
    if (vitalsState) {
      addVitals(v, vitalsState.bmiVitals);
      addVitals(v, vitalsState.heightVitals);
      addVitals(v, vitalsState.lmpVitals);
      addVitals(v, vitalsState.painScaleVitals);
      addVitals(v, vitalsState.restRateVitals);
      addVitals(v, vitalsState.waistCircVitals);
      addVitals(v, vitalsState.weightVitals);
      addVitals(v, vitalsState.tempVitals);
      addVitals(v, vitalsState.bloodPressureVitals);
      addVitals(v, vitalsState.heartRateVitals);
      addVitals(v, vitalsState.o2Sat);
    }
    return v;
  }, [vitalsState]);
  const saveHandler = async () => {
    await getConstellationRecommendations({
      variables: { vitals, chiefComplaints: complaintNamesRef[0], dateOfBirth },
      onCompleted: (data) => {
        const recommendations = [...data.getConstellationRecommendations].filter(
          (rec) =>
            existingOrderableItems?.find((lineItem) => lineItem?.labItemId === rec.id) ===
            undefined,
        );
        if (recommendations.length) {
          showModal({
            modalType: 'ConstellationRecommendationModal',
            chakraModalProps: { size: 'lg' },
            modalProps: {
              constellationRecommendations: _.uniqBy(
                recommendations,
                (rec) => rec.id,
              ).map<ConstellationRecommendationUI>((constellation) => {
                return {
                  ...constellation,
                  shouldDecline: false,
                };
              }),
              saveHandler: submitOrderableItems,
            },
          });
        } else {
          // if there are no recommended constellation recommendations
          saveCompleteHandler?.();
        }
      },
    });
  };
  const submitOrderableItems = async (orderableItems: ConstellationRecommendationUI[]) => {
    if (!orderableItems.length || !patientId) {
      saveCompleteHandler?.();
      return;
    }
    const promises = orderableItems.map(async (rec) => {
      const requestParams = {
        assignedToUserEmail,
        assignedToUserID: Number(assignedToUserID),
        labItemId: rec.id, // the orderable item id.  in our case it's ConstellationRecommendation.id. in orderable_item table, it's the `labItemId` table
        encounterID,
        orderDate: new Date().toISOString(),
        orderableItemTypeId: rec.itemTypeId,
        orderableItemName: rec.name,
        orderingProviderID,
        patientId,
        facilityID,
        orderMetricRecommendationType: OrderMetricRecommendationType.Clinical,
      };
      if (!rec.shouldDecline) {
        return await handleAddOrderableItem({
          variables: {
            ...requestParams,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            assessmentItemId: rec.icdItemId,
            futureOrder: false,
            icdCode: rec.icdCode,
          },
          onCompleted: (res) => {
            if (isMAWorkflowEnabled) {
              const orderableReportID = res.addOrderableItem?.orderableReportID ?? 0;

              if (orderableReportID) {
                dispatch(
                  worklistTodoActions.setConstellationItem({
                    id: orderableReportID,
                    text: requestParams.orderableItemName,
                    completed: false,
                    orderableItem: requestParams,
                    resultDate: new Date().toISOString(),
                    result: '',
                    received: false,
                    orderableItemResultFormFields: {},
                  }),
                );
              }
            }
          },
        });
      } else {
        return await handleDeclineOrderableItem({
          variables: { ...requestParams, clinicalDeclineReason: rec.declineReason },
        });
      }
    });
    const results = await Promise.all(promises);
    const hasErrors = results.some((result) => result?.errors && result.errors.length);
    if (hasErrors) {
      toast({
        id: 'create-constellation-order-error',
        title: 'Error',
        description: 'Error saving order submissions',
        status: 'error',
      });
    } else {
      toast({
        id: 'create-constellation-order-success',
        title: 'Order Success',
        description: 'Orders submitted successfully',
        status: 'success',
      });
      saveCompleteHandler?.();
    }
  };
  return {
    saveHandler,
    isSavingOrderableItem,
    isLoading: isSavingOrderableItem,
  };
};
