import { Center, Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from '../../../components';
import MAWorkFlowHeader from '../MAWorkFlowHeader';
import { MAWorkFlowContentContainer } from '../MAWorkFlowContentContainer';
import { OBHistoryPanel } from './OBHistoryPanel';
import ScrollContainer from './ScrollContainer';
import { GYNHistoryPanel } from './GYNHistoryPanel';
import { useAppUI, useEncounter, useMAWorkflow, usePatient, useToast } from '../../../hooks';
import { isEmpty } from 'lodash';
import { type g } from '../../../api';
import { SaveAndWorklistButtons } from '../../components/SaveAndChecklistButtons';
import { useEffect } from 'react';
import {
  useUpdateGynHistoryMutation,
  useUpdateObHistoryMutation,
} from '../../../__generated__/graphql';
import { FullStory as FS } from '@fullstory/browser';


const ERROR_TOAST_DURATION = 8000;

export function OBGYNContent({ nextStep }: { nextStep: () => void }) {
  const { maWorkFlowState } = useMAWorkflow();
  const {
    obgyn: { gynHistory, obHistory },
  } = maWorkFlowState;

  const [updateGYN, { data: dataUpdateGYN, error: errorUpdateGYN, loading: isLoadingUpdateGYN }] =
    useUpdateGynHistoryMutation();

  const isErrorUpdateGYN = !(errorUpdateGYN == null);
  const isSuccessUpdateGYN = !(dataUpdateGYN == null);

  const [updateOB, { data: dataUpdateOB, error: errorUpdateOB }] = useUpdateObHistoryMutation();

  const isErrorUpdateOB = !(errorUpdateOB == null);
  const isSuccessUpdateOB = !(dataUpdateOB == null);

  const { patientId } = usePatient();
  const { activeEncounterID } = useEncounter();

  function onSubmit() {
    // Omit __typename for query to work
    const selectedGynHistory = Object.values(gynHistory.entities).reduce<any>((acc, x) => {
      if (x != null) {
        const { sourcePatientId, __typename, ...rest } = x;
        rest.gynHistoryStructuredData = rest.gynHistoryStructuredData?.map((sd) => {
          const { __typename, ...rest } = sd as any;
          return rest;
        });
        acc.push(rest);
      }

      return acc;
    }, []);

    // Ob transform to input
    const selectedObHistory = Object.values(obHistory.entities).reduce<
      g.EncounterObHistoryInput['obHistory']
    >(
      (acc, x) => {
        if (acc != null && x != null && x.obSymptomID) {
          acc.push({
            obSymptomID: x.obSymptomID,
            symptomNotes: x.symptomNotes ?? '',
          });
        }
        return acc;
      },
      // [] as g.ObHistory[]
      [],
    );
    
    FS('trackEvent', {
      name: 'Save / Verify GYN/OB Clicked', 
      properties: { encounter_id: activeEncounterID }
    });

    updateGYN({
      variables: {
        encounterId: activeEncounterID,
        patientId,
        updateGynHistory: {
          gynHistory: selectedGynHistory,
        },
      },
    });

    updateOB({
      variables: {
        encounterId: activeEncounterID,
        patientId,
        updateObHistory: {
          obHistory: selectedObHistory,
        },
      },
    });
  }

  // Setup toasts
  useToast({
    id: 'isErrorUpdateGYN',
    title: 'Error',
    description: 'Failed to update GYN History',
    show: isErrorUpdateGYN,
    status: 'error',
    duration: ERROR_TOAST_DURATION,
  });

  useToast({
    id: 'isSuccessUpdateGYN',
    title: 'Success',
    description: 'Your GYN History submission was successful',
    show: isSuccessUpdateGYN,
    status: 'success',
  });

  useToast({
    id: 'isErrorUpdateOB',
    title: 'Error',
    description: 'Failed to update OB History',
    show: isErrorUpdateOB,
    status: 'error',
    duration: ERROR_TOAST_DURATION,
  });

  useToast({
    id: 'isSuccessUpdateOB',
    title: 'Success',
    description: 'Your OB History submission was successful.',
    show: isSuccessUpdateOB,
    status: 'success',
  });

  const { markCurrentStepComplete } = useAppUI();

  useEffect(() => {
    if (isSuccessUpdateGYN || isSuccessUpdateOB) {
      markCurrentStepComplete();
      nextStep();
    }
  }, [isSuccessUpdateGYN, isSuccessUpdateOB]);

  return (
    <MAWorkFlowContentContainer>
      <MAWorkFlowHeader>GYN/OB</MAWorkFlowHeader>
      <VStack spacing='lg'>
        {/* <GYNHistoryStructuredDataForm /> */}

        <Tabs colorScheme='brand' isLazy w='full'>
          <Center w='full'>
            <TabList minW='fit-content'>
              <Tab>GYN History</Tab>
              <Tab>OB History</Tab>
            </TabList>
          </Center>

          <TabPanels>
            <TabPanel>
              <ScrollContainer h='full'>
                <GYNHistoryPanel />
              </ScrollContainer>
            </TabPanel>
            <TabPanel>
              <ScrollContainer h='full'>
                <OBHistoryPanel />
              </ScrollContainer>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
      <SaveAndWorklistButtons
        isDisabled={isEmpty(gynHistory) && isEmpty(obHistory)}
        isLoading={isLoadingUpdateGYN}
        onClick={onSubmit}>
        Save / Verify GYN/OB
      </SaveAndWorklistButtons>
    </MAWorkFlowContentContainer>
  );
}
