import { useAppSelector } from '../state-management';

export function useChecklistConstellationItems() {
  const constellationItemsObj = useAppSelector((state) => state.worklistTodo.constellationItems);
  const constellationItems = Object.values(constellationItemsObj);

  return {
    hasAtLeastOneConstellationItem: constellationItems.filter((ci) => !ci.futureOrder).length > 0,
    constellationItems,
  };
}
