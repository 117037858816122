import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { DatePicker } from '../../../../components';
import { AsyncSelectField } from '../../../../features';
import { SearchIcon } from '@chakra-ui/icons';
import {
  type ImmunizationInjectionLookup,
  ImmunizationInjectionType,
  RecommendationType,
  type SubmitImmunizationInjectionInput,
  useGetImmunizationInjectionsQuery,
  useSubmitImmunizationInjectionMutation,
} from '../../../../__generated__/graphql';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useCustomToast, usePatient } from '../../../../hooks';

type FormInputs = SubmitImmunizationInjectionInput;

function invalidateCache(cache: any) {
  // Invalidate cache
  cache.evict({
    fieldName: 'getImmunizationInjectionPatient',
  });
}

export function AddImmunizationHistory() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState<string | undefined>('');
  const toast = useCustomToast();

  const { patientId } = usePatient();

  const { handleSubmit, control } = useForm<FormInputs>();

  const [submitImmunization, { loading: isLoadingSubmitImmunization }] =
    useSubmitImmunizationInjectionMutation();

  const [selectedDropdownItem, setSelectedOrderableItem] = useState<
    DropdownOption | undefined | null
  >();

  const { data: injectionData, loading: getImmunizationInjectionsLoading } =
    useGetImmunizationInjectionsQuery({
      variables: {
        contains: searchTerm ?? '',
        immunizationInjectionTypes: [ImmunizationInjectionType.Immunization],
      },
      skip: !searchTerm,
    });

  const immunizationInjectionLookupData = injectionData?.getImmunizationInjections ?? [];
  const dropdownItems = convertInjectionLookupItemToAsyncDropdownOptions(
    immunizationInjectionLookupData,
  );
  const selectedInjectionItem = immunizationInjectionLookupData.find(
    (item) => item.immunizationItemId === Number(selectedDropdownItem?.value),
  );

  const onSubmit = handleSubmit(handleSubmitImmunization);

  function handleSubmitImmunization(values: FormInputs) {
    if (selectedInjectionItem) {
      submitImmunization({
        variables: {
          input: {
            ...values,
            immunizationItemId: selectedInjectionItem.immunizationItemId,
            immunizationName: selectedInjectionItem.name,
            sourcePatientId: patientId,
            patientImmunizationInjectionType: RecommendationType.Immunization,
            documentingFacilityId: 0,
            lotId: 0,
            administeredByUserId: 0,
            vaccinationGivenInThePast: true,
            sourceEncounterId: 0,
            sourceOfHistory: '01',
            statusId: 1, // Administererd
          },
        },
        onError(err) {
          toast({
            id: 'submit-immunization-error',
            status: 'error',
            title: 'Error',
            duration: 5000,
            description: err.message,
          });
        },
        update: invalidateCache,
        onCompleted: onClose,
      });
    }
  }

  return (
    <>
      <Button onClick={onOpen}>Add Immunization History</Button>

      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Immunization History</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing='md' align={'stretch'}>
              <Box mb='md'>
                <AsyncSelectField
                  inputRightElement={<SearchIcon color='gray.300' />}
                  handleOptionSelect={(option) => {
                    setSelectedOrderableItem(option);
                  }}
                  inputProps={{
                    placeholder: 'Quick Find',
                    autoFocus: true,
                  }}
                  dropdownItems={dropdownItems}
                  handleInputChange={(val) => {
                    if (!val) return;
                    setSearchTerm(val);
                  }}
                  loading={getImmunizationInjectionsLoading}
                />
              </Box>

              <Flex justifyContent={'space-between'} alignItems={'center'}>
                <Text fontSize={'xl'} fontWeight={'bold'}>
                  {selectedDropdownItem?.label}
                </Text>
                <div>
                  <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='given-in-past' mb='0' whiteSpace='nowrap'>
                      Given In Past
                    </FormLabel>
                    <Switch id='given-in-past' isChecked disabled />
                  </FormControl>
                </div>
              </Flex>

              <Controller
                render={({ field }) => (
                  <FormControl>
                    <FormLabel>Given Date/Time</FormLabel>
                    <DatePicker
                      placeholder='Date'
                      maxDate={new Date()}
                      value={field.value ? new Date(field.value) : null}
                      onChange={(date) => {
                        field.onChange(date?.toISOString());
                      }}
                    />
                  </FormControl>
                )}
                control={control}
                name='dateTimeAdministered'
              />

              <Controller
                render={({ field }) => (
                  <Textarea
                    placeholder='Comments'
                    rows={7}
                    {...field}
                    value={field.value ?? undefined}
                  />
                )}
                control={control}
                name='notes'
              />
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' onClick={onClose}>
              Back
            </Button>
            <Button
              isDisabled={!selectedInjectionItem}
              isLoading={isLoadingSubmitImmunization}
              onClick={onSubmit}>
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export const convertInjectionLookupItemToAsyncDropdownOptions = (
  items?: ImmunizationInjectionLookup[],
): DropdownOption[] => {
  return (
    items?.map((i) => ({
      value: i.immunizationItemId.toString(),
      label: i.name,
    })) || []
  );
};
