import React, { useRef, useEffect } from 'react';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { Flex, type FlexProps } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCustomToast } from '../../hooks';

/**
 * Container that accounts for space needed for "Save / Verify" button at the bottom of the MA workflow pages
 */
export function MAWorkFlowContentContainer(props: FlexProps) {
  const { height } = useWindowDimensions();
  const containerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useCustomToast();

  useEffect(() => {
    // Check for templateApplied query parameter
    // This is used to show the toast and reload the page after applying templates
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('templateApplied') === 'true') {
      // Show toast
      toast({
        id: 'encounter-template-apply',
        title: 'Template Applied Successfully!',
        status: 'success',
        duration: 3000,
      });

      // Remove the query parameter
      // also remove it from the history
      // so the user can't navigate back
      // and show the toast again
      searchParams.delete('templateApplied');
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
        },
        { replace: true },
      );
    }

    // Function to scroll to top
    const scrollToTop = () => {
      containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // you can dispatch this event from any component to scroll to the top of the MA Workflow content container
    window.addEventListener('scrollToTopOfMAWorkFlowContentContainer', scrollToTop);

    return () => {
      window.removeEventListener('scrollToTopOfMAWorkFlowContentContainer', scrollToTop);
    };
  }, [location, navigate, toast]);

  return (
    <Flex
      direction='column'
      justifyContent='flex-start'
      h={height - 170} // Give space for fixed submit button
      overflowY='scroll'
      position='relative'
      {...props}
      ref={containerRef}
    />
  );
}

export default MAWorkFlowContentContainer;
