import { useEffect } from 'react';
import {
  useAppDispatch,
  useAppSelector,
  uiActions,
  socialHistorySelectors,
} from '../state-management';
import { useEncounter } from './useEncounter';
import { usePatient } from './usePatient';
import { isNil } from 'lodash';
import { useMAWorkflow } from './useMAWorkflow';
import {
  useGetSocialHistoryCategoriesQuery,
  useGetSocialHistoryQuery,
  useUpdateSocialHistoryMutation,
} from '../__generated__/graphql';
import { FullStory as FS } from '@fullstory/browser';


export function useSocialHistory() {
  const dispatch = useAppDispatch();
  const { maWorkFlowState, getHasLoadedInitialData } = useMAWorkflow();
  const { activeEncounterID } = useEncounter();
  const { patientId } = usePatient();

  // GET SOCIAL HISTORY
  const {
    data: dataGetSocialHistoryData,
    loading: isLoadingGetSocialHistory,
    error: errorGetSocialHistory,
    refetch: refetchSocialHistory,
  } = useGetSocialHistoryQuery({ variables: { patientId } });
  const dataGetSocialHistory = dataGetSocialHistoryData?.getSocialHistory;

  const {
    data: dataGetSocialHistoryCategoriesData,
    loading: isLoadingGetSocialHistoryCategories,
    error: errorGetSocialHistoryCategories,
  } = useGetSocialHistoryCategoriesQuery();
  const lastUpdate = useAppSelector((state) => state.refetchData.encounterTemplateLastApplyTime);
  useEffect(() => {
    void refetchSocialHistory();
  }, [lastUpdate]);
  const dataGetSocialHistoryCategories =
    dataGetSocialHistoryCategoriesData?.getSocialHistoryCategories;
  const isErrorGetSocialHistoryCategories = !!errorGetSocialHistoryCategories;
  const isSuccessGetSocialHistoryCategories = !isErrorGetSocialHistoryCategories;

  const { currentCategoryId } = maWorkFlowState?.socialHistory || { socialHistory: null };
  const currentSocialHistoryCategory = dataGetSocialHistoryCategories?.find((c) => {
    return c.socialHistoryCategoryID === currentCategoryId;
  });

  // Setup initial social history in store
  useEffect(() => {
    const hasLoadedInitialData = getHasLoadedInitialData('social-history');

    if (!hasLoadedInitialData && dataGetSocialHistory != null) {
      dispatch(uiActions.addSocialHistoryList(dataGetSocialHistory));
      // add a slight delay for the dirty-state watcher
      dispatch(uiActions.setHasLoadedInitialData('social-history'));
    }
  }, [JSON.stringify(dataGetSocialHistory)]); // TODO: Figure out consistent way of handling objects

  // Setup initialcategoryId which gives us an initial tab selection in left panel
  const initialCategoryId = dataGetSocialHistoryCategories?.[0]?.socialHistoryCategoryID;
  useEffect(() => {
    if (isNil(currentCategoryId) && initialCategoryId) {
      dispatch(uiActions.onChangeSocialHistoryCategory(initialCategoryId));
    }
  }, [currentCategoryId, dispatch, initialCategoryId]); // TODO: Figure out consistent way of handling objects

  const userSocialHistoryList = useAppSelector((state) => socialHistorySelectors.selectAll(state));

  const [
    onUpdateSocialHistory,
    {
      loading: isLoadingUpdateSocialHistory,
      called: calledUpdateSocialHistory,
      error: errorUpdateSocialHistory,
      data: dataUpdateSocialHistory,
    },
  ] = useUpdateSocialHistoryMutation();
  function handleUpdateSocialHistory() {
    FS('trackEvent', {
      name: 'Save / Verify Social History Clicked', 
      properties: { encounter_id: activeEncounterID }
    });

    void onUpdateSocialHistory({
      variables: {
        encounterId: activeEncounterID,
        patientId,
        socialHistory: userSocialHistoryList,
      },
    });
  }

  return {
    handleUpdateSocialHistory,
    userSocialHistoryList,
    currentCategoryId,
    currentSocialHistoryCategory,
    dataGetSocialHistoryCategories,
    dataGetSocialHistory,
    isLoadingGetSocialHistoryCategories,
    isErrorGetSocialHistoryCategories,
    isSuccessGetSocialHistoryCategories,
    isLoadingGetSocialHistory,
    isErrorGetSocialHistory: !!errorGetSocialHistory,
    isSuccessGetSocialHistory: !errorGetSocialHistory,
    isLoadingUpdateSocialHistory,
    // TODO: move away from this pattern of calling an update mutation then looking at an external success/error
    // variable for status of the mutation. The codegened mutation hook provides an onComplete & onError option callback.
    isSuccessUpdateSocialHistory: !!dataUpdateSocialHistory,
    isErrorUpdateSocialHistory: calledUpdateSocialHistory && !!errorUpdateSocialHistory,
  };
}
