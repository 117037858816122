import { ChevronRightIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { AiOutlinePlus as AddIcon } from 'react-icons/ai';
import { useGetHpiCategoriesQuery } from '../../../__generated__/graphql';
import {
  Box,
  Flex,
  HStack,
  Icon,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  WaitUntilLoaded,
} from '../../../components';
import { DEBOUNCE_MILLISECONDS } from '../../../constants';
import { useDebounce } from '../../../hooks';
import { uiActions, useAppDispatch } from '../../../state-management';
import { type HPICategoryDataItem } from '../../../types';
import { HPIExistingEncounterSymptomsPanel } from './HPIExistingEncounterSymptomsPanel';

interface HPICategoriesProps {
  categoryData?: HPICategoryDataItem[] | null;
}

const TAB_NAMES = ['All', 'Selected'] as const;
type TabName = (typeof TAB_NAMES)[number];

export function HPICategories({ categoryData }: HPICategoriesProps) {
  const [activeTab, setActiveTab] = useState<TabName>('All');
  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchText = useDebounce(searchText, DEBOUNCE_MILLISECONDS);
  const dispatch = useAppDispatch();
  const { data: hpiCategoriesData, loading: isLoading } = useGetHpiCategoriesQuery({
    variables: { contains: debouncedSearchText },
  });
  const hpiCategories = hpiCategoriesData?.getHPICategories || [];
  const selectedCount = categoryData?.length || 0;

  const clickHandler = (encounterCategory: HPICategoryDataItem, symptomId?: number) => {
    setActiveTab('Selected');
    dispatch(uiActions.setHPISelectedCategoryId(encounterCategory.hpiCategoryId || -1));
    dispatch(uiActions.setHPISelectedSymptomId(symptomId || -1));
    dispatch(uiActions.setHPISelectedEncounterCategory(encounterCategory));
    dispatch(uiActions.addHPISelectedEncounterCategory(encounterCategory));
  };

  const allTab = () => {
    return (
      <VStack overflow='auto' h='full'>
        <Flex w='full'>
          <Input
            size='lg'
            borderColor='secondary'
            borderWidth='2px'
            autoFocus
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            mb='sm'
            placeholder='Search'
            _focus={{
              boxShadow: 'primary',
              borderColor: 'brand.500',
            }}
          />
        </Flex>

        <VStack w='full' align='flex-start' h='full' overflow='auto' justify='flex-start'>
          <WaitUntilLoaded loading={isLoading}>
            {hpiCategories?.map((hpiCategory) => {
              const isDisabled = categoryData
                ?.map((category) => category.hpiCategoryId)
                .includes(hpiCategory?.hpiCategoryID || -1);

              return (
                <Box pl='sm' key={hpiCategory?.hpiCategoryID} w='full'>
                  <Box
                    opacity={isDisabled ? 0.5 : 1}
                    onClick={() => {
                      if (hpiCategory && !isDisabled) {
                        const newCategoryData = {
                          hpiCategoryId: hpiCategory.hpiCategoryID || null,
                          name: hpiCategory.name || null,
                          encounterHPIDataItems: [],
                          hpiCategoryNotesHeader: '',
                        };
                        clickHandler(newCategoryData);
                        setSearchText('');
                      }
                    }}
                    textAlign='left'>
                    <Box
                      role='group'
                      _hover={{ color: !isDisabled ? 'primary' : '' }}
                      cursor={isDisabled ? 'default' : 'pointer'}
                      mb={3}>
                      <VStack spacing={0}>
                        <HStack spacing='sm' justifyContent='flex-start' w='full'>
                          <Text
                            as='b'
                            whiteSpace='nowrap'
                            w='full'
                            textAlign='left'
                            noOfLines={1}
                            flex={1}>
                            {hpiCategory?.name}
                          </Text>
                          <Icon boxSize={6} as={AddIcon} color='brand.500' />
                        </HStack>
                        {!!hpiCategory?.hpiSymptoms?.length && searchText && (
                          <Box
                            w='full'
                            color='secondary'
                            _groupHover={{ color: !isDisabled ? 'primary' : '' }}>
                            <Text fontSize='sm' as='sub' pr={1} w='full' textAlign='left'>
                              Symptoms <ChevronRightIcon />
                            </Text>
                            <Text fontSize='sm' as='sub' w='full' textAlign='left'>
                              {hpiCategory?.hpiSymptoms?.map((symptom) => symptom?.name).join(', ')}
                            </Text>
                          </Box>
                        )}
                      </VStack>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </WaitUntilLoaded>
        </VStack>
      </VStack>
    );
  };

  return (
    <VStack spacing='md' w='full' h='full' overflow='auto' pr='md'>
      <Tabs
        index={TAB_NAMES.indexOf(activeTab)}
        onChange={(index) => {
          setActiveTab(TAB_NAMES[index]);
        }}
        colorScheme='brand'
        display='flex'
        flexDirection='column'
        overflow='auto'
        isFitted
        width='100%'>
        <TabList>
          <Tab>All</Tab>
          <Tab>Selected ({selectedCount})</Tab>
        </TabList>

        <TabPanels overflow='auto'>
          <TabPanel px={0} overflow='auto' h='full'>
            {allTab()}
          </TabPanel>
          <TabPanel px={0} overflow='auto' h='full'>
            <HPIExistingEncounterSymptomsPanel
              dataItems={categoryData}
              clickHandler={clickHandler}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
}
