import { type HpiSmartFormEntity } from '../../../../__generated__/graphql';

// phq2 is incomplete as we receive it from aylo
// we need to add the score field
// and the field options
export function handleCustomSmartForm(smartForms: HpiSmartFormEntity[]): HpiSmartFormEntity[] {
  const shouldApply = smartForms.some((sf) => sf.itemID === 23453);

  if (!shouldApply) {
    return smartForms;
  }

  const updated = smartForms.map((sf) => {
    if (sf.itemID === 23453 && sf.name !== 'Score') {
      return {
        ...sf,
        smartFormSurveyStructureOptions: [
          { displayIndex: 1, name: 'Not at all', structDataOptionID: 0 },
          { displayIndex: 2, name: 'Several days', structDataOptionID: 1 },
          { displayIndex: 3, name: 'More than half the days', structDataOptionID: 2 },
          { displayIndex: 4, name: 'Nearly every day', structDataOptionID: 3 },
        ],
      };
    }
    return sf;
  });

  const isMissingScoreField = !updated.some((sf) => sf.itemID === 23453 && sf.name === 'Score');

  if (isMissingScoreField) {
    // if the score field is not present push it here
    updated.push({
      itemID: 23453,
      name: 'Score',
      smartFormSurveyStructureOptions: [],
      structureDataDetailID: 123456789,
    });
  }

  return updated;
}
