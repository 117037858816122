import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { isString } from 'lodash';
import { IMMUNIZATION_DIAGNOSIS } from '../constants';
import {
  type OrderUI,
  type Appointment,
  type DiagnosisUI,
  type InjectionSite,
  type AdministrationRoute,
  type ClientDeclineReasonCode,
  type AlertId,
  type DeclineReason,
  type SelectedOrderableItem,
} from '../types';
import { getDefaultDiagnosis, isRecommendationInjectionOrImmunization } from '../utils';
import {
  type ImmunizationInjectionLotDetails,
  type OrderDeclineReasonCode,
  type Assessment,
} from '../__generated__/graphql';

export interface EditedOrder extends OrderUI {
  beforeEdit?: EditedOrder;
  lotDetail?: ImmunizationInjectionLotDetails;
  injectionSite?: InjectionSite;
  adminRoute?: AdministrationRoute;
  selectedOrderableItem?: SelectedOrderableItem;
  orderAssessments?: Array<Pick<Assessment, 'assessmentId' | 'assessmentName' | 'assessmentValue'>>;
  status:
    | 'YES_SAVED_DIAGNOSIS'
    | 'YES_EDITING_DIAGNOSIS'
    | 'YES_ADDING_DIAGNOSIS'
    | 'NO_ADDING_DECLINE_REASON'
    | 'NO_EDITING_DECLINE_REASON'
    | 'NO_SAVED_DECLINE_REASON';
}
export interface AppointmentsState {
  activeAppointmentID: number;
  activeAppointment?: Appointment; // TODO: Remove

  // Still get this error: Element implicitly has an 'any' type because index expression is not of type 'number'.
  // editedOrders: Record<number, EditedOrder>;
  // Fix: https://stackoverflow.com/a/40358512/2213882
  editedOrders: Record<number, EditedOrder>;
}

const initialState: AppointmentsState = {
  activeAppointmentID: 0,
  editedOrders: {},
};

// Selectors. TODO: Merge with main selectors file

function getOrderLineItemById(state: AppointmentsState, alertId: number): EditedOrder {
  return state.editedOrders[alertId];
}

function removeAllSelections(editedOrder: EditedOrder) {
  delete editedOrder.lotDetail;
  delete editedOrder.diagnosis;
  delete editedOrder.injectionSite;
  delete editedOrder.adminRoute;
  delete editedOrder.selectedOrderableItem;

  delete editedOrder.beforeEdit;
  delete editedOrder.declineReason;
  delete editedOrder.diagnosis;
}

function isSelectionComplete(editedOrder: EditedOrder) {
  // const hasOrderableItemSelectionOption =
  //   !!editedOrder.alert.orderableItemOptions;
  // const hasSelectedImmunization = !!editedOrder.selectedOrderableItem;
  const hasDiagnosisSelected = !(editedOrder?.diagnosis == null);

  // const isAccepted =
  //   hasOrderableItemSelectionOption &&
  //   hasSelectedImmunization &&
  //   hasDiagnosisSelected;

  const hasOrderableItemSelectionOption = !(editedOrder.alert.orderableItemOptions == null);

  const hasSelectedOrderableItem = hasOrderableItemSelectionOption
    ? !(editedOrder.selectedOrderableItem == null)
    : true;

  const isAccepted = hasDiagnosisSelected && hasSelectedOrderableItem;

  const isDeclined = editedOrder.declineReason?.serverDeclineReasonCode;

  return isAccepted || isDeclined;
}

function handleCancelEdit(state: AppointmentsState, alertId: AlertId) {
  const lineItem = getOrderLineItemById(state, alertId || 0);
  if (lineItem.beforeEdit != null) {
    state.editedOrders[alertId || 0] = { ...lineItem.beforeEdit };
  }
}
export const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    createEditedOrders(state, action: PayloadAction<AppointmentsState['editedOrders']>) {
      state.editedOrders = action.payload;
    },
    addActiveEncounter(state, action: PayloadAction<Appointment>) {
      state.activeAppointment = action.payload;
      /**
       * Empty edited orders. If a user is starting a new encounter, we empty the editedOrders
       * so they don't see the old ones they were possibly modifying (e.g. if they were on the
       * "Confirm Order" page and clicked back till they reached Encounters page again)
       * */
      state.editedOrders = {};
    },
    editOrderLineItem: (state, action: PayloadAction<{ alertId: AlertId }>) => {
      const { alertId } = action.payload;
      const lineItem = getOrderLineItemById(state, alertId || 0);
      delete lineItem.beforeEdit; // Incase this somehow lingers from different process.
      lineItem.beforeEdit = { ...lineItem };

      switch (lineItem.status) {
        case 'YES_SAVED_DIAGNOSIS': {
          lineItem.status = 'YES_EDITING_DIAGNOSIS';
          return;
        }
        case 'NO_SAVED_DECLINE_REASON': {
          lineItem.status = 'NO_EDITING_DECLINE_REASON';
        }
      }
    },
    cancelOrderLineItemEdit: (state, action: PayloadAction<{ alertId: AlertId }>) => {
      const { alertId } = action.payload;
      handleCancelEdit(state, alertId);
    },
    toggleOrderType(state, action: PayloadAction<{ alertId: AlertId }>) {
      const { alertId } = action.payload;
      const lineItem = getOrderLineItemById(state, alertId || 0);

      switch (lineItem.status) {
        case 'YES_ADDING_DIAGNOSIS': {
          lineItem.orderType = 'DECLINED';
          lineItem.status = 'NO_ADDING_DECLINE_REASON';
          // Remove any selections
          removeAllSelections(lineItem);
          return;
        }
        case 'NO_ADDING_DECLINE_REASON': {
          lineItem.orderType = 'ACCEPTED';
          lineItem.status = 'YES_ADDING_DIAGNOSIS';
          // Pre-select 'Z23' diagnosis for immunization or injection orders
          if (isRecommendationInjectionOrImmunization(lineItem)) {
            lineItem.diagnosis = IMMUNIZATION_DIAGNOSIS;
          }

          const defaultDiagnosis = getDefaultDiagnosis(lineItem);
          if (defaultDiagnosis != null) {
            lineItem.diagnosis = defaultDiagnosis;
          }
        }
      }
    },

    changeDiagnosis: (
      state,
      action: PayloadAction<{ alertId: AlertId; diagnosis: DiagnosisUI }>,
    ) => {
      const { alertId, diagnosis } = action.payload;
      const lineItem = getOrderLineItemById(state, alertId || 0);

      if (
        lineItem.status === 'YES_ADDING_DIAGNOSIS' ||
        lineItem.status === 'YES_EDITING_DIAGNOSIS'
      ) {
        lineItem.orderType = 'ACCEPTED';
        lineItem.diagnosis = diagnosis;
        if (isSelectionComplete(lineItem)) {
          lineItem.status = 'YES_SAVED_DIAGNOSIS';
        }
      }
    },

    changeOrderableItem: (
      state,
      action: PayloadAction<{
        alertId: AlertId;
        selectedImmunization: SelectedOrderableItem;
      }>,
    ) => {
      const { alertId, selectedImmunization } = action.payload;
      const lineItem = getOrderLineItemById(state, alertId || 0);

      if (
        lineItem.status === 'YES_ADDING_DIAGNOSIS' ||
        lineItem.status === 'YES_EDITING_DIAGNOSIS'
      ) {
        lineItem.orderType = 'ACCEPTED';
        lineItem.selectedOrderableItem = selectedImmunization;
        if (isSelectionComplete(lineItem)) {
          lineItem.status = 'YES_SAVED_DIAGNOSIS';
        }
      }
    },
    changeDeclineReason: (
      state,
      action: PayloadAction<
        {
          alertId: AlertId;
        } & Partial<DeclineReason>
      >,
    ) => {
      const {
        alertId,
        serverDeclineReasonCode,
        additionalDetails,
        dateTimeOrdered,
        location,
        result,
      } = action.payload;
      const lineItem = getOrderLineItemById(state, alertId || 0);

      // TODO: Properly do "ADDING" vs "EDIT" modes
      if (lineItem.declineReason != null) {
        if (isString(additionalDetails)) {
          lineItem.declineReason.additionalDetails = additionalDetails;
        }

        if (isString(serverDeclineReasonCode)) {
          lineItem.declineReason.serverDeclineReasonCode = serverDeclineReasonCode;
        }

        if (isString(dateTimeOrdered)) {
          lineItem.declineReason.dateTimeOrdered = dateTimeOrdered;
        }

        if (isString(location)) {
          lineItem.declineReason.location = location;
        }

        if (isString(result)) {
          lineItem.declineReason.result = result;
        }
      }
    },
    resetOrderLineItem: (
      state,
      action: PayloadAction<{
        alertId: AlertId;
      }>,
    ) => {
      const { alertId } = action.payload;
      const lineItem = getOrderLineItemById(state, alertId || 0);

      lineItem.orderType = 'ACCEPTED';
      lineItem.status = 'YES_ADDING_DIAGNOSIS';
      removeAllSelections(lineItem);
    },
    addDeclineReasonStart: (
      state,
      action: PayloadAction<{
        alertId: AlertId;
        uiDeclineReasonCode: ClientDeclineReasonCode;
        serverDeclineReasonCode: OrderDeclineReasonCode;
        declineReasonName: string;
      }>,
    ) => {
      const { alertId, uiDeclineReasonCode, declineReasonName, serverDeclineReasonCode } =
        action.payload;
      const lineItem = getOrderLineItemById(state, alertId || 0);

      if (lineItem.status === 'NO_ADDING_DECLINE_REASON') {
        lineItem.orderType = 'DECLINED';
        lineItem.declineReason = {
          uiDeclineReasonCode,
          serverDeclineReasonCode,
          name: declineReasonName,
        };
      }
    },
    addDeclineReasonComplete: (
      state,
      action: PayloadAction<{
        alertId: AlertId;
      }>,
    ) => {
      const { alertId } = action.payload;
      const lineItem = getOrderLineItemById(state, alertId || 0);

      if (
        lineItem.status === 'NO_ADDING_DECLINE_REASON' ||
        lineItem.status === 'NO_EDITING_DECLINE_REASON'
      ) {
        delete lineItem.beforeEdit;
        lineItem.status = 'NO_SAVED_DECLINE_REASON';
      }
    },
    updateAttachedReportIds: (
      state,
      action: PayloadAction<{
        attachedReportIds?: number[] | null;
      }>,
    ) => {
      const { attachedReportIds } = action.payload;

      state.activeAppointment = { ...(state.activeAppointment as Appointment), attachedReportIds };
    },
    updateClinicalStatus: (
      state,
      action: PayloadAction<{
        clinicalStatusAfterCheckIn: string;
      }>,
    ) => {
      const { clinicalStatusAfterCheckIn } = action.payload;

      state.activeAppointment = {
        ...(state.activeAppointment as Appointment),
        clinicalStatusAfterCheckIn,
      };
    },
    reset() {
      return initialState;
    },
  },
});

export const appointmentsActions = appointmentsSlice.actions;

export default appointmentsSlice.reducer;
