import { Search2Icon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useActiveEncounter, usePatient } from '../../../hooks';
import { useMemo, useState } from 'react';
import { formatDate } from '../../../utils';
import { Spinner } from '../Spinner';
import { useSelector } from 'react-redux';
import { appointmentsActions, selectors, useAppDispatch } from '../../../state-management';
import { Avatar } from './UserInfo/UserInfo';
import { FaFileLines, FaFilePdf } from 'react-icons/fa6';
import {
  useAddPastResultToEncounterMutation,
  useRemovePastResultFromEncounterMutation,
} from '../../../__generated__/graphql';

interface Props {
  setSelectedLab: (lab: UILabItem) => void;
}

interface Labs {
  groupedLabs: GroupedLabs;
  attachedLabs: UILabItem[];
}

interface GroupedLabs {
  Lab: UILabItem[];
  'Diagnostic Imaging': UILabItem[];
  Procedure: UILabItem[];
}

export function AllLabs({ setSelectedLab }: Props) {
  const [addPastResultToEncounter] = useAddPastResultToEncounterMutation();
  const [removePastResultFromEncounter] = useRemovePastResultFromEncounterMutation();

  const { patient, loading } = usePatient();
  const { activeEncounter } = useActiveEncounter();

  const dispatch = useAppDispatch();
  const id = useSelector(selectors.getPatientId);
  const name = useSelector(selectors.getPatientFullName);
  const ageGenderBirthday = useSelector(selectors.getAgeGenderBirthday);
  const visitReason = useSelector(selectors.getVisitReason);
  const visitDate = useSelector(selectors.getVisitDate);

  const [search, setSearch] = useState('');

  const subItems = [
    `Appt ${visitDate && new Date(visitDate).toLocaleString()}`,
    `${id} ${ageGenderBirthday}`,
    visitReason,
  ];

  const labs = useMemo(() => {
    if (!loading && patient.orderableItemHistory) {
      const loweredSearch = search.toLocaleLowerCase();
      const labs: Labs = {
        groupedLabs: {
          Lab: [],
          'Diagnostic Imaging': [],
          Procedure: [],
        },
        attachedLabs: [],
      };

      for (const orderableItem of patient.orderableItemHistory) {
        const orderableItemData = {
          orderableReportID: parseInt(orderableItem.orderableReportID!),
          name: orderableItem.orderableItemName ?? '',
          // Display 'Today' if order date is today
          date:
            new Date(orderableItem.orderDate).toDateString() === new Date().toDateString()
              ? 'Today'
              : formatDate(orderableItem.orderDate),
          status: orderableItem.orderTypeOccurrence
            ? // Take first letter of each word in status, uppercased
              orderableItem.orderTypeOccurrence
                .split(' ')
                .map((word) => word[0].toUpperCase())
                .join('')
            : '',
          hasDetailedReport: !!orderableItem.formattedReportId?.length,
          hasDocument: !!orderableItem.documentIds?.length,
        };

        // If Orderable Item is attached to Encounter, move it to different array
        // to be displayed separately
        if (
          activeEncounter?.attachedReportIds &&
          activeEncounter.attachedReportIds.includes(
            parseInt(orderableItem.orderableReportID ?? '0'),
          )
        ) {
          labs.attachedLabs.push(orderableItemData);
          continue;
        }
        if (
          orderableItem.orderableItemType &&
          orderableItem.orderableItemName?.toLocaleLowerCase().includes(loweredSearch)
        ) {
          labs.groupedLabs[orderableItem.orderableItemType as keyof GroupedLabs].push(
            orderableItemData,
          );
        }
      }

      return labs;
    }
  }, [patient, loading]);

  return (
    <>
      {/* Inert UserInfo component */}
      <HStack whiteSpace='nowrap' w='fit-content' marginBottom='24px'>
        <Box>
          <Avatar minWidth={10} maxWidth={14} size='5.5vw' />
        </Box>
        <Box>
          <Text fontSize='md' fontWeight='bold'>
            {name}
          </Text>
          {subItems.map((item) => (
            <Text key={item} color='secondary' variant='body2-b' fontSize={10} lineHeight='short'>
              {item}
            </Text>
          ))}
        </Box>
      </HStack>

      <Text fontSize='2xl' as='b'>
        Patient Lab History
      </Text>

      {!!labs?.attachedLabs.length && (
        <VStack alignItems='flex-start' marginTop='sm' marginBottom='md'>
          <Text fontSize='md' fontWeight='bold'>
            Applied to Current Encounter
          </Text>
          {labs?.attachedLabs.map((attachedLab, index) => {
            return (
              <Flex key={`attached-lab-${index}`} width='100%' alignItems='stretch'>
                <Button
                  height='auto'
                  variant='outline'
                  borderRadius='5px 0 0 5px'
                  onClick={() => {
                    dispatch(
                      appointmentsActions.updateAttachedReportIds({
                        attachedReportIds: activeEncounter?.attachedReportIds
                          ? activeEncounter.attachedReportIds.filter(
                              (id) => id !== attachedLab.orderableReportID,
                            )
                          : [],
                      }),
                    );
                    removePastResultFromEncounter({
                      variables: {
                        pastResultData: {
                          encounterID: activeEncounter?.encounterID ?? 0,
                          orderableReportID: attachedLab.orderableReportID,
                        },
                      },
                    });
                  }}>
                  -
                </Button>

                <LabItem isAttached lab={attachedLab} setSelectedLab={setSelectedLab} />
              </Flex>
            );
          })}
        </VStack>
      )}

      {labs ? (
        <>
          <InputGroup margin='12px 0'>
            <InputRightElement>
              <Search2Icon />
            </InputRightElement>
            <Input
              placeholder='Search'
              focusBorderColor='brand.500'
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </InputGroup>

          <Box>
            <SimpleGrid columns={3} spacing={2} marginBottom='8px'>
              {Object.keys(labs.groupedLabs).map((labType) => {
                const labCount = labs.groupedLabs[labType as keyof GroupedLabs].length;

                return (
                  <Text key={labType} size='lg' as='b' marginLeft='1%'>
                    {labCount ? `${labType} - ${labCount}` : ''}
                  </Text>
                );
              })}
            </SimpleGrid>

            <SimpleGrid columns={3} spacing={2} maxHeight='65vh' overflowY='scroll' pt='xs' pb='sm'>
              {Object.keys(labs.groupedLabs).map((labType) => {
                const typedLabs = labs.groupedLabs[labType as keyof GroupedLabs];
                return (
                  <VStack key={labType} spacing={3} alignItems='flex-start'>
                    {typedLabs
                      .sort((a, b) => {
                        const aDate = new Date(a.date!).getTime();
                        const bDate = new Date(b.date!).getTime();
                        if (!aDate || !bDate) {
                          return -1;
                        }
                        if (aDate > bDate) {
                          return -1;
                        }
                        if (aDate < bDate) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((lab, index) => {
                        return (
                          <Flex key={`${labType}-${index}`} width='98%' marginLeft='1%'>
                            <LabItem lab={lab} setSelectedLab={setSelectedLab} />

                            <Button
                              variant='outline'
                              height='100%'
                              borderRadius='0 5px 5px 0'
                              onClick={() => {
                                dispatch(
                                  appointmentsActions.updateAttachedReportIds({
                                    attachedReportIds: activeEncounter?.attachedReportIds
                                      ? [
                                          ...activeEncounter.attachedReportIds,
                                          lab.orderableReportID,
                                        ]
                                      : [lab.orderableReportID],
                                  }),
                                );
                                addPastResultToEncounter({
                                  variables: {
                                    pastResultData: {
                                      encounterID: activeEncounter?.encounterID ?? 0,
                                      orderableReportID: lab.orderableReportID,
                                    },
                                  },
                                });
                              }}>
                              +
                            </Button>
                          </Flex>
                        );
                      })}
                  </VStack>
                );
              })}
            </SimpleGrid>
          </Box>
        </>
      ) : (
        <Spinner colorScheme='brand' />
      )}
    </>
  );
}

interface Props2 {
  lab: UILabItem;
  setSelectedLab: (lab: UILabItem) => void;
  isAttached?: boolean;
}

function LabItem({ lab, setSelectedLab, isAttached = false }: Props2) {
  return (
    <Card
      width='100%'
      borderRadius={isAttached ? '0 5px 5px 0' : '5px 0 0 5px'}
      _hover={{ backgroundColor: 'brand.100' }}
      onClick={() => {
        setSelectedLab(lab);
      }}>
      <CardBody padding='12px'>
        <Flex justifyContent='space-between' alignItems='center'>
          <Text fontSize='sm' as='b' width='65%'>
            {lab.name}
          </Text>

          <Flex
            flexDirection='column'
            alignItems='flex-end'
            flex={1}
            justifyContent={'space-between'}
            alignSelf='stretch'>
            <HStack spacing={2}>
              {lab.hasDetailedReport && <Icon as={FaFileLines} color='brand.500' />}

              {lab.hasDocument && <Icon as={FaFilePdf} color='brand.500' />}

              <Text color='gray.400' fontSize='sm'>
                {lab.status}
              </Text>
            </HStack>

            <Text fontSize='sm'>{lab.date}</Text>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
}
