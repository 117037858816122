import {
  type MedicationItem,
  useUpdateMedicationMutation,
} from '../../../../__generated__/graphql';
import { useAppUI, useCustomToast } from '../../../../hooks';
import { useGetRxData } from '../PastRx/hooks/useGetRxData';
import { useMedicationState } from './useMedicationState';
import { useUpdateMedItems } from './useUpdateMedItems';
import { FullStory as FS } from '@fullstory/browser';

interface Props {
  nextStep: () => void;
}

export const useSubmitMedication = ({ nextStep }: Props) => {
  const [updateMedication, { loading }] = useUpdateMedicationMutation();
  const medicationState = useMedicationState();
  const { refetch: rxDataQueryRefetch } = useGetRxData();
  const { handleResetAddedMedications } = useUpdateMedItems();

  const { markCurrentStepComplete } = useAppUI();
  const toast = useCustomToast();

  function handleSubmitUpdateMedication() {
    if (medicationState.activeEncounterID && medicationState.patientId) {
      const meds: Array<Partial<MedicationItem>> = medicationState.addedMedications.map(
        ({ newStatus, ...rest }): MedicationItem => {
          return {
            ...rest,
            status: newStatus,
          };
        },
      );

      FS('trackEvent', {
        name: 'Save / Verify Medications Clicked', 
        properties: { encounter_id: medicationState.activeEncounterID }
      });

      updateMedication({
        variables: {
          patientId: medicationState.patientId,
          encounterId: medicationState.activeEncounterID,
          medication: meds,
        },
        onError: () => {
          toast({
            id: 'submit-medications-error',
            title: 'Error',
            description: 'Something went wrong.',
            status: 'error',
          });
        },
        update: (cache) => {
          cache.evict({ fieldName: 'getMedications' });
        },
        onCompleted: async (cache) => {
          await medicationState.medsQuery.refetch();
          await medicationState.medsByEncounterQuery.refetch();

          await rxDataQueryRefetch();
          // force a state update after calling refetch
          // this way we can nav back to the tab and it will
          // reflect our latest updates without needing to manually
          // reset the state values based on the query results
          handleResetAddedMedications();

          toast({
            id: 'submit-medications-success',
            title: 'Success',
            description: 'Your submission was successful.',
            status: 'success',
          });
          markCurrentStepComplete();
          nextStep();
        },
      });
    }
  }

  return {
    loading,
    handleSubmitUpdateMedication,
  };
};
