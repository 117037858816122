import {
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  SimpleGrid,
  Switch,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { type ImmunizationInjectionItem } from '../__generated__/graphql';
import { formatDate } from '../utils';

interface DisabledVaccineFormProps {
  injection: ImmunizationInjectionItem;
}

export function DisabledInjectionForm({ injection }: DisabledVaccineFormProps) {
  return (
    <>
      <Flex justifyContent={'space-between'} mb='lg' pt={'lg'}>
        <Text variant='h6-b'>{injection.immunizationName}</Text>
        <HStack spacing='sm' position='relative'>
          <Text>Given in Past</Text>
          <Switch
            isDisabled
            colorScheme='brand'
            isChecked={!!injection.vaccinationGivenInThePast}
          />
        </HStack>
      </Flex>
      <VStack alignItems={'stretch'} spacing={'lg'}>
        <SimpleGrid columns={3} spacing={'md'}>
          <FormControl>
            <FormLabel>
              <Text>Lot #</Text>
            </FormLabel>
            <Input isDisabled value={injection?.lotNumber ?? '-'} />
          </FormControl>
          <FormControl>
            <FormLabel>Dose</FormLabel>
            <Input isDisabled value={injection.dose ?? '-'} />
          </FormControl>

          <FormControl>
            <FormLabel>Route</FormLabel>
            <Input disabled value={injection?.route ?? '-'} />
          </FormControl>

          <FormControl>
            <FormLabel>Dose #</FormLabel>
            <Input isDisabled placeholder='Dose #' value={injection?.doseNumber ?? ''} />
          </FormControl>

          <FormControl>
            <FormLabel>
              <Text> Location</Text>
            </FormLabel>
            <Input disabled value={injection?.injectionLocation ?? '-'} />
          </FormControl>
        </SimpleGrid>

        <Divider />

        <SimpleGrid columns={3} spacing={'md'}>
          <FormControl>
            <FormLabel>Given Date/Time</FormLabel>
            <Input
              disabled
              value={
                injection?.dateTimeAdministered ? formatDate(injection.dateTimeAdministered) : '-'
              }
            />
          </FormControl>

          <FormControl>
            <FormLabel>Given By</FormLabel>
            <Input isDisabled value={injection?.administeredByUserName ?? '-'} />
          </FormControl>

          <FormControl>
            <FormLabel>Status</FormLabel>
            <Input isDisabled value={injection?.statusDescription ?? '-'} />
          </FormControl>
        </SimpleGrid>

        <Divider />

        <SimpleGrid columns={3} spacing={'md'}>
          <FormControl>
            <FormLabel>VFC</FormLabel>
            <Input isDisabled value={injection?.vfcCode ?? '-'} />
          </FormControl>

          <FormControl>
            <FormLabel>VIS Given Date</FormLabel>
            <Input
              disabled
              value={injection?.dateVISGiven ? formatDate(injection.dateVISGiven) : '-'}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Date On VIS</FormLabel>
            <Input disabled value={injection?.dateOnVIS ? formatDate(injection.dateOnVIS) : '-'} />
          </FormControl>
        </SimpleGrid>

        <Divider />

        <Flex>
          <FormControl>
            <Textarea value={injection.notes ?? '-'} isDisabled placeholder='Comments' />
          </FormControl>
        </Flex>
      </VStack>
    </>
  );
}
