import { Button, Drawer, DrawerOverlay, useDisclosure } from '@chakra-ui/react';
import { ImmunizationHistoryDrawer } from './ImmunizationHistoryDrawer';

export function ImmunizationHistoryButton() {
  const { onOpen, onClose, isOpen } = useDisclosure({ defaultIsOpen: false });
  // trigger the drawer to open with URL from react-router
  // useEffect
  //   if (window.location.pathname === '/immunization-history') {
  //     onOpen();
  //   }
  // }, [onOpen]);

  return (
    <>
      <Button variant='outline' onClick={onOpen}>
        Immunization History
      </Button>
      <Drawer isOpen={isOpen} placement='right' size={'full'} onClose={onClose}>
        <DrawerOverlay />

        <ImmunizationHistoryDrawer onClose={onClose} />
      </Drawer>
    </>
  );
}
