import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Tag,
  Text,
  Textarea,
  VStack,
  Wrap,
  WrapItem,
} from '../..';
import { Box, Heading, Select } from '@chakra-ui/react';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { injectSuggestionTagForInput } from '../../../utils';

interface ROSModalProps {
  encounterROSDataItem: EncounterRosDataItemInputUI | undefined;
  symptom: ROSCategorySymptom;
  categoryName: string;
  onCloseModal: () => void;
  saveSymptom: (
    encounterHPIDataItem: EncounterRosDataItemInputUI,
    symptom: ROSCategorySymptom,
  ) => void;
  nextHandler: (next: boolean, symptom: ROSCategorySymptom) => void;
  enableNext: boolean;
  enableBack: boolean;
}

export function EditROSModal({
  encounterROSDataItem: encounterROSDataItemProp,
  symptom,
  categoryName,
  onCloseModal,
  saveSymptom,
  nextHandler,
  enableBack,
  enableNext,
}: ROSModalProps) {
  const [encounterROSDataItem, setEncounterROSDataItem] = useState<
    EncounterRosDataItemInputUI | undefined
  >(encounterROSDataItemProp || undefined);
  if (encounterROSDataItem?.rosSymptomID !== encounterROSDataItemProp?.rosSymptomID) {
    setEncounterROSDataItem(encounterROSDataItemProp);
  }
  const presenceOptions = symptom?.symptomOptions?.split(',').filter((o) => o);
  const rosObservations = symptom?.rosObservations || [];

  const noteInputRef = useRef<HTMLTextAreaElement>(null);
  const initialNoteHighlight = useRef(false);

  // Handle the initial data load and highlight of text
  useEffect(() => {
    if (!initialNoteHighlight.current && encounterROSDataItem) {
      initialNoteHighlight.current = true;
      noteInputRef.current?.select(); // highlight notes
    }
  }, [encounterROSDataItem]);

  const handleTagClick = (tagName: string) => {
    const result = injectSuggestionTagForInput(
      noteInputRef,
      encounterROSDataItem?.symptomNote ?? undefined,
      tagName,
    );

    if (encounterROSDataItem)
      setEncounterROSDataItem({ ...encounterROSDataItem, symptomNote: result });
  };
  const showPrevAndNext = enableNext || enableBack;
  return (
    <>
      <ModalHeader />
      <ModalCloseButton />
      <ModalBody>
        <VStack spacing='md' alignItems='flex-start'>
          <Heading fontSize='xl'>{`${categoryName} > ${symptom.symptomName}`}</Heading>
          {presenceOptions?.length && (
            <Select
              value={
                // to prevent TS "null" complaint. TODO: Type differently.
                encounterROSDataItem?.presenceOption as string | undefined
              }
              placeholder='Presence'
              onChange={(e) => {
                if (encounterROSDataItem) {
                  const updated = {
                    ...encounterROSDataItem,
                    presenceOption: e.target.value,
                  };
                  setEncounterROSDataItem(updated);
                  saveSymptom(updated, symptom);
                }
              }}>
              {presenceOptions.map((symptom) => {
                return (
                  <option key={symptom} value={symptom}>
                    {symptom}
                  </option>
                );
              })}
            </Select>
          )}
          <Textarea
            ref={noteInputRef}
            rows={6}
            placeholder='Notes'
            value={encounterROSDataItem?.symptomNote || ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              if (encounterROSDataItem) {
                setEncounterROSDataItem({
                  ...encounterROSDataItem,
                  symptomNote: e.target.value || '',
                });
              }
            }}
          />

          <VStack w='full' alignItems='flex-start'>
            <Text>Suggestions</Text>

            <Wrap spacing='sm'>
              {rosObservations?.map((x) => (
                <WrapItem key={x.observationID}>
                  <Tag
                    onClick={() => {
                      handleTagClick(x.observationName ?? '');
                    }}
                    variant='outline'
                    borderRadius='full'
                    colorScheme='brand'
                    sx={{ cursor: 'pointer' }}>
                    {x.observationName}
                  </Tag>
                </WrapItem>
              ))}
            </Wrap>
          </VStack>
        </VStack>
      </ModalBody>
      <ModalFooter justifyContent={showPrevAndNext ? 'space-between' : 'flex-end'}>
        {showPrevAndNext && (
          <Box>
            <Button
              isDisabled={!enableBack}
              mr='sm'
              variant='outline'
              onClick={() => {
                if (
                  encounterROSDataItem &&
                  !_.isEqual(encounterROSDataItem, encounterROSDataItemProp)
                ) {
                  saveSymptom(encounterROSDataItem, symptom);
                }
                nextHandler(false, symptom);
              }}>
              Previous
            </Button>
            <Button
              isDisabled={!enableNext}
              variant='outline'
              onClick={() => {
                if (
                  encounterROSDataItem &&
                  !_.isEqual(encounterROSDataItem, encounterROSDataItemProp)
                ) {
                  saveSymptom(encounterROSDataItem, symptom);
                }
                nextHandler(true, symptom);
              }}>
              Next
            </Button>
          </Box>
        )}
        <Box>
          <Button variant='ghost' onClick={onCloseModal}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (encounterROSDataItem) {
                saveSymptom(encounterROSDataItem, symptom);
                onCloseModal();
              }
            }}>
            Save Note
          </Button>
        </Box>
      </ModalFooter>
    </>
  );
}
