import ResizeTextarea from 'react-textarea-autosize';
import {
  Box,
  type ChakraProps,
  IconButton,
  Modal,
  Text,
  Textarea,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { NoteSuggestion } from './NoteSuggestion';
import React, { useMemo, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useAuth } from '../../../hooks';
import { formatDate, formatTime } from '../../../utils';
import { Flex } from '../../layout';

interface Props {
  keynameCategory: string;
  value: string;
  onChange: (e: string) => void;
  suggestionCallback: (input: string) => void;
  popupTitle: string;
  popupDisclaimer: string;
  disclaimer?: string;
  placeholder?: string;
  children?: React.ReactNode;
  maxRows?: number;
  disclaimerStyling?: ChakraProps;
  isDisabled?: boolean;
  previousNote?: string;
  prependWithSignature?: boolean;
}

export function Notes({
  keynameCategory,
  value,
  maxRows = 3,
  onChange,
  suggestionCallback,
  placeholder,
  children,
  disclaimer,
  isDisabled,
  disclaimerStyling = {},
  popupTitle,
  popupDisclaimer,
  previousNote,
  prependWithSignature,
}: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isFocused, setIsFocused] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { user } = useAuth();

  const previousNoteFormatted = previousNote?.split('\n') ?? [];
  const signature = useMemo(() => {
    const date = new Date().toUTCString();
    return `${user.lastName}, ${user.firstName} ${formatDate(date)} ${formatTime(date)} >`;
  }, []);

  const addSignature = (note: string) => {
    if (prependWithSignature && !note.startsWith(signature)) {
      return `${signature} ${note}`;
    }
    return note;
  };

  return (
    <Box
      borderRadius={4}
      width='100%'
      borderColor={isFocused ? 'brand.500' : 'gray.300'}
      borderWidth={isFocused ? 2 : 1}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <NoteSuggestion
          keynameCategory={keynameCategory}
          popupDisclaimer={popupDisclaimer}
          value={value}
          onClose={onClose}
          onSave={(note) => {
            const val = addSignature(note);
            suggestionCallback(val);
          }}
          popupTitle={popupTitle}
        />
      </Modal>

      {previousNote && (
        <Box paddingTop={2} borderBottom={'1px solid'} borderColor={'gray.400'}>
          <Box
            paddingX={4}
            paddingBottom={2}
            maxHeight={isExpanded ? undefined : '170px'}
            overflowY='hidden'>
            {previousNoteFormatted?.map((note, index) => {
              return (
                <Text key={index} color={'gray.500'}>
                  {note}
                  <br />
                </Text>
              );
            })}
          </Box>

          {previousNoteFormatted.length > 7 && (
            <Button
              width='100%'
              variant='ghost'
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}>
              See {isExpanded ? 'Less' : 'More'} &nbsp;
              {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </Button>
          )}
        </Box>
      )}

      <Textarea
        border={0}
        w='full'
        isDisabled={isDisabled}
        outline='none'
        placeholder={placeholder}
        as={ResizeTextarea}
        maxRows={maxRows}
        minRows={3}
        onChange={(e) => {
          const val = addSignature(e.target?.value ?? '');
          onChange(val);
        }}
        value={value}
        resize='none'
        _focus={{
          boxShadow: '0 0 0 0',
        }}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
      />

      {children}

      <Flex paddingX={4} justifyContent='space-between' paddingY={2}>
        <Text color='gray.400' {...disclaimerStyling}>
          {disclaimer}
        </Text>
        <IconButton
          isDisabled={isDisabled}
          aria-label='more actions button'
          variant='outline'
          borderRadius='50px'
          colorScheme='brand'
          height={6}
          onClick={onOpen}>
          <FiMoreHorizontal />
        </IconButton>
      </Flex>
    </Box>
  );
}
