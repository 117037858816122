import { useAuth, useCustomToast } from '../../../hooks';
import { useAppDispatch, useAppSelector, worklistTodoActions } from '../../../state-management';
import {
  type UpdateOrderableItemInput,
  useUpdateOrderableItemAndResultsMutation,
} from '../../../__generated__/graphql';

export function useChecklistConstellationForm({
  constellationItemId,
}: {
  constellationItemId: number;
}) {
  const [updateOrderableItemAndResults, { loading, error, data }] =
    useUpdateOrderableItemAndResultsMutation();
  const dispatch = useAppDispatch();
  const toast = useCustomToast();
  const submitState = {
    isLoading: loading,
    isSubmitError: !!error,
    isSubmitSuccess: !!data,
  };

  const constellationItems = useAppSelector((state) => state.worklistTodo.constellationItems);

  const constellationItem = constellationItems[constellationItemId];
  const dynamicFormFieldsState = constellationItem?.orderableItemResultFormFields;
  const dynamicFormFields = Object.values(dynamicFormFieldsState);

  // TODO: Should we use this for update orderable item?
  const ecwId = useAuth().user?.ecwId;
  const reviewedByUserId = ecwId ? Number(ecwId) : null;

  async function handleSubmitForm() {
    const {
      id: orderableReportID,
      notes,
      internalNotes,
      result,
      resultDate,
      received,
      collectedDate,
    } = constellationItem;
    const canSubmit = !!orderableReportID && !!reviewedByUserId;

    if (canSubmit) {
      const input: UpdateOrderableItemInput = {
        actualFasting: 2, // Not Recorded
        orderableReportID,
        fasting: false, // temporarily, till removed from API?
        reviewedByUserId,
        result,
        resultDate,
        orderReceived: received,
        orderableItemResults: dynamicFormFields,
        collectedDate,
        notes,
        internalNotes,
      };

      updateOrderableItemAndResults({
        variables: {
          orderableItem: input,
        },

        onCompleted: () => {
          dispatch(worklistTodoActions.markConstellationItemComplete(constellationItemId));
          toast({
            id: 'lab-collection-success',
            title: 'Success',
            description: 'Submitted successfully',
            status: 'success',
          });
        },

        onError: () => {
          toast({
            id: 'submit-lab-order-error',
            title: 'Error',
            status: 'error',
            description: 'Something went wrong',
            duration: null,
          });
        },
      });
    }
  }

  return {
    formFields: dynamicFormFields,
    handleSubmitForm,
    submitState,
  };
}
