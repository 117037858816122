import { useCustomToast } from '../../../hooks';
import { useLabCollectionForm } from './useLabCollectionForm';
import { useEffect, useState } from 'react';
import { useGetAoeQuestionsAndOptionsLazyQuery } from '../../../__generated__/graphql';
import { isEmpty } from 'lodash';

export function useAOEQuestionsAndLabInstructions() {
  const toast = useCustomToast();
  const [aoeState, setAOEState] = useState({
    hasAOEQuestionsOrLabInstructions: false,
    hasAOEQuestions: false,
    hasLabInstructions: false,
  }); // TODO: Check if this is needed
  const [apiState, setApiState] = useState<{
    isLoading?: boolean;
    isError?: boolean;
    isSuccess?: boolean;
  }>({ isLoading: false, isError: false, isSuccess: false });

  const { formValues, globalLabForm } = useLabCollectionForm();
  const sendToLabCompanyId = globalLabForm.sendToLabCompanyId ?? 0;
  const [getAoeQuestionsAndOptions] = useGetAoeQuestionsAndOptionsLazyQuery();

  useEffect(() => {
    if (sendToLabCompanyId) {
      (async () => {
        const labIds = [sendToLabCompanyId]; // Orchard. The only one we are using for now.
        const promises = formValues.map(async (lab) => {
          const labItemId = lab.orderableItemId;
          if (!labItemId) return undefined;

          return getAoeQuestionsAndOptions({
            variables: {
              labIds,
              labItemId,
            },
          });
        });

        try {
          const results = await Promise.all(promises);
          const hasErrors = results.some((result) => !!result?.error); // TODO: Check this error handling
          const hasAOEQuestions = results.some(
            (result) => !isEmpty(result?.data?.getAOEQuestionsAndOptions?.aoe),
          );
          const hasLabInstructions = results.some(
            (result) =>
              !isEmpty(
                result?.data?.getAOEQuestionsAndOptions?.labs?.filter((l) => !!l.labInstructions),
              ),
          );
          const hasAOEQuestionsOrLabInstructions =
            !hasErrors && (hasAOEQuestions || hasLabInstructions);

          setAOEState({ hasAOEQuestionsOrLabInstructions, hasAOEQuestions, hasLabInstructions });
          setApiState({
            isLoading: false,
            isSuccess: !hasErrors,
            isError: hasErrors,
          });

          setApiState({
            isLoading: false,
            isSuccess: !hasErrors,
            isError: hasErrors,
          });

          if (hasErrors) {
            toast({
              id: 'fetch-aoe-collection-error',
              title: 'Error',
              description: 'Failed to fetch AOE questions and options',
              status: 'error',
            });
          }
        } catch (e) {
          toast({
            id: 'fetch-aoe-order-error',
            title: 'Error',
            status: 'error',
            description: 'Something went wrong',
            duration: null,
          });
          setApiState({ isLoading: false, isSuccess: false, isError: true });
        }
      })();
    }
  }, [sendToLabCompanyId]);

  return { ...aoeState, apiState };
}
