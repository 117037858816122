import {
  Box,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useState } from 'react';
import { UserInfo } from '../../../data-display';
import { useWorklistModal } from '../../WorklistModal/useWorklistModal';
import { useAOEQuestionsAndLabInstructions } from '../useAOEQuestionsAndLabInstructions';
import { useLabCollectionForm } from '../useLabCollectionForm';
import { AOETabContent } from './AOETabContent';
import { LabInstructionsTabContent } from './LabInstructionsTabContent';

export function AOEQuestionsAndLabFormsModal() {
  const { toLabCollectionFormModal } = useWorklistModal();
  const { handleSubmit, submitState } = useLabCollectionForm();
  const [hasAcknowledgedLabInstructions, setAcknowledgedLabInstructions] = useState<boolean>(false);
  const { hasLabInstructions } = useAOEQuestionsAndLabInstructions();
  const canSend = !hasLabInstructions || hasAcknowledgedLabInstructions;

  return (
    <>
      <ModalCloseButton />
      <ModalBody>
        <Box h='70vh'>
          <UserInfo location='labSubmission' />
          <Text variant='h6-b' mt='lg' mb='md'>
            Please Complete AOE And Acknowledge Instructions.
          </Text>
          <Tabs colorScheme='brand' isFitted>
            <TabList>
              <Tab>AOE</Tab>
              <Tab>Lab Instructions</Tab>
            </TabList>

            <TabPanels>
              <TabPanel p='0'>
                <AOETabContent />
              </TabPanel>
              <TabPanel p='0'>
                <LabInstructionsTabContent
                  acknowledged={hasAcknowledgedLabInstructions}
                  setAcknowledged={setAcknowledgedLabInstructions}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button variant='ghost' onClick={toLabCollectionFormModal}>
          Back
        </Button>
        <Tooltip
          label='Please check "Acknowledge Instructions"'
          isDisabled={canSend}
          placement='top'>
          <Button isDisabled={!canSend} isLoading={submitState.isLoading} onClick={handleSubmit}>
            Send
          </Button>
        </Tooltip>
      </ModalFooter>
    </>
  );
}
