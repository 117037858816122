import { useEffect, useState } from 'react';
import { useCustomToast, useDebounce, useEncounter, usePatient } from '../../../hooks';
import {
  medicalHistorySelectors,
  existingPatientMedicalHistorySelectors,
  type RootState,
  uiActions,
  useAppDispatch,
  useAppSelector,
  modifiableExistingPatientMedicalHistorySelectors,
} from '../../../state-management';
import { type ICDCode } from '../../../types';
import {
  type CreateMedicalHistoryItem,
  type MedicalHistory,
  useGetIcdCodesQuery,
  useGetMedicalHistoryItemsQuery,
  useGetMedicalHistoryQuery,
  useUpdateMedicalHistoryMutation,
} from '../../../__generated__/graphql';
import { FullStory as FS } from '@fullstory/browser';

const getOrderFlowNavigationState = (state: RootState) => state.ui.orderFlowNavigation;

function transformGetICDCodesToMedicalHistoryItems(icdCodes?: ICDCode[] | null) {
  if (icdCodes != null) {
    return icdCodes.map(({ itemID, ...rest }) => {
      return {
        ...rest,
        addToPatientProblemList: false,
        id: itemID,
      };
    });
  }
}

function transformMedicalHistoryItem(
  medicalHistoryItems?: MedicalHistory[] | null,
): MedicalHistoryViewItem[] {
  return (medicalHistoryItems ?? []).map((item, idx) => {
    return {
      id: idx, // the idx is temporary until the BE is updated to return the item id
      name: item.historyText || 'N/A',
      icdCode: item.icdCode,
      addToPatientProblemList: !!item.presentOnProblemList,
      isCustomEntry: !item.icdCode,
      isByName: !item.icdCode,
      icdItemID: item.icdItemID ?? undefined,
    };
  });
}

function transformGetMedHistoryItemsToAddedMedHistoryItems(
  items?: string[] | null,
): MedicalHistoryViewItem[] | undefined {
  if (items != null) {
    return items.map((item) => {
      return {
        id: item,
        addToPatientProblemList: false, // TODO: To be returned?
        isByName: true,
        name: item,
        // icdCode: item,
      };
    });
  }
}

const DEBOUNCE_MILLISECONDS = 400;

export function useMedicalHistory() {
  const { patientId, refetch } = usePatient();
  const { activeEncounterID } = useEncounter();
  const dispatch = useAppDispatch();
  const orderFlowState = useAppSelector(getOrderFlowNavigationState);
  const [isSuccessUpdateMedicalHistory, setIsSuccessUpdateMedicalHistory] = useState(false);

  const medicalHistoryState = orderFlowState.addedMedicalHistory;
  const existingPatientMedicalHistoryState = orderFlowState.existingPatientMedicalHistory;
  const modifiableExistingPatientMedicalHistoryState =
    orderFlowState.modifiableExistingPatientMedicalHistory;
  const toast = useCustomToast();
  const {
    debouncedMedHistoryNameSearchText,
    debouncedMedHistoryDescriptionSearchText,
    debouncedICDSearchText,
    rawICDSearchText,
    rawMedHistoryDescriptionSearchText,
    rawMedHistoryNameSearchText,
  } = orderFlowState;

  // ICD Codes search
  const rawDebouncedSearchText = useDebounce(rawICDSearchText, DEBOUNCE_MILLISECONDS);
  const rawDebouncedMedHistoryDescriptionSearchText = useDebounce(
    rawMedHistoryDescriptionSearchText,
    DEBOUNCE_MILLISECONDS,
  );

  const rawDebouncedNameSearchText = useDebounce(
    rawMedHistoryNameSearchText,
    DEBOUNCE_MILLISECONDS,
  );

  useEffect(() => {
    dispatch(uiActions.onICDCodeDebouncedSearchTextChange(rawDebouncedSearchText));
  }, [rawDebouncedSearchText, dispatch]);

  useEffect(() => {
    dispatch(uiActions.onMedHistoryNameDebouncedTextChange(rawDebouncedNameSearchText));
  }, [rawDebouncedNameSearchText, dispatch]);

  useEffect(() => {
    dispatch(
      uiActions.onMedHistoryDescriptionDebouncedTextChange(
        rawDebouncedMedHistoryDescriptionSearchText,
      ),
    );
  }, [rawDebouncedMedHistoryDescriptionSearchText, dispatch]);

  // Fetch medical history items based on DICD/Desc
  const { data: icdCodesData, loading: isLoading } = useGetIcdCodesQuery({
    variables: {
      startsWith: debouncedICDSearchText,
      contains: debouncedMedHistoryDescriptionSearchText,
      isIcd10Code: true,
    },
    skip: !debouncedICDSearchText && !debouncedMedHistoryDescriptionSearchText,
  });
  const icdCodes = icdCodesData?.getICDCodes;
  // Fetch medical history items based on name
  const { data: medHistoryItemsByNameData, loading: isLoadingGetMedicalHistoryItems } =
    useGetMedicalHistoryItemsQuery({
      variables: { contains: debouncedMedHistoryNameSearchText },
      skip: !debouncedMedHistoryNameSearchText,
    });
  const medHistoryItemsByName = medHistoryItemsByNameData?.getMedicalHistoryItems;
  const {
    data: patientMedicalHistoryData,
    loading: isLoadingPatientMedicalHistory,
    refetch: refetchPatientMedicalHistory,
  } = useGetMedicalHistoryQuery({
    variables: { patientId },
    skip: !patientId,
  });
  const patientMedicalHistory = patientMedicalHistoryData?.getMedicalHistory?.medicalHistory;

  useEffect(() => {
    const existingPatientHistory = transformMedicalHistoryItem(patientMedicalHistory);
    dispatch(uiActions.setExistingPatientMedicalHistoryItems(existingPatientHistory));
    dispatch(uiActions.setModifiableExistingPatientMedicalHistoryItems(existingPatientHistory));
  }, [patientMedicalHistory, dispatch]);

  const [onUpdateMedicalHistory, { loading: isLoadingUpdateMedicalHistory }] =
    useUpdateMedicalHistoryMutation();

  // Transform medical history items (used for drop down)
  const medicalHistoryItems = transformGetICDCodesToMedicalHistoryItems(icdCodes?.codes);
  // sort the codes alphabetically
  medicalHistoryItems?.sort((a, b) => {
    if (a.icdCode > b.icdCode) {
      return 1;
    }
    if (a.icdCode < b.icdCode) {
      return -1;
    }
    return 0;
  });

  const fetchedMedHistoryItemsByName = transformGetMedHistoryItemsToAddedMedHistoryItems(
    medHistoryItemsByName?.medicalHistoryItems,
  );

  const addedMedicalHistoryItems = medicalHistoryState
    ? medicalHistorySelectors.selectAll(medicalHistoryState)
    : [];

  const existingPatientMedicalHistoryItems = existingPatientMedicalHistoryState
    ? existingPatientMedicalHistorySelectors.selectAll(existingPatientMedicalHistoryState)
    : [];

  const modifiableExistingPatientMedicalHistoryItems = modifiableExistingPatientMedicalHistoryState
    ? modifiableExistingPatientMedicalHistorySelectors.selectAll(
        modifiableExistingPatientMedicalHistoryState,
      )
    : [];

  function handleICDSearchChange(input: string) {
    dispatch(uiActions.onRawICDSearchTextChange(input));
  }
  function handleDescriptionSearchChange(input: string) {
    dispatch(uiActions.onRawMedicalHistoryDescriptionTextChange(input));
  }
  function handleNameSearchChange(input: string) {
    dispatch(uiActions.onRawMedHistoryNameTextChange(input));
  }
  function handleAddMedicalHistoryItem(option: MedicalHistoryViewItem | undefined | null) {
    if (option != null) {
      dispatch(uiActions.addMedicalHistoryItem(option));
    }
  }

  function handleUpdateMedicalHistory() {
    if (patientId && activeEncounterID) {
      const historyItems: CreateMedicalHistoryItem[] = addedMedicalHistoryItems.map((item) => {
        return {
          medhxItemID: Number(item.id),
          icdCode: item.icdCode,
          historyText: item.name,
          addToPatientProblemList: item.addToPatientProblemList,
        };
      });

      const existingHistoryItems: CreateMedicalHistoryItem[] =
        modifiableExistingPatientMedicalHistoryItems.map((item) => {
          return {
            medhxItemID: item.icdItemID,
            icdCode: item.icdCode,
            historyText: item.name,
            addToPatientProblemList: item.addToPatientProblemList,
          };
        });

      const payload: UpdateMedicalHistoryItemInput = {
        patientId,
        encounterId: activeEncounterID,
        medicalHistory: [...existingHistoryItems, ...historyItems],
      };

      FS('trackEvent', {
        name: 'Save / Verify Medical History Clicked', 
        properties: { encounter_id: activeEncounterID }
      });

      onUpdateMedicalHistory({
        variables: payload,
        onCompleted: () => {
          dispatch(uiActions.setMedicalHistoryItems([]));
          setIsSuccessUpdateMedicalHistory(true);
          void refetchPatientMedicalHistory();
          // refetch the patient to capture any updates
          // to the problem list sidebar
          refetch();
          toast({
            id: 'submit-medical-history-success',
            title: 'Success',
            description: 'Your submission was successful.',
            status: 'success',
          });
        },
        onError: () => {
          toast({
            id: 'submit-medical-history-fail',
            title: 'Error',
            description: 'Something went wrong.',
            status: 'error',
          });
        },
      });
    }
  }

  const noPendingChanges =
    !addedMedicalHistoryItems?.length &&
    existingPatientMedicalHistoryItems.length ===
      modifiableExistingPatientMedicalHistoryItems.length;
  return {
    patientMedicalHistory: existingPatientMedicalHistoryItems,
    modifiablePatientMedicalHistory: modifiableExistingPatientMedicalHistoryItems,
    addedMedicalHistoryItems,
    medicalHistoryItems,
    noPendingChanges,
    handleICDSearchChange,
    handleDescriptionSearchChange,
    handleUpdateMedicalHistory,
    handleNameSearchChange,
    handleAddMedicalHistoryItem,
    debouncedMedHistoryNameSearchText,
    fetchedMedHistoryItemsByName,
    isLoadingUpdateMedicalHistory,
    isSuccessUpdateMedicalHistory,
    isLoadingPatientMedicalHistory,
    isLoadingMedicalHistoryOptionsByCode: isLoading,
    isLoadingMedicalHistoryOptionsByName: isLoadingGetMedicalHistoryItems,
  };
}
