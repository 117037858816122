import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  IconButton,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { AiOutlinePlus } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { Spinner } from '../Spinner';
import { useGetKeywordsQuery } from '../../../__generated__/graphql';

interface Props {
  keynameCategory: string;
  value?: string;
  onClose: () => void;
  onSave: (input: string) => void;
  popupTitle: string;
  popupDisclaimer: string;
}

export function NoteSuggestion({
  keynameCategory,
  value,
  popupTitle,
  popupDisclaimer,
  onClose,
  onSave,
}: Props) {
  const [localValue, setLocalValue] = useState(value ?? '');
  const [search, setSearch] = useState('');

  const { data, loading } = useGetKeywordsQuery({
    variables: {
      keynameCategory,
      contains: search,
    },
  });

  return (
    <>
      <ModalOverlay
        // Slightly opaque brand color background
        bg='rgba(237, 73, 36, 0.8)'
      />
      <ModalContent height='80%' overflow='hidden'>
        <ModalHeader>{popupTitle}</ModalHeader>

        <ModalCloseButton />

        <ModalBody height='100%' overflow='hidden'>
          <Box borderRadius={4} width='100%' border='gray.300' borderWidth={1} marginBottom='12px'>
            <NoteTextArea initialValue={localValue} callback={setLocalValue} />

            <Flex justifyContent='center'>
              <Text color='gray.400'>{popupDisclaimer}</Text>
            </Flex>
          </Box>

          <Flex direction='row' alignItems='center'>
            <Input
              autoFocus
              borderRightRadius={0}
              placeholder='Suggestions'
              borderRightWidth={0}
              onChange={(e) => {
                const searchValue = e.target.value;
                setSearch(searchValue);
              }}
            />
            <IconButton
              aria-label='search-button'
              borderLeftRadius={0}
              variant='outline'
              color='gray.200'
              icon={<FiSearch />}
            />
          </Flex>

          <Flex width='100%' justifyContent='center' marginTop='12px' overflowY='auto' height='60%'>
            <Box width='90%'>
              {loading ? (
                <Spinner />
              ) : (
                data?.getKeywords?.keywords?.map((option, index) => {
                  return (
                    <div key={index}>
                      <Flex
                        onClick={() => {
                          // trim first bc some details will
                          // have an extra whitespace which forces
                          // that selection
                          const val = option?.details?.trim() || option?.name?.trim();
                          const combined = `${localValue} ${val}`.trim();
                          setLocalValue(combined);
                        }}
                        _hover={{
                          cursor: 'pointer',
                          background: 'brand.100',
                        }}
                        py={2}
                        width='100%'
                        justifyContent='space-between'
                        alignItems='center'>
                        <Text width='75%'>{option?.name}</Text>

                        <IconButton
                          aria-label='plus button'
                          variant='ghost'
                          colorScheme='brand'
                          height={6}>
                          <AiOutlinePlus />
                        </IconButton>
                      </Flex>

                      <Divider />
                    </div>
                  );
                })
              )}
            </Box>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme='blue' onClick={onClose}>
              Cancel
            </Button>

            <Button
              colorScheme='brand'
              onClick={() => {
                onSave(localValue);
                onClose();
              }}>
              Save
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </>
  );
}

const NoteTextArea = ({
  initialValue,
  callback,
}: {
  initialValue: string;
  callback: (value: string) => void;
}) => {
  const [value, setValue] = useState(initialValue);

  // Handle add suggestion button click
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Textarea
      border={0}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={() => {
        callback(value);
      }}
    />
  );
};
