import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { DEV_API_URL, getAppMode, IS_LOCAL, IS_PROD } from '../constants';
import { Auth } from 'aws-amplify';
import { onError } from '@apollo/client/link/error';

import { setContext } from '@apollo/client/link/context';
import { sendTextToEnsomataApiErrorsChannelInSlack } from './sendTextToEnsomataApiErrorsChannelInSlack';

const BASE_URL = IS_PROD ? 'https://api.prod.ensomata.io/graphql' : DEV_API_URL;

async function getAuthToken() {
  const authData = await Auth.currentAuthenticatedUser();
  return authData?.signInUserSession?.accessToken?.jwtToken;
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      const errorString = JSON.stringify(err);
      sendTextToEnsomataApiErrorsChannelInSlack(errorString);
    }
  }

  if (networkError) {
    const errorString = `[Network error]: ${networkError}`;
    sendTextToEnsomataApiErrorsChannelInSlack(errorString);
  }
});

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: await getAuthToken(),
    },
  };
});

const httpLink = createHttpLink({
  uri: ({ operationName }) => {
    return `${BASE_URL}${getAppMode() !== 'prod' ? '?op=' + operationName : ''}`;
  },
  fetch: async (...args) => await fetch(...args),
});

export const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink).concat(httpLink), // NOTE: Ensure httpLink is last to prevent "Error: You are calling concat on a terminating link, which will have no effect"
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Encounter: {
        keyFields: ['encounterID'],
      },
    },
  }),
  connectToDevTools: IS_LOCAL,
});
