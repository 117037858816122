import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAppDispatch,
  useAppSelector,
  uiActions,
  allergySelectors,
  familyMembersSelectors,
} from '../state-management';
import { useEncounter } from './useEncounter';
import { usePatient } from './usePatient';
import {
  type AllergyCriticality,
  type AllergyListUI,
  type AllergyReaction,
  type AllergySource,
  type AllergyStatus,
  type Criticality,
} from '../types';
import _, { isNil } from 'lodash';
import {
  type AllergyType,
  useGetPatientAllergiesQuery,
  useUpdateAllergiesMutation,
  type AllergyInput,
} from '../__generated__/graphql';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FullStory as FS } from '@fullstory/browser';

export function useMAWorkflow() {
  const flags = useFlags();
  const dispatch = useAppDispatch();
  const { activeEncounterID } = useEncounter();
  const { patientId } = usePatient();
  const [handleUpdateAllergies, { loading: isUpdateAllergiesLoading }] =
    useUpdateAllergiesMutation();
  const [isUpdateAllergiesSuccess, setIsUpdateAllergiesSuccess] = useState(false);
  const [isUpdateAllergiesError, setIsUpdateAllergiesError] = useState(false);

  const maWorkFlowState = useAppSelector((state) => state.ui.orderFlowNavigation);
  const getHasLoadedInitialData = useCallback(
    (step: OrderFlowSteps) => {
      const workflow = maWorkFlowState.steps.find((s) => s.id === step);
      return workflow?.hasLoadedInitialData;
    },
    [maWorkFlowState.steps],
  );

  const hasLoadedInitialData = getHasLoadedInitialData('allergies');

  // Allergies
  const { addedAllergies, isNKDA } = maWorkFlowState;
  const patientAllergyItems = addedAllergies ? allergySelectors.selectAll(addedAllergies) : [];
  function onToggleNKDA() {
    dispatch(uiActions.toggleNKDA());
  }

  function markCurrentStepComplete() {
    dispatch(uiActions.markCurrentStepComplete());
  }

  const {
    data: patientAllergyListData,
    loading: isLoadingPatientAllergies,
    refetch: refetchPatientAllergiesList,
  } = useGetPatientAllergiesQuery({
    variables: { patientId },
    skip: !patientId,
  });

  const patientAllergyList = patientAllergyListData?.getAllergies;

  useEffect(() => {
    if (patientAllergyList != null && !isNil(patientAllergyList.nkda)) {
      dispatch(uiActions.setNKDA(patientAllergyList.nkda));
    }
  }, [patientAllergyList]);

  const existingPatientAllergiesList = useMemo<AllergyListUI>(() => {
    return {
      nkda: patientAllergyList?.nkda || false,
      allergyItems:
        patientAllergyList?.allergies.map((allergy, idx) => {
          return {
            id: idx,
            allergySourceId: allergy?.allergySourceId || 0,
            source: {
              id: 0,
              agentSubstance: allergy?.agentSubstance || '',
            } satisfies AllergySource,
            reaction: {
              id: 0,
              reaction: allergy?.reaction || '',
            } satisfies AllergyReaction,
            type: { id: 0, name: allergy?.type || '' } satisfies AllergyType,
            criticality: {
              id: 0,
              name: (allergy?.criticality || 'Unknown') as Criticality,
            } satisfies AllergyCriticality,
            onSetDate: allergy?.onset as string,
            status: {
              id: 0,
              name: allergy?.active ? 'Active' : 'Inactive',
            } satisfies AllergyStatus,
          };
        }) || [],
    };
  }, [patientAllergyList]);

  useEffect(() => {
    if (!hasLoadedInitialData && !_.isNil(patientAllergyListData?.getAllergies)) {
      dispatch(uiActions.setHasLoadedInitialData('allergies'));
      dispatch(uiActions.setPatientAllergyItems(existingPatientAllergiesList.allergyItems));
    }
  }, [patientAllergyListData]);

  async function onUpdateAllergies() {
    const formattedNewAllergies = patientAllergyItems.map((item) => {
      return {
        allergySourceId: item.allergySourceId,
        active: item.status?.name === 'Active',
        agentSubstance: item.source?.agentSubstance,
        criticality: item.criticality?.name,
        onset: item.onSetDate,
        reaction: item.reaction?.reaction,
        type: item.type?.name ?? '',
      };
    });

    if (activeEncounterID && patientId) {
      FS('trackEvent', {
        name: 'Save / Verify Allergies Clicked', 
        properties: { encounter_id: activeEncounterID }
      });
      await handleUpdateAllergies({
        variables: {
          nkda: isNKDA,
          encounterId: activeEncounterID,
          patientId,
          allergies: formattedNewAllergies as AllergyInput[],
        },
        onCompleted: () => {
          setIsUpdateAllergiesSuccess(true);
          refetchPatientAllergiesList();
        },
        onError: () => {
          setIsUpdateAllergiesError(true);
        },
      });
    }
  }

  // Family History
  const { familyMembersHistory, ...restFamilyHistory } = maWorkFlowState.familyHistory;
  const familyMembers =
    familyMembersHistory != null ? familyMembersSelectors.selectAll(familyMembersHistory) : [];

  function handleToggleFamilyMemberCondition(config: {
    tempId: FamilyMemberTempId;
    icdItemId: ICDItemId;
  }) {
    dispatch(uiActions.toggleFamilyMemberCondition(config));
  }
  function handleSetFamilyMember(config: { familyMember: FamilyMemberUI }) {
    dispatch(uiActions.setFamilyMember(config));
  }
  function handleSetFamilyHistory(familyHistory: FamilyHistoryUI) {
    dispatch(uiActions.setFamilyHistory(familyHistory));
  }

  return {
    isMAWorkflowEnabled: flags.maWorkflow,
    maWorkFlowState,
    markCurrentStepComplete,
    getHasLoadedInitialData,
    // Allergies
    patientAllergyItems,
    onToggleNKDA,
    onUpdateAllergies,
    existingPatientAllergiesList,
    isUpdateAllergiesLoading,
    isUpdateAllergiesSuccess,
    isUpdateAllergiesError,
    isLoadingPatientAllergies,
    // Family History
    familyHistory: {
      familyHistoryUI: maWorkFlowState.familyHistory,
      ...restFamilyHistory,
      handleToggleFamilyMemberCondition,
      handleSetFamilyMember,
      handleSetFamilyHistory,
      familyMembers,
    },
    // ROS
    ros: {
      rosData: maWorkFlowState.ros.rosData,
      showingCategoryIds: maWorkFlowState.ros.showingCategories,
      selectedROSCategoryId: maWorkFlowState.ros.selectedROSCategoryId,
      selectedROSSymptomId: maWorkFlowState.ros.selectedROSSymptomId,
    },
    // HPI
    hpi: {
      encounterHPIState: maWorkFlowState.hpi.encounterHPIState,
      selectedCategoryId: maWorkFlowState.hpi.selectedHPICategoryId,
      selectedSymptomId: maWorkFlowState.hpi.selectedHPISymptomId,
      selectedEncounterCategory: maWorkFlowState.hpi.selectedEncounterCategory,
      selectedEncounterHPICategories: maWorkFlowState.hpi.selectedEncounterHPICategories,
    },
  };
}
