import { Box, Button, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { formatDate } from '../../../utils';
import { selectors, useAppSelector } from '../../../state-management';
import { useAdministerVaccineForm } from './useAdministerVaccineForm';
import { WaitUntilLoaded } from '../../layout';
import { useGetImmunizationInjectionLotListQuery } from '../../../__generated__/graphql';

interface LotSearchProps {
  onSelectLot: () => void;
}

export function LotSearch({ onSelectLot }: LotSearchProps) {
  const { handleUpdateForm } = useAdministerVaccineForm();
  const immunization = useAppSelector((state) => state.worklistTodo.selectedPendingVaccine);
  const itemId = immunization?.id;
  const selectedLocationID = useAppSelector(selectors.getSelectedLocationID);

  const { loading, data } = useGetImmunizationInjectionLotListQuery({
    variables: {
      facilityId: selectedLocationID,
      itemId: itemId ?? 0,
    },
    skip: !itemId,
  });

  const lotDetails = data?.getImmunizationInjectionLotList;

  return (
    <Box h='70vh' overflowY={'auto'} p='md'>
      <WaitUntilLoaded loading={loading}>
        {!lotDetails?.length ? (
          <Text textAlign={'center'}>No lot details found</Text>
        ) : (
          <>
            <Box mb='lg'>
              <Text variant='h6-b'>Lot Numbers</Text>
            </Box>
            <SimpleGrid {...{ columns: 5, spacing: 'md', alignItems: 'center' }}>
              <Heading fontSize='md'>Lot Number</Heading>
              <Heading fontSize='md'>Vaccine Name</Heading>
              <Heading fontSize='md'>Expiry Date</Heading>
              <Heading fontSize='md'>Doses Remaining</Heading>
              <div />
              {lotDetails?.map((d) => {
                return (
                  <>
                    <Text>{d.lotNumber}</Text>
                    <Text>{d.itemName}</Text>
                    <Text>{d.expirydate ? formatDate(d.expirydate) : ''}</Text>
                    <Text>{d.dosesRemaining}</Text>
                    <Button
                      variant={'outline'}
                      onClick={() => {
                        handleUpdateForm({
                          dose: d.dosage,
                          lotId: d.lotId,
                          lotNumber: d.lotNumber,
                        });
                        onSelectLot();
                      }}>
                      Select
                    </Button>
                  </>
                );
              })}
            </SimpleGrid>
          </>
        )}
      </WaitUntilLoaded>
    </Box>
  );
}
