import {
  Button,
  HStack,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Textarea,
  VStack,
  WaitUntilLoaded,
} from '../../../components';
import { modalActions, uiActions, useAppDispatch } from '../../../state-management';
import { useMAWorkflow } from '../../../hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetRosCategoryByIdQuery } from '../../../__generated__/graphql';
import { Heading } from '@chakra-ui/react';

export function ROSPanel({
  rosCategoryID,
}: {
  rosCategoryID: EncounterRosDataItemInputUI['rosCategoryID'];
}) {
  const dispatch = useAppDispatch();
  const {
    ros: {
      showingCategoryIds,
      rosData,
      selectedROSSymptomId: selectedLeftPanelSymptomId,
      selectedROSCategoryId,
    },
  } = useMAWorkflow();
  const [searchText, setSearchText] = useState('');
  // TODO: Merge initial users pre-selected items coming from server with the standard symptom inquiry
  const { data: rosByCategoryId, loading: loadingROSCategory } = useGetRosCategoryByIdQuery({
    variables: {
      rosCategoryId: rosCategoryID,
    },
  });

  useEffect(() => {
    setSearchText('');
  }, [selectedROSCategoryId]);

  const dataItemsMap = useMemo(() => {
    return (
      rosData.encounterROSDataItems.reduce<Record<number, EncounterRosDataItemInputUI>>(
        (accum, item) => {
          if (item.rosSymptomID) {
            accum[item.rosSymptomID] = item;
          }
          return accum;
        },
        {},
      ) || {}
    );
  }, [rosData]);

  const rosSymptoms = rosByCategoryId?.getROSCategoryById?.rosSymptoms;
  const filteredRosSymptoms = useMemo(() => {
    return (
      rosSymptoms
        ?.filter((r) => {
          return r?.symptomName?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
        })
        .sort((a, b) => {
          if ((a.symptomID || -1) < (b.symptomID || -1)) {
            return 1;
          }
          return -1;
        }) || []
    );
  }, [rosSymptoms, searchText]);

  const currentTab = showingCategoryIds?.find((t) => t.rosCategoryId === rosCategoryID);

  const getSymptomIndex = (symptomId?: number | null) => {
    const symptoms: ROSCategorySymptom[] = filteredRosSymptoms || [];
    const currentIndex: number = symptoms.findIndex((s) => s.symptomID === symptomId);
    const hasPrevious = currentIndex > 0;
    const hasNext = currentIndex < symptoms.length - 1;

    return {
      index: currentIndex,
      hasNext,
      hasPrevious,
    };
  };

  const getSymptomIndexForLeftPanel = (symptomId?: number | null) => {
    if (!leftPanelSymptoms) {
      return {
        index: -1,
        hasNext: false,
        hasPrevious: false,
      };
    }

    const currentIndex: number = leftPanelSymptoms.findIndex((s) => s.symptomID === symptomId);
    const hasPrevious = currentIndex > 0;
    const hasNext = currentIndex < leftPanelSymptoms.length - 1;

    return {
      index: currentIndex,
      hasNext,
      hasPrevious,
    };
  };
  const getROSCategoryDataItem = (symptom: ROSCategorySymptom): EncounterRosDataItemInputUI => {
    const symptomId = symptom.symptomID || -1;
    if (!dataItemsMap[symptomId]) {
      dataItemsMap[symptomId] = {
        rosSymptomID: symptom.symptomID || -1,
        rosSymptomName: symptom.symptomName,
        rosCategoryID,
        symptomNote: null,
        presenceOption: null,
      };
    }
    return dataItemsMap[symptomId];
  };

  const nextSymptom = (next: boolean, symptom: ROSCategorySymptom) => {
    const symptoms: ROSCategorySymptom[] = filteredRosSymptoms || [];
    const symptomIndexData = getSymptomIndex(symptom.symptomID);
    let currentIndex = symptomIndexData.index;
    if (currentIndex > -1) {
      if (next && symptomIndexData.hasNext) {
        currentIndex++;
      }
      if (!next && symptomIndexData.hasPrevious) {
        currentIndex--;
      }

      const nextSymptom = symptoms[currentIndex];
      const nextSymptomIndexData = getSymptomIndex(nextSymptom.symptomID);
      const dataItem = getROSCategoryDataItem(nextSymptom);
      handleShowEditDataItemModal(
        dataItem,
        nextSymptom,
        nextSymptomIndexData.hasNext,
        nextSymptomIndexData.hasPrevious,
      );
    }
  };

  const nextSymptomForLeftPanel = (next: boolean, symptom: ROSCategorySymptom) => {
    const symptoms: ROSCategorySymptom[] = leftPanelSymptoms || [];
    const symptomIndexData = getSymptomIndexForLeftPanel(symptom.symptomID);
    let currentIndex = symptomIndexData.index;
    if (currentIndex > -1) {
      if (next && symptomIndexData.hasNext) {
        currentIndex++;
      }
      if (!next && symptomIndexData.hasPrevious) {
        currentIndex--;
      }

      const nextSymptom = symptoms[currentIndex];
      const nextSymptomIndexData = getSymptomIndexForLeftPanel(nextSymptom.symptomID);
      const dataItem = getROSCategoryDataItem(nextSymptom);
      handleShowEditDataItemModalForLeftPanel(
        dataItem,
        nextSymptom,
        nextSymptomIndexData.hasNext,
        nextSymptomIndexData.hasPrevious,
      );
    }
  };

  const setDataItemHandler = (
    dataItem: EncounterRosDataItemInputUI,
    symptom: ROSCategorySymptom,
  ) => {
    dispatch(uiActions.upsertRosDataItem({ ...dataItem }));
  };

  const handleShowEditDataItemModal = (
    dataItem: EncounterRosDataItemInputUI,
    symptom: ROSCategorySymptom,
    enableNext: boolean,
    enableBack: boolean,
  ) => {
    dispatch(
      modalActions.showModal({
        modalType: 'EditROSModal',
        modalProps: {
          rosDataItem: dataItem,
          symptomOptions: symptom.rosObservations,
          categoryName: rosByCategoryId?.getROSCategoryById?.name || '',
          encounterROSDataItem: dataItem,
          symptom,
          saveSymptom: (
            encounterROSDataItem: EncounterRosDataItemInputUI,
            symptom: ROSCategorySymptom,
          ) => {
            setDataItemHandler(encounterROSDataItem, symptom);
          },
          nextHandler: nextSymptom,
          enableNext,
          enableBack,
          onModalClose() {
            dispatch(uiActions.setROSSelectedSymptomId(undefined));
          },
        },
        chakraModalProps: { size: '4xl' },
      }),
    );
  };

  const handleShowEditDataItemModalForLeftPanel = (
    dataItem: EncounterRosDataItemInputUI,
    symptom: ROSCategorySymptom,
    enableNext: boolean,
    enableBack: boolean,
  ) => {
    dispatch(
      modalActions.showModal({
        modalType: 'EditROSModal',
        modalProps: {
          rosDataItem: dataItem,
          symptomOptions: symptom.rosObservations,
          categoryName: rosByCategoryId?.getROSCategoryById?.name || '',
          encounterROSDataItem: dataItem,
          symptom,
          saveSymptom: (
            encounterROSDataItem: EncounterRosDataItemInputUI,
            symptom: ROSCategorySymptom,
          ) => {
            setDataItemHandler(encounterROSDataItem, symptom);
          },
          nextHandler: nextSymptomForLeftPanel,
          enableNext,
          enableBack,
          onModalClose() {
            dispatch(uiActions.setROSSelectedSymptomId(undefined));
          },
        },
        chakraModalProps: { size: '4xl' },
      }),
    );
  };

  // Get only sypmtoms currently displayed in the left panel
  const leftPanelEncounterROSDataItems = rosData.encounterROSDataItems?.filter(
    (item) => item.rosCategoryID === rosCategoryID,
  );
  const leftPanelSymptoms = filteredRosSymptoms.filter(
    (item) =>
      item.symptomID &&
      leftPanelEncounterROSDataItems.some(
        (encounterRosItem) => encounterRosItem.rosSymptomID === item.symptomID,
      ),
  );

  // Opens navigation modal when a left panel symptom is clicked
  useEffect(() => {
    if (!leftPanelSymptoms) {
      return;
    }

    const symptom = leftPanelSymptoms.find((s) => s.symptomID === selectedLeftPanelSymptomId);
    if (selectedLeftPanelSymptomId && symptom) {
      const encounterSymptom = dataItemsMap[selectedLeftPanelSymptomId];
      const symptomIndexData = getSymptomIndex(selectedLeftPanelSymptomId);
      handleShowEditDataItemModalForLeftPanel(
        encounterSymptom,
        symptom,
        symptomIndexData.hasNext,
        symptomIndexData.hasPrevious,
      );
    }
  }, [selectedLeftPanelSymptomId, leftPanelSymptoms]);

  return (
    <WaitUntilLoaded loading={loadingROSCategory}>
      <VStack spacing='md' alignItems='flex-start' w='full' py='sm'>
        <Heading fontSize='2xl' mb='sm'>
          {rosByCategoryId?.getROSCategoryById?.name}
        </Heading>
        <Input
          placeholder={`Find in ${currentTab?.rosCategoryName}`}
          value={searchText}
          mb='sm'
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <Textarea
          placeholder='Notes'
          value={rosData.notes}
          onChange={(e) => {
            dispatch(uiActions.setRosDataNotes(e.target.value));
          }}
        />

        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Symptoms</Th>
                <Th>Presence</Th>
                <Th>Notes</Th>
                <Th>
                  <Button
                    variant='ghost'
                    onClick={() => {
                      dispatch(uiActions.clearAllRosCategoryNotes());
                    }}>
                    Clear All Category Notes
                  </Button>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredRosSymptoms?.map((symptom) => {
                const symptomID = symptom?.symptomID;
                if (!symptomID) return null;

                const presenceOptions = symptom?.symptomOptions?.split(',');
                const hasPresenceOptions = presenceOptions != null && presenceOptions?.length > 1; // Prevent [''] from passing.
                const userRosDataItem = rosData.encounterROSDataItems.find(
                  (selectedRosItem) => selectedRosItem.rosSymptomID === symptomID,
                );
                const updateBase: EncounterRosDataItemInputUI =
                  userRosDataItem != null
                    ? {
                        // pre-existing user entry
                        ...userRosDataItem,
                      }
                    : {
                        // new entry
                        rosCategoryID,
                        rosCategoryName: currentTab?.rosCategoryName,
                        rosSymptomID: symptomID,
                        rosSymptomName: symptom.symptomName,
                      };
                return (
                  <Tr key={symptomID}>
                    <Td>{symptom.symptomName}</Td>
                    <Td>
                      {hasPresenceOptions && (
                        <Select
                          value={
                            // to prevent TS "null" complaint. TODO: Type differently.
                            userRosDataItem?.presenceOption as string | undefined
                          }
                          minWidth='110px'
                          placeholder='Select'
                          onChange={(e) => {
                            updateBase.presenceOption = e.target.value;
                            dispatch(uiActions.upsertRosDataItem(updateBase));
                          }}>
                          {presenceOptions.map((symptom) => {
                            return (
                              <option key={symptom} value={symptom}>
                                {symptom}
                              </option>
                            );
                          })}
                        </Select>
                      )}
                    </Td>
                    <Td maxW='420px'>
                      <HStack>
                        <Text noOfLines={1}>{updateBase.symptomNote}</Text>
                        <Button
                          variant='ghost'
                          onClick={() => {
                            const dataItem = getROSCategoryDataItem(symptom);
                            const symptomIndexData = getSymptomIndex(symptom.symptomID);
                            handleShowEditDataItemModal(
                              dataItem,
                              symptom,
                              symptomIndexData.hasNext,
                              symptomIndexData.hasPrevious,
                            );
                          }}>
                          {updateBase.symptomNote ? 'Edit' : 'Add'}
                        </Button>
                      </HStack>
                    </Td>
                    <Td />
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    </WaitUntilLoaded>
  );
}
