import { Icon, IconButton, type IconButtonProps, Tooltip } from '@chakra-ui/react';
import { IoIosRefresh as RefreshIcon } from 'react-icons/io';
import { FullStory as FS } from '@fullstory/browser';

export function RefreshButton(props: IconButtonProps) {
  function handleRefresh() {
    FS('trackEvent', {
      name: 'Reload Page Clicked', 
      properties: { }
    });
    window.location.reload();
  }

  return (
    <Tooltip label='Reload page' placement='left'>
      <IconButton
        {...props}
        //   zIndex={100}
        colorScheme='brand'
        icon={<Icon boxSize={5} as={RefreshIcon} />}
        variant='ghost'
        onClick={handleRefresh}
      />
    </Tooltip>
  );
}
