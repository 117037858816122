import { MdChecklist } from 'react-icons/md';
import { Button, Icon } from '@chakra-ui/react';
import { useActiveEncounter, useModal } from '../../../../hooks';
import { FullStory as FS } from '@fullstory/browser';

export function WorklistButton() {
  const { showModal } = useModal();
  const { activeEncounterId } = useActiveEncounter();

  return (
    <Button
      leftIcon={<Icon as={MdChecklist} boxSize='5' />}
      onClick={() => {
        FS('trackEvent', {
          name: 'Checklist Clicked', 
          properties: { encounter_id: activeEncounterId }
        });
        showModal({
          modalType: 'WorklistModal',
          chakraModalProps: { size: '6xl' },
          modalProps: { showFinalize: false },
        });
      }}>
      Checklist
    </Button>
  );
}
