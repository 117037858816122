import {
  IconButton,
  Icon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  HStack,
  VStack,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { AiFillEdit as EditIcon } from 'react-icons/ai';
import { ComboBox, DatePicker } from '../../form';
import { FacilitySelect } from '../../../features';
import { ActualFastingSelect } from './ActualFastingSelect';
import { CollectionSourceSelect } from './CollectionSourceSelect';
import { CollectionUnitSelect } from './CollectionUnitSelect';
import { ClinicalInfoInput } from './ClinicalInfoInput';
import { InternalNotesInput } from './InternalNotesInput';
import { Controller, useForm } from 'react-hook-form';
import { type LabSubmissionInput } from '../../../__generated__/graphql';
import { useEffect } from 'react';

type FormInputs = LabSubmissionInput;

export function EditLabInfo({
  lab,
  onSubmit,
}: {
  lab: LabItem;
  onSubmit: (data: FormInputs) => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit: handleSubmitRHF, control, reset } = useForm<FormInputs>();

  // Initialize the form with the lab's data
  useEffect(() => {
    // Ideally, we'd use react-hook-form's defaultValues, however, since Chakra modals retain state even when closed,
    // if lab?.labSubmissionInputs changes (from global form changes) it will still show the old local data when the modal is re-opened.
    // So this useEffect forces a reset to the form when the lab data changes.
    if (lab?.labSubmissionInputs) {
      reset(lab?.labSubmissionInputs);
    }
  }, [lab?.labSubmissionInputs]);

  const defaultLabCompanySelection = {
    label: 'Orchard',
    value: '13',
  };

  const handleSubmit = handleSubmitRHF((data) => {
    onSubmit(data);
    onClose();
  });

  return (
    <>
      <IconButton
        onClick={onOpen}
        size={'xs'}
        variant={'ghost'}
        aria-label='edit'
        icon={<Icon boxSize={5} color='primary' as={EditIcon} />}
      />

      <Modal isOpen={isOpen} onClose={onClose} size={'5xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Collection Data {'>'} {lab.orderableItemName}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing='sm' align={'stretch'}>
              <SimpleGrid columns={2} spacing={'sm'}>
                <Controller
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel>Send To</FormLabel>
                      <ComboBox
                        isLoading={false}
                        placeholder='Send To'
                        onSelection={(selection) => {
                          field.onChange(selection?.value);
                        }}
                        options={[defaultLabCompanySelection]}
                        defaultValue={defaultLabCompanySelection}
                      />
                    </FormControl>
                  )}
                  control={control}
                  name='sendToLabCompanyId'
                />

                <Controller
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel>Order Facility</FormLabel>
                      <FacilitySelect
                        placeholder='Order Facility'
                        onSelection={(selection) => {
                          field.onChange(Number(selection?.value));
                        }}
                        defaultValueId={field.value}
                      />
                    </FormControl>
                  )}
                  control={control}
                  name='orderingFacilityId'
                />

                <Controller
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel>Collection Date / Time</FormLabel>
                      <DatePicker
                        placeholder='Collection Date / Time'
                        value={field.value ? new Date(field.value) : null}
                        onChange={(date) => {
                          if (date) {
                            field.onChange(date.toISOString());
                          }
                        }}
                      />
                    </FormControl>
                  )}
                  control={control}
                  name='collectionDateTime'
                />

                <Controller
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel>Actual Fasting</FormLabel>
                      <ActualFastingSelect
                        value={Number(field.value) as ActualFasting}
                        onChange={field.onChange}
                      />
                    </FormControl>
                  )}
                  control={control}
                  name='actualFasting'
                />
              </SimpleGrid>

              <VStack align={'stretch'}>
                <HStack>
                  <Controller
                    render={({ field }) => (
                      <FormControl>
                        <FormLabel>Collection Source</FormLabel>
                        <CollectionSourceSelect value={field.value} onChange={field.onChange} />
                      </FormControl>
                    )}
                    control={control}
                    name='specimenSource'
                  />

                  <Controller
                    render={({ field }) => (
                      <FormControl>
                        <FormLabel>Collection Volume</FormLabel>
                        <Input
                          placeholder='Collection Volume'
                          value={field.value ?? undefined}
                          onChange={field.onChange}
                        />
                      </FormControl>
                    )}
                    control={control}
                    name='collectionVolume'
                  />

                  <Controller
                    render={({ field }) => (
                      <FormControl>
                        <FormLabel>Unit</FormLabel>
                        <CollectionUnitSelect
                          value={field.value ?? undefined}
                          onChange={field.onChange}
                        />
                      </FormControl>
                    )}
                    control={control}
                    name='collectionUnit'
                  />
                </HStack>

                <HStack>
                  <Controller
                    render={({ field }) => (
                      <FormControl>
                        <FormLabel>Clinical Info</FormLabel>
                        <ClinicalInfoInput
                          value={field.value ?? undefined}
                          onChange={field.onChange}
                        />
                      </FormControl>
                    )}
                    control={control}
                    name='clinicalInformation'
                  />

                  <Controller
                    render={({ field }) => (
                      <FormControl>
                        <FormLabel>Internal Notes</FormLabel>
                        <InternalNotesInput
                          value={field.value ?? undefined}
                          onChange={field.onChange}
                        />
                      </FormControl>
                    )}
                    control={control}
                    name='comments'
                  />
                </HStack>
              </VStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant={'ghost'} mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>Save Collection Data</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
