import { type HpiSmartFormEntity } from '../../../__generated__/graphql';
import { Box, Button, HStack, Radio, Td, Tr } from '../../../components';
import { type EncounterHPIDataItem, type HPISymptom } from '../../../types';

interface HPIPanelRowProps {
  symptom?: HPISymptom | null;
  encounterHPIDataItem: EncounterHPIDataItem | undefined;
  editHandler: () => void;
  setEncounterHPIDataItem: (encounterHPIDataItem: EncounterHPIDataItem | undefined) => void;
  smartForm: HpiSmartFormEntity[];
}

export function HPIPanelRow({
  symptom,
  encounterHPIDataItem,
  setEncounterHPIDataItem,
  editHandler,
  smartForm,
}: HPIPanelRowProps) {
  return symptom ? (
    <Tr>
      <Td textAlign='center'>
        {encounterHPIDataItem != null && (
          <Radio
            isChecked={encounterHPIDataItem?.complainsDenies === true}
            onClick={() => {
              let nextState: boolean | undefined = true;
              if (encounterHPIDataItem?.complainsDenies === true) {
                nextState = undefined;
              }
              setEncounterHPIDataItem({
                ...(encounterHPIDataItem || {}),
                complainsDenies: nextState || null,
              } satisfies EncounterHPIDataItem);
            }}
            colorScheme='brand'
          />
        )}
      </Td>
      <Td textAlign='center'>
        {encounterHPIDataItem != null && (
          <Radio
            isChecked={encounterHPIDataItem?.complainsDenies === false}
            onClick={() => {
              let nextState: boolean | undefined = false;
              if (encounterHPIDataItem?.complainsDenies === false) {
                nextState = undefined;
              }
              setEncounterHPIDataItem({
                ...(encounterHPIDataItem || {}),
                complainsDenies: nextState !== undefined ? nextState : null,
              } satisfies EncounterHPIDataItem);
            }}
            colorScheme='brand'
          />
        )}
      </Td>
      <Td>{symptom.name}</Td>
      <Td
        sx={{
          width: '100%', // take as much space as possible
          maxWidth: 0, // prevent overflow
        }}>
        <HStack>
          <Box
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {encounterHPIDataItem?.symptomNotes}
            {encounterHPIDataItem?.hpiStructuredData
              ?.map((s) => {
                const smartField = smartForm.find(
                  (sf) =>
                    sf.structureDataDetailID === s.hpiStructuredDataDetailId &&
                    sf.itemID === symptom.hpiSymptomID,
                );

                const structuredData = symptom.symptomOptions?.find(
                  (so) => so.optionID === s.hpiStructuredDataDetailId,
                );

                if (structuredData?.name?.length) {
                  const name =
                    structuredData.name?.length > 10
                      ? `${structuredData.name.slice(0, 7)}...`
                      : structuredData.name;

                  return `${name}: ${s.value}`;
                }

                if (smartField) {
                  return `${smartField.name}: ${s.value}`;
                }

                return '';
              })
              .filter(Boolean)
              .join(', ')
              .trim()}
          </Box>
          <Button variant='ghost' onClick={editHandler}>
            {encounterHPIDataItem != null ? 'Edit' : 'Add'}
          </Button>
        </HStack>
      </Td>
      <Td>{encounterHPIDataItem?.duration}</Td>
    </Tr>
  ) : null;
}
