import { RecommendationType } from '../__generated__/graphql';
import { type EditedOrder } from '../state-management';
import { type DiagnosisUI } from '../types';

export function autoPopulateOrderableItemReason(props: {
  futureOrder?: boolean | null | undefined;
  recommendationType?: RecommendationType | null;
  orderableItemId?: number | null | undefined;
}) {
  if (props.orderableItemId === 527416) return 'Send ColoGuard';

  if (!props.futureOrder && props.recommendationType === RecommendationType.Imaging)
    return 'Send to PACS';

  return '';
}

export function isRecommendationInjectionOrImmunization(editedOrder: EditedOrder) {
  const recType = getRecommendationType(editedOrder);
  const isInjectionOrImmunization =
    recType === RecommendationType.Immunization || recType === RecommendationType.Injection;

  return isInjectionOrImmunization;
}

export function isRecommendationLab(editedOrder: EditedOrder) {
  const recType = getRecommendationType(editedOrder);
  return recType === RecommendationType.Lab;
}

export function getDefaultDiagnosis(editedOrder?: EditedOrder): DiagnosisUI | undefined {
  if (!editedOrder) return;

  const icdCode = editedOrder.alert?.defaultIcdCode;

  if (icdCode) {
    return {
      diagnosisName: editedOrder.alert?.defaultDiagnosisName || '',
      icdCode: editedOrder.alert?.defaultIcdCode || '',
      assessmentItemId: editedOrder.alert?.defaultAssessmentItemId || 0,
    };
  }
}

// This allows diagnosis to be auto-picked even if it isn't in Saved state yet.
export function hasAutoPickedDiagnosis(editedOrder: EditedOrder) {
  const hasDiagnosisSelected = !(editedOrder.diagnosis == null);
  const hasOrderableItemSelectionOption = !(editedOrder.alert.orderableItemOptions == null);
  const hasSelectedOrderableItem = hasOrderableItemSelectionOption
    ? !(editedOrder.selectedOrderableItem == null)
    : true;

  return hasDiagnosisSelected && hasSelectedOrderableItem;
}

export function getRecommendationType(editedOrder: EditedOrder) {
  return editedOrder?.alert?.recommendationType;
}

export function isAcceptingEditedOrder(editedOrder: EditedOrder) {
  return editedOrder.orderType === 'ACCEPTED';
}

export function isDecliningEditedOrder(editedOrder: EditedOrder) {
  return editedOrder.orderType === 'DECLINED';
}

export function isReadOnlyEditedOrder(editedOrder: EditedOrder) {
  return editedOrder.orderType === 'READONLY';
}
