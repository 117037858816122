import { useSelector } from 'react-redux';
import { useActiveEncounter, usePatient } from '../../../../hooks';
import { Circle } from '../../../general';
import { Text } from '../../../typography';
import { selectors } from '../../../../state-management';
import { Flex, HStack, Tooltip } from '@chakra-ui/react';

interface Props {
  size?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
}

export function Avatar({ size = 14, maxWidth, minWidth }: Props) {
  const patientInitials = useSelector(selectors.getPatientInitials);

  return (
    <Circle
      aspectRatio={1}
      minHeight={minWidth}
      maxHeight={maxWidth}
      maxWidth={maxWidth}
      minWidth={minWidth}
      bg='primary'
      size={size}>
      <Text variant='h6-b' fontSize='sm' color='white'>
        {patientInitials}
      </Text>
    </Circle>
  );
}

export function UserInfoMini() {
  const { patient } = usePatient();

  const id = useSelector(selectors.getPatientId);
  const name = useSelector(selectors.getPatientFullName);
  const ageGenderBirthday = useSelector(selectors.getAgeGenderBirthday);
  const visitReason = useSelector(selectors.getVisitReason);

  const visitDate = useSelector(selectors.getVisitDate);
  const insurance = patient?.insurance;
  const { activeEncounterId } = useActiveEncounter();

  const truncatedVisitReason = visitReason.length > 25 ? `${visitReason.slice(0, 22)}...` : '';

  const subItems = [
    { label: `Appt ${visitDate && new Date(visitDate).toLocaleString()}`, shortened: '' },
    { label: `${id} ${ageGenderBirthday}`, shortened: '' },
    { label: visitReason, shortened: truncatedVisitReason },
  ];

  return (
    <HStack>
      <HStack whiteSpace='nowrap' w='fit-content'>
        <Avatar minWidth={10} maxWidth={12} size='5.5vw' />
        <Flex>
          <Flex flexDirection='column' alignItems='flex-start'>
            <HStack>
              <Text fontSize='xl' fontWeight='bold' lineHeight='short'>
                {name}
              </Text>
              <Text fontSize='lg' fontWeight='bold' lineHeight='short' textColor='white'>
                {activeEncounterId}
              </Text>
            </HStack>

            <Text fontWeight='bold' lineHeight='short'>
              {subItems[0].label}
            </Text>
            {subItems.slice(1, 3).map(({ label, shortened }) => {
              if (shortened) {
                return (
                  <Tooltip key={label} label={label}>
                    <Text color='#5E6C9B' fontSize='sm' lineHeight='short'>
                      {shortened}
                    </Text>
                  </Tooltip>
                );
              }

              return (
                <Text key={label} color='#5E6C9B' fontSize='sm' lineHeight='short'>
                  {label}
                </Text>
              );
            })}
            <Text color='#5E6C9B' fontSize='sm' lineHeight='short' noOfLines={1}>
              {insurance || 'Insurance: N/A'}
            </Text>
          </Flex>
        </Flex>
      </HStack>
    </HStack>
  );
}
