import {
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Checkbox,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { LabInfo } from './LabInfo';
import { useLabCollectionForm } from './useLabCollectionForm';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector, worklistTodoActions } from '../../../state-management';
import { formatDate } from '../../../utils';
import { isLabSubmissionInputValid } from './lab-collection-form-modal.helpers';
import { FASTING_MAP } from '../../../constants';
import { EditLabInfo } from './EditLabInfo';

export function LabTable() {
  const {
    formValues,
    handleToggleLabItemSelection,
    handleToggleSelectAllLabItems,
    handleUpdateForm,
  } = useLabCollectionForm();

  const dispatch = useAppDispatch();
  const selectAllLabs = useAppSelector((state) => state.worklistTodo.selectAllLabs);

  useEffect(() => {
    if (formValues) {
      dispatch(worklistTodoActions.initializeLabItems());
    }
  }, []);

  return (
    <div>
      <TableContainer>
        <Table variant='simple' sx={{ tableLayout: 'fixed' }}>
          <Thead>
            <Tr>
              <Th>Lab Name</Th>
              <Th>Send To</Th>
              <Th>Date</Th>
              <Th>Source</Th>
              <Th>Fasting</Th>
              <Th>
                <Flex gap='sm' justifyContent='flex-end'>
                  Select All
                  <Checkbox
                    size={'lg'}
                    isChecked={selectAllLabs}
                    onChange={handleToggleSelectAllLabItems}
                  />
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {formValues?.map((lab) => {
              const id = lab.orderableReportID;
              const name = lab.orderableItemName;
              const labSubmissionInputs = lab?.labSubmissionInputs;

              return (
                <Tr key={id}>
                  <Td>
                    <Tooltip label={name} aria-label='lab name'>
                      <Text fontSize='sm' isTruncated>
                        {name}
                      </Text>
                    </Tooltip>
                  </Td>
                  <Td>
                    <Text fontSize='sm'>Orchard</Text>
                  </Td>
                  <Td>
                    <Text fontSize='sm'>
                      {labSubmissionInputs?.collectionDateTime
                        ? formatDate(labSubmissionInputs.collectionDateTime)
                        : null}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize='sm'>{labSubmissionInputs?.specimenSource}</Text>
                  </Td>
                  <Td>
                    <Text fontSize='sm'>
                      {typeof labSubmissionInputs?.actualFasting === 'number'
                        ? FASTING_MAP[labSubmissionInputs.actualFasting]
                        : ''}
                    </Text>
                  </Td>
                  <Td>
                    <Flex gap='sm' justifyContent='flex-end' alignItems={'center'}>
                      <Text fontSize='xs' color='green.500' alignSelf={'center'}>
                        {isLabSubmissionInputValid(labSubmissionInputs) ? 'Data Applied' : ''}
                      </Text>
                      {isLabSubmissionInputValid(labSubmissionInputs) && (
                        <EditLabInfo
                          lab={lab}
                          onSubmit={(data) => {
                            handleUpdateForm({
                              labSubmissionInputs: data,
                              orderableReportID: lab.orderableReportID,
                            });
                          }}
                        />
                      )}
                      <LabInfo lab={lab} />
                      <Checkbox
                        isChecked={lab.isSelected}
                        size={'lg'}
                        onChange={() => {
                          handleToggleLabItemSelection(id);
                        }}
                      />
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
}
