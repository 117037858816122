import React from 'react';
import { Card } from '../Card';
import { Box, Flex, type BoxProps } from '../../layout';
import { Button, ComboBox } from '../../form';
import { Text } from '../../typography';
import { type ComboBoxOption, type EncounterCardInfo } from '../../../types';
import { PatientRoomNumber } from '../PatientRoomNumber/PatientRoomNumber';
import clinicalStatuses from './clinicalStatuses';

interface OrderSummaryCardProps extends BoxProps {
  active?: boolean;
  onViewOrder?: () => void;
  data: EncounterCardInfo;
  onClick?: () => void;
  updateClinicalStatus: (encounterId: number, clinicalStatusAfterCheckIn: string) => Promise<void>;
  updateRoomNumber: (encounterId: number, roomNumber: string) => Promise<void>;
  loading?: boolean;
}

// Covers edge case of ECW user entering unexpected value in clinical status field
// by adding it to ComboBox options
export const getClinicalStatusOptions = (clinicalStatusAfterCheckIn: string) => {
  const hasNewValue = !clinicalStatuses.includes(clinicalStatusAfterCheckIn);
  const options: ComboBoxOption[] = clinicalStatuses.map((code: string) => {
    return {
      label: code,
      value: code,
    };
  });

  // Adding new value if exists
  if (hasNewValue) {
    options.push({
      label: clinicalStatusAfterCheckIn,
      value: clinicalStatusAfterCheckIn,
    });
    // Sort alphabetically
    options.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  }

  return options;
};

export function OrderSummaryCard({
  active,
  data,
  onViewOrder,
  updateClinicalStatus,
  updateRoomNumber,
  onClick,
  loading,
  ...rest
}: OrderSummaryCardProps) {
  const getStatusButton = (data: EncounterCardInfo) => {
    let buttonProps = {
      backgroundColor: 'primary',
      children: 'View Encounter',
    };

    if (!onViewOrder) {
      if (data.isNotStarted && data.visitStatus === 'ARR') {
        buttonProps = {
          backgroundColor: 'primary',
          children: 'Start Encounter',
        };
      } else if (data.isStartedAndNotComplete) {
        buttonProps = {
          backgroundColor: 'brand.200',
          children: 'Continue',
        };
      }
    }

    return (
      <Button
        onClick={onClick}
        isLoading={loading}
        w='175px'
        textColor='white'
        boxShadow='primary'
        {...buttonProps}
      />
    );
  };

  return (
    <Card
      active={active}
      {...rest}
      borderRight='3px solid'
      borderColor={data.isStartedAndNotComplete ? 'brand.200' : 'primary'}>
      <Flex justifyContent='space-between'>
        <Flex flex={1} flexDirection='column' mt='xs' justifyContent='center' whiteSpace='nowrap'>
          <Text variant='h6-b'>{data?.fullNameAndID}</Text>
          <PatientRoomNumber
            roomNumber={data.roomNumber}
            updateRoomNumber={updateRoomNumber}
            encounterID={data.encounterID}
            placeholder='No Location'
          />
        </Flex>
        <Flex flex={1} flexDirection='column' mt='xs' justifyContent='center' whiteSpace='normal'>
          <Text variant='body2' color='secondary' whiteSpace='normal'>
            {data?.resourceName}
          </Text>
          <Text variant='body2' color='secondary' whiteSpace='normal'>
            {data?.insurance}
          </Text>
          <Text variant='body2' color='secondary' whiteSpace='normal'>
            {data?.visitReason}
          </Text>
          <Text variant='body2'>{data?.genderAgeBirthday}</Text>
        </Flex>

        <Flex flex={1} mt='xs' flexDirection='column' justifyContent='center' whiteSpace='nowrap'>
          <Text variant='h6' fontWeight='bold' textAlign='right'>
            {data?.time}
          </Text>
          {data?.clinicalStatusAfterCheckIn?.length ? (
            <Flex justifyContent='flex-end'>
              <Box width='46%' border='2px solid #DD6B20' borderRadius='9px' position='relative'>
                <ComboBox
                  placeholder='Status'
                  options={getClinicalStatusOptions(data.clinicalStatusAfterCheckIn)}
                  required
                  defaultValue={{
                    label: data.clinicalStatusAfterCheckIn,
                    value: data.clinicalStatusAfterCheckIn,
                  }}
                  onSelection={(selection) => {
                    // Guarantee selection because we have set ComboBox to required, it can never be null/undefined
                    updateClinicalStatus(data.encounterID, selection!.label);
                  }}
                  isLoading={false}
                />
              </Box>
            </Flex>
          ) : (
            <Text variant='body2' color={active ? 'primary' : 'secondary'} textAlign='right'>
              {data?.visitStatus}
            </Text>
          )}
        </Flex>

        <Flex flex={1} mt='xs' justifyContent='end' alignItems='center' whiteSpace='nowrap'>
          {getStatusButton(data)}
        </Flex>
      </Flex>
    </Card>
  );
}
